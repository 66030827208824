import { FC, ReactNode } from 'react';

import * as styles from './styles';

interface NavigationBarProps {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
}

const NavigationBar: FC<NavigationBarProps> = (props) => {
  const { leftComponent, rightComponent } = props;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>{leftComponent}</div>
      <div className={styles.contentContainer}>{rightComponent}</div>
    </div>
  );
};

export default NavigationBar;
