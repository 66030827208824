export const COLORS = {
    WHITE: '#FFFFFF',
    ALPHA_WHITE_05: 'rgba(255, 255, 255, 0.05)',
    ALPHA_WHITE_10: 'rgba(255, 255, 255, 0.1)',
    ALPHA_WHITE_15: 'rgba(255, 255, 255, 0.15)',
    ALPHA_WHITE_20: 'rgba(255, 255, 255, 0.2)',
    ALPHA_WHITE_25: 'rgba(255, 255, 255, 0.25)',
    ALPHA_WHITE_30: 'rgba(255, 255, 255, 0.3)',
    ALPHA_WHITE_35: 'rgba(255, 255, 255, 0.35)',
    ALPHA_WHITE_40: 'rgba(255, 255, 255, 0.4)',
    ALPHA_WHITE_45: 'rgba(255, 255, 255, 0.45)',
    ALPHA_WHITE_50: 'rgba(255, 255, 255, 0.5)',
    ALPHA_WHITE_55: 'rgba(255, 255, 255, 0.55)',
    ALPHA_WHITE_60: 'rgba(255, 255, 255, 0.6)',
    ALPHA_WHITE_65: 'rgba(255, 255, 255, 0.65)',
    ALPHA_WHITE_70: 'rgba(255, 255, 255, 0.7)',
    ALPHA_WHITE_75: 'rgba(255, 255, 255, 0.75)',
    ALPHA_WHITE_80: 'rgba(255, 255, 255, 0.8)',
    ALPHA_WHITE_85: 'rgba(255, 255, 255, 0.85)',
    ALPHA_WHITE_90: 'rgba(255, 255, 255, 0.9)',
    ALPHA_WHITE_95: 'rgba(255, 255, 255, 0.95)',

    BLACK: '#000000',
    ALPHA_BLACK_05: 'rgba(0, 0, 0, 0.05)',
    ALPHA_BLACK_10: 'rgba(0, 0, 0, 0.10)',
    ALPHA_BLACK_15: 'rgba(0, 0, 0, 0.15)',
    ALPHA_BLACK_20: 'rgba(0, 0, 0, 0.20)',
    ALPHA_BLACK_25: 'rgba(0, 0, 0, 0.25)',
    ALPHA_BLACK_30: 'rgba(0, 0, 0, 0.30)',
    ALPHA_BLACK_35: 'rgba(0, 0, 0, 0.35)',
    ALPHA_BLACK_40: 'rgba(0, 0, 0, 0.40)',
    ALPHA_BLACK_45: 'rgba(0, 0, 0, 0.45)',
    ALPHA_BLACK_50: 'rgba(0, 0, 0, 0.50)',
    ALPHA_BLACK_55: 'rgba(0, 0, 0, 0.55)',
    ALPHA_BLACK_60: 'rgba(0, 0, 0, 0.60)',
    ALPHA_BLACK_65: 'rgba(0, 0, 0, 0.65)',
    ALPHA_BLACK_70: 'rgba(0, 0, 0, 0.70)',
    ALPHA_BLACK_75: 'rgba(0, 0, 0, 0.75)',
    ALPHA_BLACK_80: 'rgba(0, 0, 0, 0.80)',
    ALPHA_BLACK_85: 'rgba(0, 0, 0, 0.85)',
    ALPHA_BLACK_90: 'rgba(0, 0, 0, 0.90)',
    ALPHA_BLACK_95: 'rgba(0, 0, 0, 0.95)',

    GRAY_10: '#F9F9FB',
    GRAY_20: '#F2F4F7',
    GRAY_30: '#E3E6ED',
    GRAY_40: '#CACED8',
    GRAY_50: '#A8AEBD',
    GRAY_60: '#808799',
    GRAY_70: '#5D6474',
    GRAY_80: '#434956',
    GRAY_90: '#31353F',
    GRAY_100: '#16181D',

    ALPHA_GRAY_05: 'rgba(128, 135, 153,0.05)',
    ALPHA_GRAY_10: 'rgba(128, 135, 153, 0.1)',
    ALPHA_GRAY_15: 'rgba(128, 135, 153, 0.15)',
    ALPHA_GRAY_20: 'rgba(128, 135, 153, 0.2)',
    ALPHA_GRAY_25: 'rgba(128, 135, 153, 0.25)',
    ALPHA_GRAY_30: 'rgba(128, 135, 153, 0.3)',
    ALPHA_GRAY_35: 'rgba(128, 135, 153, 0.35)',
    ALPHA_GRAY_40: 'rgba(128, 135, 153, 0.4)',
    ALPHA_GRAY_45: 'rgba(128, 135, 153, 0.45)',
    ALPHA_GRAY_50: 'rgba(128, 135, 153, 0.5)',
    ALPHA_GRAY_55: 'rgba(128, 135, 153, 0.55)',
    ALPHA_GRAY_60: 'rgba(128, 135, 153, 0.6)',
    ALPHA_GRAY_65: 'rgba(128, 135, 153, 0.65)',
    ALPHA_GRAY_70: 'rgba(128, 135, 153, 0.7)',
    ALPHA_GRAY_75: 'rgba(128, 135, 153, 0.75)',
    ALPHA_GRAY_80: 'rgba(128, 135, 153, 0.8)',
    ALPHA_GRAY_85: 'rgba(128, 135, 153, 0.85)',
    ALPHA_GRAY_90: 'rgba(128, 135, 153, 0.9)',
    ALPHA_GRAY_95: 'rgba(128, 135, 153, 0.95)',

    BLUE_10: '#F5FAFF',
    BLUE_20: '#E5F2FF',
    BLUE_30: '#D1E8FF',
    BLUE_40: '#94C9FF',
    BLUE_50: '#479DFF',
    BLUE_60: '#0073FA',
    BLUE_70: '#056BE1',
    BLUE_80: '#0961CE',
    BLUE_90: '#0D56B5',
    BLUE_100: '#0E4BA0',
    BLUE_110: '#0B3279',
    BLUE_120: '#0C2455',

    ALPHA_BLUE_05: 'rgba(0, 115, 250, 0.05)',
    ALPHA_BLUE_10: 'rgba(0, 115, 250, 0.1)',
    ALPHA_BLUE_15: 'rgba(0, 115, 250, 0.15)',
    ALPHA_BLUE_20: 'rgba(0, 115, 250, 0.2)',
    ALPHA_BLUE_25: 'rgba(0, 115, 250, 0.25)',
    ALPHA_BLUE_30: 'rgba(0, 115, 250, 0.3)',
    ALPHA_BLUE_35: 'rgba(0, 115, 250, 0.35)',
    ALPHA_BLUE_40: 'rgba(0, 115, 250, 0.4)',
    ALPHA_BLUE_45: 'rgba(0, 115, 250, 0.45)',
    ALPHA_BLUE_50: 'rgba(0, 115, 250, 0.5)',
    ALPHA_BLUE_55: 'rgba(0, 115, 250, 0.55)',
    ALPHA_BLUE_60: 'rgba(0, 115, 250, 0.6)',
    ALPHA_BLUE_65: 'rgba(0, 115, 250, 0.65)',
    ALPHA_BLUE_70: 'rgba(0, 115, 250, 0.7)',
    ALPHA_BLUE_75: 'rgba(0, 115, 250, 0.75)',
    ALPHA_BLUE_80: 'rgba(0, 115, 250, 0.8)',
    ALPHA_BLUE_85: 'rgba(0, 115, 250, 0.85)',
    ALPHA_BLUE_90: 'rgba(0, 115, 250, 0.9)',
    ALPHA_BLUE_95: 'rgba(0, 115, 250, 0.95)',

    GREEN_10: '#E9FBF3',
    GREEN_20: '#DCF9ED',
    GREEN_30: '#C7F5E1',
    GREEN_40: '#A0EECC',
    GREEN_50: '#6DE1AF',
    GREEN_60: '#41D293',
    GREEN_70: '#2AC180',
    GREEN_80: '#18AC6C',
    GREEN_90: '#0B9C67',
    GREEN_100: '#008A55',

    ALPHA_GREEN_05: 'rgba(37, 187, 122, 0.05)',
    ALPHA_GREEN_10: 'rgba(37, 187, 122, 0.1)',
    ALPHA_GREEN_15: 'rgba(37, 187, 122, 0.15)',
    ALPHA_GREEN_20: 'rgba(37, 187, 122, 0.2)',
    ALPHA_GREEN_25: 'rgba(37, 187, 122, 0.25)',
    ALPHA_GREEN_30: 'rgba(37, 187, 122, 0.3)',
    ALPHA_GREEN_35: 'rgba(37, 187, 122, 0.35)',
    ALPHA_GREEN_40: 'rgba(37, 187, 122, 0.4)',
    ALPHA_GREEN_45: 'rgba(37, 187, 122, 0.45)',
    ALPHA_GREEN_50: 'rgba(37, 187, 122, 0.5)',
    ALPHA_GREEN_55: 'rgba(37, 187, 122, 0.55)',
    ALPHA_GREEN_60: 'rgba(37, 187, 122, 0.6)',
    ALPHA_GREEN_65: 'rgba(37, 187, 122, 0.65)',
    ALPHA_GREEN_70: 'rgba(37, 187, 122, 0.7)',
    ALPHA_GREEN_75: 'rgba(37, 187, 122, 0.75)',
    ALPHA_GREEN_80: 'rgba(37, 187, 122, 0.8)',
    ALPHA_GREEN_85: 'rgba(37, 187, 122, 0.85)',
    ALPHA_GREEN_90: 'rgba(37, 187, 122, 0.9)',
    ALPHA_GREEN_95: 'rgba(37, 187, 122, 0.95)',

    RED_10: '#fff6f6',
    RED_20: '#feecee',
    RED_30: '#fedadd',
    RED_40: '#fdb5bb',
    RED_50: '#fb7480',
    RED_60: '#fa4655',
    RED_70: '#ec3847',
    RED_80: '#d52130',
    RED_90: '#ca1a29',
    RED_100: '#b31b28',

    ALPHA_RED_05: 'rgba(335, 95, 63, 0.05)',
    ALPHA_RED_10: 'rgba(335, 95, 63, 0.1)',
    ALPHA_RED_15: 'rgba(335, 95, 63, 0.15)',
    ALPHA_RED_20: 'rgba(335, 95, 63, 0.2)',
    ALPHA_RED_25: 'rgba(335, 95, 63, 0.25)',
    ALPHA_RED_30: 'rgba(335, 95, 63, 0.3)',
    ALPHA_RED_35: 'rgba(335, 95, 63, 0.35)',
    ALPHA_RED_40: 'rgba(335, 95, 63, 0.4)',
    ALPHA_RED_45: 'rgba(335, 95, 63, 0.45)',
    ALPHA_RED_50: 'rgba(335, 95, 63, 0.5)',
    ALPHA_RED_55: 'rgba(335, 95, 63, 0.55)',
    ALPHA_RED_60: 'rgba(335, 95, 63, 0.6)',
    ALPHA_RED_65: 'rgba(335, 95, 63, 0.65)',
    ALPHA_RED_70: 'rgba(335, 95, 63, 0.7)',
    ALPHA_RED_75: 'rgba(335, 95, 63, 0.75)',
    ALPHA_RED_80: 'rgba(335, 95, 63, 0.8)',
    ALPHA_RED_85: 'rgba(335, 95, 63, 0.85)',
    ALPHA_RED_90: 'rgba(335, 95, 63, 0.9)',
    ALPHA_RED_95: 'rgba(335, 95, 63, 0.95)',
    YELLOW_60: '#FAE300',
    ORANGE_60: '#FC7D22',

    TRANSPARENT: 'rgba(0, 0, 0, 0)',
    DIM_LIGHT: 'rgba(22, 28, 45, 0.2)',
    DIM_DARK: 'rgba(22, 28, 45, 0.4)',
    DIM_DARKER: 'rgba(22, 28, 45, 0.7)',

    INHERIT: 'inherit'
};

export type Color = keyof typeof COLORS;
