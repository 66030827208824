import { css } from '@emotion/css';

export const container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  min-height: 48px;
`;

export const contentContainer = css`
  display: inline-flex;
`;
