import constate from 'constate';
import { useState, useCallback } from 'react';

import { ToastProps } from '@/components/feedbacks/Toast';

const useToast = () => {
  const [toast, setToast] = useState<ToastProps | undefined>(undefined);

  const showToast: (toastProps: ToastProps, duration?: number) => void = useCallback(
    (toastProps, duration) => {
      setToast(toastProps);
      setTimeout(() => {
        setToast(undefined);
      }, duration || 1500);
    },
    []
  );

  return { toast, showToast };
};
export const [ToastProvider, useToastState, useToastContext] = constate(
  useToast,
  (value) => value.toast,
  (value) => value.showToast
);
