export const uploadImageToS3 = async (url: string, file: File) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    });

    return response;
  } catch (error) {
    console.log('s3 uploader error', error);
  }
};
