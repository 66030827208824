import { FC } from 'react';
import { cx } from '@emotion/css';

import { TextType } from './type';
import * as styles from './styles';

export type TextProps = {
  type: TextType;
  color?: string;
  className?: string;
  children: React.ReactNode;
};

const Text: FC<TextProps> = (props) => {
  const { type, color, className, children } = props;
  return <span className={cx(styles.text(type, color), className)}>{children}</span>;
};

export default Text;
