import { useRef, useState } from 'react';

import {
  useCreateSymptomImageMutation,
  useRevokeSymptomImageMutation
} from '@/graphql/generated/graphql';
import { uploadImageToS3 } from '@/utils/uploadImageToS3';
import { useToastContext } from '@/context/toast';
import amplitude from '@/utils/amplitude';

const MAXIMUM_NUMBER_OF_UPLOADS = 3;
const MAXIMUM_MB_OF_IMAGE = 10;

const useSymptomImageUpload = () => {
  const showToast = useToastContext();

  const uploadRef = useRef(null);

  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [s3Keys, setS3Keys] = useState<string[]>([]);
  const [isLoadingUploadToS3, setIsLoadingUploadToS3] = useState<boolean>(false);

  const [uploadImageMutation] = useCreateSymptomImageMutation();
  const [deleteImageMutation] = useRevokeSymptomImageMutation();

  const uploadToS3 = async (resourceKeys: string[], selectedImages: File[]) => {
    for (let index = 0; index < resourceKeys.length; index++) {
      const response = await uploadImageToS3(resourceKeys[index], selectedImages[index]);
      if (!response || !response.ok || response.status !== 200) {
        await deleteImageMutation({ variables: { resourceKeys } });
        return false;
      }
    }
    return true;
  };

  const uploadImage = async (selectedImages: File[]) => {
    amplitude.track('untact_addOnTreatmentRequest_photo_click');
    if (uploadedImages.length + selectedImages.length > MAXIMUM_NUMBER_OF_UPLOADS) {
      return showToast({
        label: '이미지는 3개 이하만 등록할 수 있습니다.',
        type: 'error'
      });
    }

    if (selectedImages.find((file) => file.size / 1024 / 1024 > MAXIMUM_MB_OF_IMAGE))
      return showToast({
        label: '이미지 사이즈는 10MB를 초과할 수 없습니다.',
        type: 'error'
      });

    if (
      selectedImages.find(
        (file) =>
          ![
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/heif',
            'image/webp',
            'image/gif'
          ].includes(file.type)
      )
    )
      return showToast({
        label: '해당 이미지는 업로드 할 수 없습니다.',
        type: 'error'
      });

    setIsLoadingUploadToS3(true);
    await uploadImageMutation({
      variables: {
        fileNames: selectedImages.map((image) => image.name)
      },
      onCompleted: async (data) => {
        const presignedUrls = data.createSymptomImage?.map((data) => data.url);
        const createdS3Keys = data.createSymptomImage?.map((data) => data.s3Key);

        const response = await uploadToS3(presignedUrls, selectedImages);
        if (!response) return console.error('url에 이미지 업로드 실패');

        setUploadedImages([...uploadedImages, ...selectedImages]);
        setS3Keys([...s3Keys, ...createdS3Keys]);

        setIsLoadingUploadToS3(false);
      },
      onError: (_error) => {
        setIsLoadingUploadToS3(false);
        showToast({
          label: '이미지 업로드가 실패하였습니다.',
          type: 'error'
        });
      }
    });
  };

  const deleteImage = async (selectedIndex: number) => {
    setIsLoadingUploadToS3(true);
    await deleteImageMutation({
      variables: { resourceKeys: s3Keys[selectedIndex] },
      onCompleted: (data) => {
        if (!data) return;
        setUploadedImages(uploadedImages.filter((_, index) => index !== selectedIndex));
        setS3Keys(s3Keys.filter((_, index) => index !== selectedIndex));
        setIsLoadingUploadToS3(false);
      },
      onError: (_error) => {
        setIsLoadingUploadToS3(false);
        showToast({
          label: '다시 시도해주세요.',
          type: 'error'
        });
      }
    });
  };

  return {
    uploadRef,
    isLoadingUploadToS3,
    uploadedImages,
    uploadImage,
    deleteImage,
    s3Keys
  };
};

export default useSymptomImageUpload;
