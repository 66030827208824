import { ClientError } from 'graphql-request';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  _Any: any;
  _FieldSet: any;
};

export type AdminUserObject = {
  __typename?: 'AdminUserObject';
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

export enum AgreementTypeEnum {
  Age = 'age',
  Goodbot = 'goodbot',
  HospitalAccount = 'hospitalAccount',
  HospitalMarketing = 'hospitalMarketing',
  Location = 'location',
  Marketing = 'marketing',
  MarketingNight = 'marketingNight',
  MarketingPush = 'marketingPush',
  MarketingSmsLms = 'marketingSmsLms',
  Privacy = 'privacy',
  Usage = 'usage'
}

export type AppBanner = {
  __typename?: 'AppBanner';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 종료날짜 */
  endAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  /** 이미지 S3 URL */
  image?: Maybe<Scalars['String']>;
  /** @deprecated 배너위치 sectionName으로 대체 */
  position?: Maybe<AppBannerPositionEnum>;
  /** 배너 구분 */
  sectionName?: Maybe<Scalars['String']>;
  /** 정렬순서 */
  sort?: Maybe<Scalars['Int']>;
  /** 시작날짜 */
  startAt?: Maybe<Scalars['DateTime']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 링크 URL */
  url?: Maybe<Scalars['String']>;
  /** 출력여부 */
  visibility?: Maybe<Scalars['Boolean']>;
};

export enum AppBannerPositionEnum {
  HomeCenter = 'homeCenter'
}

export type AppBannerQueryInput = {
  /** 종료날짜(이하) */
  endAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<AppBannerPositionEnum>;
  /** 배너 구분 */
  sectionName?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  /** 시작날짜(이상) */
  startAt?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  /** 배너 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 노출여부 */
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type ApplyMissionCompleteReward = {
  __typename?: 'ApplyMissionCompleteReward';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospitalMission: HospitalMission;
  /** 병원별 미션 ID */
  hospitalMissionId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 상품 수령인 */
  name: Scalars['String'];
  /** 상품 수령인 번호 */
  phone: Scalars['String'];
  /** 지급 상태 / unknown으로 설정 / 우버에서만 상태 변경 */
  rewardStatusType: RewardStatusTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type Appointment = {
  __typename?: 'Appointment';
  /** 설문지 응답 리스트 (예약 시점) */
  appointmentQuestionnaireData?: Maybe<Array<Maybe<AppointmentQuestionnaireRecords>>>;
  /** 예약 진료항목 */
  appointmentTreatment?: Maybe<AppointmentTreatment>;
  /** 진료항목 ID */
  appointmentTreatmentId?: Maybe<Scalars['ID']>;
  /** 진료항목 이름 (예약 시점) */
  appointmentTreatmentName?: Maybe<Scalars['String']>;
  /** 예약 유형 */
  appointmentType: AppointmentTypeEnum;
  /** 취소확정 시간 */
  canceledAt?: Maybe<Scalars['DateTime']>;
  /** 취소 상세 사유(Kor) */
  canceledDetailReason?: Maybe<Scalars['String']>;
  /** 취소 사유(Eng) */
  canceledReason?: Maybe<AppointmentCancelReasonEnum>;
  /** 방문예정 시간 */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** 확정 시간 */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  confirmedType?: Maybe<AppointmentConfirmedTypeEnum>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 요청시간 평균대기시간(분) */
  desiredTimeWaitingTime?: Maybe<Scalars['Int']>;
  /** 희망 방문 시간 */
  desiredVisitTime?: Maybe<Scalars['String']>;
  /** 사용자와 병원의 거리(m) */
  distance?: Maybe<Scalars['Int']>;
  /** 실패 시간 */
  failedAt?: Maybe<Scalars['DateTime']>;
  /** 실패 사유 */
  failedReason?: Maybe<AppointmentFailReasonEnum>;
  /** 첫번째 희망 방문 시각 */
  firstDesiredVisitTime?: Maybe<Scalars['DateTime']>;
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 접수 연동 여부 (예약 시점) */
  isReceiptIntegrated: Scalars['Boolean'];
  /** 본인 예약 여부 */
  isSelfAppointment: Scalars['Boolean'];
  /** 마지막 희망 방문 시각 */
  lastDesiredVisitTime?: Maybe<Scalars['DateTime']>;
  /** 특이사항 (내부관리용) */
  memo?: Maybe<Scalars['String']>;
  /** 고객전달 메시지 */
  memoToUser?: Maybe<Scalars['String']>;
  /** 요청 사항 */
  note?: Maybe<Scalars['String']>;
  operator?: Maybe<AdminUserObject>;
  operatorId?: Maybe<Scalars['ID']>;
  /** 우버어드민유저, 병원어드민유저 구분 */
  operatorType: OperatorTypeEnum;
  /** 예약 요청자 정보 (요청자 본인의 patient정보) */
  patient?: Maybe<Patient>;
  /** 진룓받을 환자 생년월일 */
  patientBirth?: Maybe<Scalars['String']>;
  /** 예약 요청자 (요청자 본인의 patientId) */
  patientId?: Maybe<Scalars['ID']>;
  /** 진룓받을 환자 이름 */
  patientName?: Maybe<Scalars['String']>;
  /** 진룓받을 환자 전화번호 */
  patientPhone?: Maybe<Scalars['String']>;
  /** 진룓받을 환자 주민등록번호 7번째 자리 숫자 */
  patientSexdstn?: Maybe<Scalars['Int']>;
  /**
   * 예약 목적
   * @deprecated appointmentTreatmentName으로 대체됨
   */
  purpose: AppointmentPurposeEnum;
  /** 예약확인중(상담원확인) 시간 */
  receptionAt?: Maybe<Scalars['DateTime']>;
  /** 취소요청 시간 */
  requestCanceledAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  review?: Maybe<Review>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  reviewId?: Maybe<Scalars['ID']>;
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  /** 재방문 */
  revisit: Scalars['Boolean'];
  /** 예약 상태 */
  status: AppointmentStatusEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export enum AppointmentCancelReasonEnum {
  Etc = 'etc',
  ImminentDesiredTime = 'imminentDesiredTime',
  MultipleAppointments = 'multipleAppointments',
  ResponseDelay = 'responseDelay',
  SimpleChangeOfMind = 'simpleChangeOfMind'
}

export enum AppointmentConfirmedTypeEnum {
  Appointment = 'appointment',
  WalkIn = 'walkIn'
}

export enum AppointmentFailReasonEnum {
  Closed = 'closed',
  DependOnOtherPlatform = 'dependOnOtherPlatform',
  Etc = 'etc',
  NeedBirthInformation = 'needBirthInformation',
  NeedResidentRegistrationNumber = 'needResidentRegistrationNumber',
  Refused = 'refused',
  UnableAppointment = 'unableAppointment',
  UnableFirstAppointment = 'unableFirstAppointment',
  UnableProxyAppointment = 'unableProxyAppointment',
  UnableSameDay = 'unableSameDay',
  UnableSelectedTime = 'unableSelectedTime'
}

export type AppointmentHospitalConfiguration = {
  __typename?: 'AppointmentHospitalConfiguration';
  /** 예약 유형 */
  appointmentType: AppointmentTypeEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 예약 슬롯 기본 횟수 제한 (건) */
  defaultTimeslotOccupyingLimit: Scalars['Float'];
  /** 예약 슬롯 기본 시간 단위 (분) */
  defaultTimeslotTimeUnit: Scalars['Float'];
  /** 예약가능 설정 */
  enableAppointment: Scalars['Boolean'];
  hospital: Hospital;
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 인기예약 여부 */
  isFamousAppointment: Scalars['Boolean'];
  /** 예약 항목별 슬롯 설정 활성화 여부 */
  isTimeslotCustomizable: Scalars['Boolean'];
  /** 당일예약불가 설정 */
  unableSameDayAppointment: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type AppointmentHospitalSchedule = {
  __typename?: 'AppointmentHospitalSchedule';
  /** 예약 스케쥴 메모 */
  comment?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  /** 타겟 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 사용 가능한 예약 스케쥴 여부 */
  openedSchedule: Scalars['Boolean'];
  /** 예약 스케쥴 날짜 */
  targetDate: Scalars['DateTime'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type AppointmentInput = {
  /** 설문지 응답 리스트 (예약 시점) */
  appointmentQuestionnaireData?: InputMaybe<
    Array<InputMaybe<AppointmentQuestionnaireAnswers>>
  >;
  /** 진료항목 ID */
  appointmentTreatmentId?: InputMaybe<Scalars['ID']>;
  /** 진료항목 이름 (예약 시점) */
  appointmentTreatmentName?: InputMaybe<Scalars['String']>;
  /** 취소확정 시간 */
  canceledAt?: InputMaybe<Scalars['DateTime']>;
  /** 방문예정 시간 */
  completedAt?: InputMaybe<Scalars['DateTime']>;
  /** 확정 시간 */
  confirmedAt?: InputMaybe<Scalars['DateTime']>;
  /** 희망 방문 시간 */
  desiredVisitTime?: InputMaybe<Scalars['String']>;
  /** 사용자와 병원의 거리(m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 실패 시간 */
  failedAt?: InputMaybe<Scalars['DateTime']>;
  /** 실패 사유 */
  failedReason?: InputMaybe<AppointmentFailReasonEnum>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 특이사항 (내부관리용) */
  memo?: InputMaybe<Scalars['String']>;
  /** 고객전달 메시지 */
  memoToUser?: InputMaybe<Scalars['String']>;
  /** 요청 사항 */
  note?: InputMaybe<Scalars['String']>;
  operatorId?: InputMaybe<Scalars['ID']>;
  /** 우버어드민유저, 병원어드민유저 구분 */
  operatorType?: OperatorTypeEnum;
  /** 진료받을 환자 생년월일 */
  patientBirth?: InputMaybe<Scalars['String']>;
  /** 예약 요청자 (요청자 본인의 patientId) */
  patientId: Scalars['ID'];
  /** 진료받을 환자 이름 */
  patientName?: InputMaybe<Scalars['String']>;
  /** 진료받을 환자 전화번호 */
  patientPhone?: InputMaybe<Scalars['String']>;
  /** 진료받을 환자 주민등록번호 7번째 자리 숫자 */
  patientSexdstn?: InputMaybe<Scalars['Int']>;
  /** 예약 목적 */
  purpose?: AppointmentPurposeEnum;
  /** 취소요청 시간 */
  requestCanceledAt?: InputMaybe<Scalars['DateTime']>;
  /** 재방문 */
  revisit?: InputMaybe<Scalars['Boolean']>;
  /** 예약 상태 */
  status?: InputMaybe<AppointmentStatusEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type AppointmentProfile = {
  __typename?: 'AppointmentProfile';
  birth: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  sexdstn: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type AppointmentProfileInput = {
  /** 예약자 생년월일 */
  birth: Scalars['String'];
  /** 예약자 이름 */
  name: Scalars['String'];
  /** 주민등록번호 7번째 자리 숫자 */
  sexdstn: Scalars['Int'];
  userId?: InputMaybe<Scalars['ID']>;
};

export enum AppointmentPurposeEnum {
  Checkup = 'checkup',
  Etc = 'etc',
  Treatment = 'treatment',
  Vaccination = 'vaccination'
}

export type AppointmentQueryInput = {
  /** 예약 타입 (concierge / direct / walkIn) */
  appointmentTypes?: InputMaybe<Array<AppointmentTypeEnum>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 기간 필터를 적용할 필드 리스트 */
  filterDateFields?: InputMaybe<Array<Scalars['String']>>;
  firstDesiredVisitTime?: InputMaybe<Scalars['DateTime']>;
  /** 그룹핑의 기준이 되는 field 리스트 */
  groupByField?: InputMaybe<Scalars['String']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  hospitalName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  operatorId?: InputMaybe<Scalars['ID']>;
  operatorName?: InputMaybe<Scalars['String']>;
  /** 오퍼레이터 타입 (uberAdminUser / hospitalAdminUser) */
  operatorTypes?: InputMaybe<Array<OperatorTypeEnum>>;
  patientName?: InputMaybe<Scalars['String']>;
  patientPhone?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<AppointmentStatusEnum>>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type AppointmentQuestionnaireAnswers = {
  /** 답변 (링크인 경우 null) */
  answer?: InputMaybe<Scalars['String']>;
  /** 질문 (AppointmentTreatmentQuestionnaire의 값 사용) */
  question: Scalars['String'];
  /** 타입 (AppointmentTreatmentQuestionnaire의 값 사용) */
  replyType: AppointmentTreatmentReplyTypeEnum;
};

export type AppointmentQuestionnaireRecords = {
  __typename?: 'AppointmentQuestionnaireRecords';
  /** 답변 (링크인 경우 null) */
  answer?: Maybe<Scalars['String']>;
  /** 질문 (AppointmentTreatmentQuestionnaire의 값 사용) */
  question: Scalars['String'];
  /** 타입 (AppointmentTreatmentQuestionnaire의 값 사용) */
  replyType: AppointmentTreatmentReplyTypeEnum;
};

export enum AppointmentStatusEnum {
  Canceled = 'canceled',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Failed = 'failed',
  OnProgress = 'onProgress',
  OnProgressToCancel = 'onProgressToCancel',
  Request = 'request',
  RequestCancel = 'requestCancel'
}

export type AppointmentTimeslot = {
  __typename?: 'AppointmentTimeslot';
  /** 슬롯 예약 가능 여부 */
  available: Scalars['Boolean'];
  /** 슬롯 시작 시각 (UTC) */
  from: Scalars['DateTime'];
};

export type AppointmentTimeslotQueryInput = {
  /** 예약항목 ID - 중복 제거용 */
  appointmentTreatmentId: Scalars['String'];
  /** 병원 ID */
  hospitalId: Scalars['String'];
  /** 환자 ID - 중복 제거용 */
  patientId?: InputMaybe<Scalars['String']>;
  /** UTC 기준 조회 대상 */
  utcDatetime: Scalars['DateTime'];
};

export type AppointmentTreatment = {
  __typename?: 'AppointmentTreatment';
  appointmentTreatmentQuestionnaires?: Maybe<
    Array<Maybe<AppointmentTreatmentQuestionnaire>>
  >;
  /** 예약 리스트 */
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /**  진료항목 설명  */
  description?: Maybe<Scalars['String']>;
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 접수 연동 여부 */
  isReceiptIntegrated: Scalars['Boolean'];
  /** 진료항목 */
  name: Scalars['String'];
  /** 진료비(굿닥할인가) */
  price?: Maybe<Scalars['Int']>;
  /** 연동 접수 항목 ID */
  receiptTreatmentItemId?: Maybe<Scalars['ID']>;
  timeslots?: Maybe<Array<Maybe<AppointmentTreatmentTimeslot>>>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

/** 병원 예약 진료항목 */
export type AppointmentTreatmentObject = {
  __typename?: 'AppointmentTreatmentObject';
  /** 진료항목 */
  name?: Maybe<Scalars['String']>;
  /** 가격 */
  price?: Maybe<Scalars['Float']>;
};

export type AppointmentTreatmentQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  hospitalName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type AppointmentTreatmentQuestionnaire = {
  __typename?: 'AppointmentTreatmentQuestionnaire';
  appointmentTreatment?: Maybe<AppointmentTreatment>;
  appointmentTreatmentId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 외부링크 주소 */
  externalLink?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 진료항목 질문 */
  question: Scalars['String'];
  /** 답변유형 */
  replyType: AppointmentTreatmentReplyTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export enum AppointmentTreatmentReplyTypeEnum {
  ExternalLink = 'externalLink',
  ShortAnswer = 'shortAnswer'
}

export type AppointmentTreatmentTimeslot = {
  __typename?: 'AppointmentTreatmentTimeslot';
  appointmentTreatment: AppointmentTreatment;
  /** 타겟 예약 항목 ID */
  appointmentTreatmentId: Scalars['ID'];
  /** 예약 슬롯 메모 */
  comment?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 예약 슬롯 시작 시각 (UTC) */
  from: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 예약 슬롯 현재 예약 건수 */
  occupyingCount: Scalars['Float'];
  /** 예약 슬롯 최대 예약 건수 */
  occupyingLimit: Scalars['Float'];
  /** 예약 슬롯 활성화 여부 */
  opened: Scalars['Boolean'];
  /** 예약 슬롯 끝 시각 (UTC) */
  to: Scalars['DateTime'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum AppointmentTypeEnum {
  Concierge = 'concierge',
  Direct = 'direct',
  WalkIn = 'walkIn'
}

/** 자동완성 검색 정보 */
export type AutocompleOutput = {
  __typename?: 'AutocompleOutput';
  /** 딥링크 */
  deepLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** 매핑된 id들 */
  mappingIds?: Maybe<Array<Scalars['ID']>>;
  /** 단어 */
  name: Scalars['String'];
  /** 키워드가 속한 상세타입: hospitalName, pharmacyName, eventName, departmentName, pharmacyKeywords, eventKeywords, diseaseKeywords, medicalService */
  originType: Scalars['String'];
  /** 단어 타입 (도메인) */
  type: Scalars['String'];
};

export type AutocompleteUserInput = {
  phone: Scalars['String'];
  yymmddi: Scalars['String'];
};

export type AutocompleteUserResult = {
  __typename?: 'AutocompleteUserResult';
  mobileCarrier?: Maybe<MobileCarrierEnum>;
  name?: Maybe<Scalars['String']>;
};

export enum BusinessChannelEnum {
  /** 차트사(배너 or 영업 담당자) */
  Chart = 'chart',
  /** 기타 */
  Etc = 'etc',
  /** 굿닥 영업 담당자 */
  GoodocBusinessManager = 'goodocBusinessManager',
  /** 병원 or 지인소개 */
  Hospital = 'hospital',
  /** SNS(블로그, 기사 등) */
  Sns = 'sns'
}

export type CancelReceiptInQueueInput = {
  receiptOfTodayId: Scalars['ID'];
};

export type CancelVisitPharmacyOutput = {
  __typename?: 'CancelVisitPharmacyOutput';
  code: Scalars['Float'];
  message: Scalars['String'];
  prescriptionCode: Scalars['String'];
};

export enum ChartInstallationEnum {
  Call = 'call',
  Self = 'self'
}

export type ClinicBookmark = {
  __typename?: 'ClinicBookmark';
  clinicEvent: ClinicEvent;
  /** 이벤트 아이디 */
  clinicEventId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 병원 */
  hospital: Hospital;
  /** 병원 아이디 */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 북마크 여부 */
  selected: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  /** 매칭된 유저 아이디 */
  userId: Scalars['ID'];
};

export type ClinicEvent = {
  __typename?: 'ClinicEvent';
  /** 이벤트 신청 버튼 */
  applyButton?: Maybe<Scalars['JSONObject']>;
  /** 클리닉 북마크 */
  clinicBookmarks?: Maybe<Array<Maybe<ClinicBookmark>>>;
  /** 같은 ClinicEventProcessing 에서 연결된 형제 이벤트들 */
  clinicEvents: Scalars['JSONObject'];
  /** 컨텐츠 이미지 목록 */
  contentImages: Array<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** DB 가격 (인입 건당 차감 금액) */
  dbPrice?: Maybe<Scalars['Int']>;
  /** 종료 시간 */
  endAt: Scalars['DateTime'];
  /** 클리닉 이벤트 카트 정보 */
  eventCarts?: Maybe<Array<Maybe<EventCart>>>;
  /** 이벤트 유형 */
  eventType: Scalars['String'];
  /** 병원 */
  hospital?: Maybe<Hospital>;
  /** 병원 ID */
  hospitalId?: Maybe<Scalars['ID']>;
  /** Lactea.ads 테이블의 id와 완벽한 1:1 */
  id: Scalars['ID'];
  /** 자동 연장 여부 */
  isAutoExtend: Scalars['Boolean'];
  /** 리뷰 표시 여부 */
  isReviewVisible: Scalars['Boolean'];
  /** 가격 표시의 글자 여부 */
  isTextPrice: Scalars['Boolean'];
  /** 노출 여부 */
  isVisible: Scalars['Boolean'];
  /** Legacy 병원 ID */
  legacyHospitalId?: Maybe<Scalars['ID']>;
  /** 의료심의 번호 */
  medicalConsiderationNumber?: Maybe<Scalars['String']>;
  /** 진료 서비스 */
  medicalService?: Maybe<MedicalService>;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']>;
  /** 원래 가격 */
  originalPrice?: Maybe<Scalars['Int']>;
  /** 제휴 매체 목록 */
  partnership: Array<Scalars['String']>;
  /** 굿닥 가격 */
  price?: Maybe<Scalars['Int']>;
  /** 검색 키워드 목록 */
  searchKeywords: Array<Scalars['String']>;
  /** 시작 시간 */
  startAt: Scalars['DateTime'];
  /** 진행 상태 */
  status: ClinicEventStatusEnum;
  /** 표시 가격 */
  textPrice?: Maybe<Scalars['String']>;
  /** 썸네일 이미지 */
  thumbnailImage?: Maybe<Scalars['String']>;
  /** 이벤트명 */
  title?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum ClinicEventStatusEnum {
  Completed = 'Completed',
  RecordCompleted = 'RecordCompleted'
}

export type ClinicPopularHospitalQueryInput = {
  categorizeBy?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type CodeFErrorOutput = {
  __typename?: 'CodeFErrorOutput';
  result: CodeFResult;
};

export type CodeFExtraResult = {
  __typename?: 'CodeFExtraResult';
  /** 보안문자 */
  reqSecureNo?: Maybe<Scalars['String']>;
  /** 보안문자 새로고침 */
  reqSecureNoRefresh?: Maybe<Scalars['String']>;
};

export type CodeFMedicalCheckupHistoryOutput = {
  __typename?: 'CodeFMedicalCheckupHistoryOutput';
  result: CodeFResult;
  success: Scalars['Boolean'];
};

export type CodeFMedicalCheckupHistoryUnionOutputType =
  | CodeFErrorOutput
  | CodeFMedicalCheckupHistoryOutput
  | CodeFTwoWayOutput;

export type CodeFMedicalCheckupInput = {
  /** ID */
  id?: InputMaybe<Scalars['String']>;
  /** 간편인증 */
  loginTypeLevel?: InputMaybe<SimpleAuthEnum>;
  /** 보안문자 */
  secureNo?: InputMaybe<Scalars['String']>;
  /** 보안문자 새로고침(0: 기본, 1: 재요청, 2: 입력취소) */
  secureNoRefresh?: InputMaybe<Scalars['String']>;
  /** 간편인증 요청(0: 취소, 1: 확인) */
  simpleAuth?: InputMaybe<Scalars['String']>;
  /** 인증정보 */
  twoWayInfo?: InputMaybe<CodeFTwoWayInfo>;
  /** 조회대상(0: 전체, 1: 본인, 2: 영유아) */
  type?: InputMaybe<Scalars['String']>;
};

export type CodeFResult = {
  __typename?: 'CodeFResult';
  /** CF-03002: 추가인증 필요, CF-00000: 성공, 그 외 실패 */
  code: Scalars['String'];
  /** 추가 설명 */
  extraMessage: Scalars['String'];
  /** 설명 */
  message: Scalars['String'];
};

export type CodeFTreatmentAndDrugContentsInput = {
  /** ID */
  id?: InputMaybe<Scalars['String']>;
  /** 간편인증 */
  loginTypeLevel?: InputMaybe<SimpleAuthEnum>;
  /** 보안문자 */
  secureNo?: InputMaybe<Scalars['String']>;
  /** 보안문자 새로고침(0: 기본, 1: 재요청, 2: 입력취소) */
  secureNoRefresh?: InputMaybe<Scalars['String']>;
  /** 간편인증 요청(0: 취소, 1: 확인) */
  simpleAuth?: InputMaybe<Scalars['String']>;
  /** 인증정보 */
  twoWayInfo?: InputMaybe<CodeFTwoWayInfo>;
  /** 조회대상(0: 전체, 1: 본인, 2: 영유아) */
  type?: InputMaybe<Scalars['String']>;
};

export type CodeFTreatmentAndDrugContentsOutput = {
  __typename?: 'CodeFTreatmentAndDrugContentsOutput';
  result: CodeFResult;
  success: Scalars['Boolean'];
};

export type CodeFTreatmentAndDrugContentsQueryInput = {
  /** 내역 조회 기관 */
  medicalFacilityType?: InputMaybe<MedicalFacilityTypeEnum>;
};

export type CodeFTreatmentAndDrugContentsUnionOutput =
  | CodeFErrorOutput
  | CodeFTreatmentAndDrugContentsOutput
  | CodeFTwoWayOutput;

export type CodeFTwoWayData = {
  __typename?: 'CodeFTwoWayData';
  extraInfo: CodeFExtraResult;
  id: Scalars['String'];
  jobIndex: Scalars['Float'];
  jti: Scalars['String'];
  threadIndex: Scalars['Float'];
  twoWayTimestamp: Scalars['Float'];
};

export type CodeFTwoWayInfo = {
  jobIndex: Scalars['Float'];
  jti: Scalars['String'];
  threadIndex: Scalars['Float'];
  twoWayTimestamp: Scalars['Float'];
};

export type CodeFTwoWayOutput = {
  __typename?: 'CodeFTwoWayOutput';
  data: CodeFTwoWayData;
  result: CodeFResult;
};

export type Consent = {
  __typename?: 'Consent';
  appliedAt: Scalars['DateTime'];
  category: ConsentCategoryEnum;
  content: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** @deprecated deletedAt으로 대체됨 */
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  essential: Scalars['Boolean'];
  form: ConsentFormEnum;
  htmlConsent?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  title: Scalars['String'];
  /** @deprecated category로 대체됨 */
  type: ReceiptConsentTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  used: Scalars['Boolean'];
};

export enum ConsentCategoryEnum {
  AppointmentGoodocPrivacyProvisioning = 'appointmentGoodocPrivacyProvisioning',
  AppointmentGoodocSensitiveCollection = 'appointmentGoodocSensitiveCollection',
  CommonHospitalAdvertisementUsage = 'commonHospitalAdvertisementUsage',
  CommonHospitalMarketingUsage = 'commonHospitalMarketingUsage',
  CommonHospitalSalesUsage = 'commonHospitalSalesUsage',
  PatientGoodocPrivacyCollection = 'patientGoodocPrivacyCollection',
  PatientGoodocRepresentativeConfirmation = 'patientGoodocRepresentativeConfirmation',
  PatientGoodocSensitiveCollection = 'patientGoodocSensitiveCollection',
  ReceiptHospitalPrivacyCollection = 'receiptHospitalPrivacyCollection',
  ReceiptHospitalRrnCollection = 'receiptHospitalRrnCollection',
  ReceiptHospitalSensitiveCollection = 'receiptHospitalSensitiveCollection',
  Uncategorized = 'uncategorized'
}

export type ConsentDocumentQueryInput = {
  /** 동의서 종류 */
  categories?: InputMaybe<Array<ConsentCategoryEnum>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 동의서 필수 여부 */
  essential?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  /** 동의서 사용 여부 */
  used?: InputMaybe<Scalars['Boolean']>;
};

export enum ConsentFormEnum {
  Inline = 'inline',
  Url = 'url'
}

export type ConsentHistory = {
  __typename?: 'ConsentHistory';
  consent: Consent;
  consentId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hospital?: Maybe<Hospital>;
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  legalRepresentative?: Maybe<LegalRepresentative>;
  legalRepresentativeId?: Maybe<Scalars['ID']>;
  patient: Patient;
  patientId: Scalars['ID'];
  receipt: Receipt;
  /** 접수 Primary Key */
  receiptId?: Maybe<Scalars['ID']>;
  receiptPatient: ReceiptPatient;
  receiptPatientId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ConsentHistoryCreateInput = {
  consentId: Scalars['ID'];
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 법정대리인 정보 필요 여부 */
  isLegalRepresentativeRequired?: Scalars['Boolean'];
  legalRepresentativeId?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  /** 접수 Primary Key */
  receiptId?: InputMaybe<Scalars['ID']>;
  receiptPatientId?: InputMaybe<Scalars['ID']>;
};

export type ConsentQueryInput = {
  /** 동의서 종류 */
  consentCategories?: InputMaybe<Array<ConsentCategoryEnum>>;
  /** 동의서 타입 (CS1/CS2/CS3/CS4) (deprecated: consentCategories를 대신 사용할 것) */
  consentTypes?: InputMaybe<Array<ReceiptConsentTypeEnum>>;
  /** 중복을 제거할 때의 순서 (MIN = ID 기준 가장 작은 값, MAX = ID 기준 가장 큰 값 / 기본값 = MIN) */
  distinctBy?: InputMaybe<Scalars['String']>;
  /** 중복을 제거할 기준 필드 (hospitalId, patientId, receiptPatientId) */
  distinctWith?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** (deprecated: hospitalIds를 대신 사용할 것) */
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 병원 ID */
  hospitalIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** 환자 ID */
  patientIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 접수 환자 ID */
  receiptPatientIds?: InputMaybe<Array<Scalars['ID']>>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  /** 기존 동의서 조회 여부 */
  withDeprecated?: InputMaybe<Scalars['Boolean']>;
};

export type CreateHospitalOperationTimeInput = {
  /** 시작시간(kst) hh:mm:ss */
  from: Scalars['String'];
  operationOff: Scalars['Boolean'];
  operationType: HospitalOperationTimeTypeEnum;
  /** 종료시간(kst) hh:mm:ss */
  to: Scalars['String'];
};

export type CreateMedicalQuestionnaireSubmittedHistoryInput = {
  /** 생년월일 YYYYMMDD */
  birth: Scalars['String'];
  /** 성별 unknown/male/female */
  gender?: InputMaybe<GenderEnum>;
  hospitalId: Scalars['ID'];
  /** 문진 이름 */
  medicalQuestionnaireName: Scalars['String'];
  /** 문진 요청 object id */
  medicalQuestionnaireRequestHistoryId?: InputMaybe<Scalars['ID']>;
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType?: MedicalQuestionnaireTypes;
  /** 문진 작성대상자 (주로 환자) 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 문진 작성대상자 (주로 환자) 번호 */
  phone: Scalars['String'];
  /** 주민등록번호 7번째 자리 */
  sexdstn: Scalars['Int'];
  /** 제출문진 raw data */
  submittedJsonData: Scalars['JSONObject'];
  /** 문진 s3 경로 */
  url?: InputMaybe<Scalars['String']>;
};

export type CreateMedicineAlarmInput = {
  /** 알람 시간(HH:mm) */
  medicineAlarmTimes: Array<Scalars['String']>;
  /** 알람 이름 */
  name: Scalars['String'];
};

export type CreateReceiptMobileInput = {
  /** 주소 */
  address: Scalars['String'];
  /** 차트 환자 id (비연동 접수일경우 Nullable) */
  chartPatientId?: InputMaybe<Scalars['String']>;
  /** 동의서 번호 배열 */
  consentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 모바일용 사용자와 병원의 거리(m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 방문 경로 */
  funnel: Scalars['String'];
  /** 모바일용 병원아이디 */
  hospitalId: Scalars['ID'];
  /** 법정대리인 주소 */
  legalRepresentativeAddress?: InputMaybe<Scalars['String']>;
  /** 법정대리인 이름 */
  legalRepresentativeName?: InputMaybe<Scalars['String']>;
  /** 법정대리인 전화번호 */
  legalRepresentativePhone?: InputMaybe<Scalars['String']>;
  /** 법정대리인 관계 */
  legalRepresentativeRelationship?: InputMaybe<Scalars['String']>;
  /** 법정대리인 주소 우편번호 */
  legalRepresentativeZipcode?: InputMaybe<Scalars['String']>;
  /** 접수 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 기타 증상 */
  otherSymptom: Scalars['String'];
  /** 휴대폰 번호 (- 없음) */
  phone: Scalars['String'];
  /** 문진 요청 알림톡 전달받을 번호 */
  requestMedicalQuestionnairePhone?: InputMaybe<Scalars['String']>;
  /** 주민등록번호 (- 없음) */
  residentRegistrationNumber: Scalars['String'];
  /** 진료실 primary key (비연동 접수일경우 Nullable) */
  roomId?: InputMaybe<Scalars['ID']>;
  /** 접수 신청 경로 유형 */
  sourceType?: ReceiptSourceTypeEnum;
  /** 내원 목적 list */
  symptoms?: InputMaybe<Array<Scalars['String']>>;
  /** 접수항목 primary key */
  treatmentItemId: Scalars['ID'];
  /** 모바일용 유저 번호 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 우편번호 */
  zipcode: Scalars['String'];
};

export type CreateReceiptQrInput = {
  /** 주소 */
  address: Scalars['String'];
  /** 차트 환자 id (비연동 접수일경우 Nullable) */
  chartPatientId?: InputMaybe<Scalars['String']>;
  /** 동의서 번호 배열 */
  consentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 모바일용 사용자와 병원의 거리(m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 방문 경로 */
  funnel: Scalars['String'];
  /** QR용 병원아이디 */
  hospitalId: Scalars['ID'];
  /** 법정대리인 주소 */
  legalRepresentativeAddress?: InputMaybe<Scalars['String']>;
  /** 법정대리인 이름 */
  legalRepresentativeName?: InputMaybe<Scalars['String']>;
  /** 법정대리인 전화번호 */
  legalRepresentativePhone?: InputMaybe<Scalars['String']>;
  /** 법정대리인 관계 */
  legalRepresentativeRelationship?: InputMaybe<Scalars['String']>;
  /** 법정대리인 주소 우편번호 */
  legalRepresentativeZipcode?: InputMaybe<Scalars['String']>;
  /** 접수 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 기타 증상 */
  otherSymptom: Scalars['String'];
  /** 휴대폰 번호 (- 없음) */
  phone: Scalars['String'];
  /** 문진 요청 알림톡 전달받을 번호 */
  requestMedicalQuestionnairePhone?: InputMaybe<Scalars['String']>;
  /** 주민등록번호 (- 없음) */
  residentRegistrationNumber: Scalars['String'];
  /** 진료실 primary key (비연동 접수일경우 Nullable) */
  roomId?: InputMaybe<Scalars['ID']>;
  /** 접수 신청 경로 유형 */
  sourceType?: ReceiptSourceTypeEnum;
  /** 내원 목적 list */
  symptoms?: InputMaybe<Array<Scalars['String']>>;
  /** 접수항목 primary key */
  treatmentItemId: Scalars['ID'];
  /** 모바일용 유저 번호 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 우편번호 */
  zipcode: Scalars['String'];
};

/** 리뷰 생성 인풋 */
export type CreateReviewInput = {
  /** 연계 예약 ID */
  appointmentId?: InputMaybe<Scalars['ID']>;
  /** CLS 의 이벤트 리뷰 작성시 필요한 CallRequest ID */
  callRequestId?: InputMaybe<Scalars['ID']>;
  /** 리뷰 내용 */
  contents?: InputMaybe<Scalars['String']>;
  /** 진료 만족도 */
  diagnosisScore?: Scalars['Int'];
  /** 의사선생님 아이디 */
  doctorId?: InputMaybe<Scalars['ID']>;
  /** 연계 이벤트 ID */
  eventId?: InputMaybe<Scalars['ID']>;
  /** 시설 만족도 */
  facilityScore?: Scalars['Int'];
  /** 연계 병원 ID */
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 이미지 업로드 URL */
  images?: InputMaybe<Array<Scalars['String']>>;
  /** 인증/비인증 */
  isVisited?: Scalars['Boolean'];
  /** 친절 만족도 */
  kindnessScore?: Scalars['Int'];
  /** 연계 약국 ID */
  pharmacyId?: InputMaybe<Scalars['ID']>;
  /** 평점 */
  rating?: InputMaybe<Scalars['Int']>;
  /** 연계 접수 ID */
  receiptId?: InputMaybe<Scalars['ID']>;
  /** 재방문 */
  recommendation?: InputMaybe<Scalars['Boolean']>;
  /** 병원|약국|클리닉|이벤트|비대면 타입 */
  reviewType?: InputMaybe<ReviewTypeEnum>;
  /** 리뷰 작성 경로 */
  source?: InputMaybe<ReviewSourceEnum>;
  /** 병원|약국|클리닉|이벤트|비대면 아이디 */
  targetId?: InputMaybe<Scalars['ID']>;
  /** 진료항목 */
  treatmentName?: InputMaybe<Scalars['String']>;
  /** 진료비용 */
  treatmentPrice?: Scalars['Float'];
  /** 연계 비대면진료 ID */
  untactTreatmentId?: InputMaybe<Scalars['ID']>;
  /** 작성자 이메일 */
  userEmail?: InputMaybe<Scalars['String']>;
  /** 작성자 이름 */
  userName: Scalars['String'];
  /** 방문일 */
  visitedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 리뷰 신고 */
export type CreateReviewReportInput = {
  /** 리뷰 ID */
  reviewId: Scalars['ID'];
  /** 신고 유형 */
  type: ReviewReportTypeEnum;
};

export type CreateSymptomImage = {
  __typename?: 'CreateSymptomImage';
  s3Key: Scalars['String'];
  url: Scalars['String'];
};

export type CreateUserReceiptInput = {
  /** 환자 생년월일(yymmdd) */
  birth?: InputMaybe<Scalars['String']>;
  /** 환자명 */
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
  /** 환자 휴대폰 번호(- 없음) */
  phone?: InputMaybe<Scalars['String']>;
  receiptPatientId?: InputMaybe<Scalars['ID']>;
  /** 환자 주민등록번호(- 없음) */
  residentRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 환자 주민등록번호 7번째숫자 */
  sexdstn?: InputMaybe<Scalars['Float']>;
  /** 접수 신청 경로 유형(tablet, mobile, qr) */
  sourceTypes?: InputMaybe<Array<ReceiptSourceTypeEnum>>;
};

export type CrmSendHistory = {
  __typename?: 'CrmSendHistory';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  errorMessage: Scalars['String'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  message: Scalars['String'];
  messageId?: Maybe<Scalars['String']>;
  methodType: CrmSendingMethodTypeEnum;
  name: Scalars['String'];
  phone: Scalars['String'];
  receiptPatient: ReceiptPatient;
  receiptPatientId: Scalars['ID'];
  status: CrmSendSmsStatusTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum CrmSendSmsStatusTypeEnum {
  Failure = 'failure',
  Success = 'success',
  Wating = 'wating'
}

export type CrmSenderProfile = {
  __typename?: 'CrmSenderProfile';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  phone: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum CrmSendingMethodTypeEnum {
  Alimtalk = 'alimtalk',
  Lms = 'lms'
}

export type CurationHospitalsQueryInput = {
  /** 회원 위치 위도 */
  latitude?: InputMaybe<Scalars['Float']>;
  /** 회원 위치 경도 */
  longitude?: InputMaybe<Scalars['Float']>;
  /** 태그이름 */
  managedTagName: Scalars['String'];
  /** 회원ID */
  userId?: InputMaybe<Scalars['String']>;
};

export type CursorPageInfoObject = {
  __typename?: 'CursorPageInfoObject';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** 커서렁 페이지네이션된 병원 검색 결과 */
export type CursorPaginatedHospitalSearchObject = {
  __typename?: 'CursorPaginatedHospitalSearchObject';
  countTotal: Scalars['Int'];
  edges?: Maybe<Array<HospitalSearchObjectEdge>>;
  pageInfo?: Maybe<CursorPageInfoObject>;
};

/** 커서렁 페이지네이션된 약국 검색 결과 */
export type CursorPaginatedPharmacySearchObject = {
  __typename?: 'CursorPaginatedPharmacySearchObject';
  countTotal: Scalars['Int'];
  edges?: Maybe<Array<PharmacySearchObjectEdge>>;
  pageInfo?: Maybe<CursorPageInfoObject>;
};

export type CursorPaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type Delivery = {
  __typename?: 'Delivery';
  address: Scalars['String'];
  addressDetail: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** @deprecated deliveryPayment를 deliveryPayments로 바꾸기 위해 남겨둠 */
  deliveryPayment?: Maybe<Array<DeliveryPayment>>;
  deliveryPayments?: Maybe<Array<DeliveryPayment>>;
  /** 퀵 대행사 */
  directDeliveryAgency?: Maybe<Scalars['String']>;
  /** 퀵 대행사 요청 아이디 */
  directDeliveryAgencyRequestId?: Maybe<Scalars['String']>;
  /** 직접(퀵) 배송 완료 시간 로그 */
  directDeliveryCompleteAt?: Maybe<Scalars['DateTime']>;
  /** 처방전 직접배달(퀵) 상태 */
  directDeliveryStatus?: Maybe<DirectDeliveryStatusEnum>;
  /** 직접(퀵) 픽업완료 시간 로그 */
  directPickupCompleteAt?: Maybe<Scalars['DateTime']>;
  /** 직접(퀵) 픽업요청 시간 로그 */
  directPickupRequestAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  /** 처방전 배달 방식 */
  method?: Maybe<DeliveryIndirectMethodEnum>;
  note: Scalars['String'];
  parcelAgency?: Maybe<UntactPharmacyParcelAgency>;
  /** 택배사 */
  parcelAgencyId?: Maybe<Scalars['ID']>;
  /** 택배 배송완료 시간 로그 */
  parcelDeliveryCompleteAt?: Maybe<Scalars['DateTime']>;
  /** 택배 배송 시작 시간 로그 */
  parcelDeliveryStartAt?: Maybe<Scalars['DateTime']>;
  /** 택배 송장번호 */
  parcelInvoiceNumber?: Maybe<Scalars['String']>;
  /** 처방전 택배배달 상태 */
  parcelStatus?: Maybe<ParcelDeliveryStatusEnum>;
  pharmacyAdminUser?: Maybe<PharmacyAdminUser>;
  /** 매칭된 약사(약국어드민) 아이디 */
  pharmacyAdminUserId?: Maybe<Scalars['ID']>;
  /** 약사(약국어드민) 매칭된 시각 */
  pharmacyAdminUserTakedAt?: Maybe<Scalars['DateTime']>;
  postNumber: Scalars['String'];
  prescription: Prescription;
  prescriptionId: Scalars['ID'];
  /** 수납금액 로그 */
  prescriptionPayment?: Maybe<Scalars['Int']>;
  recipient: Scalars['String'];
  recipientPhone: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum DeliveryIndirectMethodEnum {
  Dailydelivery = 'dailydelivery',
  Directdelivery = 'directdelivery',
  Parcel = 'parcel'
}

export enum DeliveryMethodEnum {
  Dailydelivery = 'dailydelivery',
  Directdelivery = 'directdelivery',
  Parcel = 'parcel',
  Visit = 'visit'
}

export type DeliveryPayment = {
  __typename?: 'DeliveryPayment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  delivery: Delivery;
  deliveryId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  paymentId?: Maybe<Scalars['ID']>;
  paymentMethodId: Scalars['ID'];
  paymentStatus?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type Department = {
  __typename?: 'Department';
  /** 분류명 (의과, 치과, 한의과) */
  categoryName: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  departmentHospitals: Array<DepartmentHospital>;
  description?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 부서 아이콘 이미지 */
  image?: Maybe<Scalars['String']>;
  /** 클라이언트 노출 부서명 */
  name: Scalars['String'];
  /** 원본 부서명 */
  originalName: Scalars['String'];
  sort: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type DepartmentHospital = {
  __typename?: 'DepartmentHospital';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  department: Department;
  hospital: Hospital;
  /** pk */
  id: Scalars['ID'];
  representative: Scalars['Boolean'];
};

export type Device = {
  __typename?: 'Device';
  appVersion: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deviceId: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  os: DeviceOsEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  userId?: Maybe<Scalars['ID']>;
  uuid: Scalars['String'];
};

export enum DeviceOsEnum {
  Aos = 'aos',
  Ios = 'ios'
}

export type DigitalSignage = {
  __typename?: 'DigitalSignage';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  hospital: Hospital;
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};

export enum DirectDeliveryStatusEnum {
  MakingStart = 'makingStart',
  PickupComplete = 'pickupComplete',
  PickupRequest = 'pickupRequest',
  Waiting = 'waiting'
}

export type DistrictObject = {
  __typename?: 'DistrictObject';
  /** 법정동 코드 */
  code: Scalars['String'];
  /** 법정동 이름 */
  name: Scalars['String'];
  /** 상위 코드 */
  upperCode?: Maybe<Scalars['String']>;
};

export type Doctor = {
  __typename?: 'Doctor';
  clinicVisibility: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  defaultImage: Scalars['String'];
  /** 삭제일 */
  deletedAt: Scalars['DateTime'];
  doctorDepartments: Array<DoctorDepartment>;
  /** 의사 학력 정보 */
  doctorEducations?: Maybe<Array<Maybe<DoctorEducation>>>;
  /** 비급여 진료비 정보 */
  doctorMedicalServices?: Maybe<Array<Maybe<DoctorMedicalService>>>;
  /** 전문의 정보 */
  doctorSpecialistDepartments?: Maybe<Array<Maybe<DoctorSpecialistDepartment>>>;
  doctorTags?: Maybe<Array<Maybe<DoctorTag>>>;
  doctorUntactDepartments?: Maybe<Array<Maybe<DoctorUntactDepartment>>>;
  gender: DoctorGenderEnum;
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  /** 의료진 상세 소개 */
  introductionDetail?: Maybe<Scalars['String']>;
  /** 의료진 한줄 소개 */
  introductionSummary?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  /** 학력 및 경력 및 수상이력 */
  profile?: Maybe<Scalars['String']>;
  reviewSummary: ReviewSummary;
  reviews?: Maybe<Array<Maybe<Review>>>;
  /** 어워드 뱃지 표시 여부 */
  showAwardBadge?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
  sourceType?: Maybe<Scalars['String']>;
  treatments: Array<Treatment>;
  tvImageUrl?: Maybe<Scalars['String']>;
  tvVisibility: Scalars['Boolean'];
  /** 기본, 치과, 한의학 구분 */
  type: MedicalTypeEnum;
  /** 비대면 의사 진료 요약 정보 */
  untactConfiguration?: Maybe<UntactDoctorConfiguration>;
  untactDoctorTreatmentRankings?: Maybe<Array<Maybe<UntactDoctorTreatmentRanking>>>;
  /** 비대면 의사 진료 요약 정보 */
  untactDoctorTreatmentSummary?: Maybe<UntactDoctorTreatmentSummary>;
  /** 비대면 공지사항 */
  untactNotice?: Maybe<Scalars['String']>;
  untactOnTreatment: Scalars['Boolean'];
  untactVisibility: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type DoctorDepartment = {
  __typename?: 'DoctorDepartment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  department: Department;
  doctor: Doctor;
  /** pk */
  id: Scalars['ID'];
};

export type DoctorEducation = {
  __typename?: 'DoctorEducation';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  doctorId: Scalars['Int'];
  /** 교육 종류 */
  educationType: EducationTypeEnum;
  /** 졸업/수료 연도 */
  graduationYear: Scalars['Int'];
  /** pk */
  id: Scalars['ID'];
  school: School;
  schoolId: Scalars['Int'];
  specialistDepartment: SpecialistDepartment;
  /** 전공의 */
  specialistDepartmentId: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum DoctorGenderEnum {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}

export type DoctorMedicalService = {
  __typename?: 'DoctorMedicalService';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  doctorId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  medicalService: MedicalService;
  medicalServiceId?: Maybe<Scalars['ID']>;
};

export type DoctorSpecialistDepartment = {
  __typename?: 'DoctorSpecialistDepartment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  doctorId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 전문의 대표과목 여부 */
  representative: Scalars['Boolean'];
  specialistDepartment: SpecialistDepartment;
  specialistDepartmentId: Scalars['ID'];
};

export type DoctorTag = {
  __typename?: 'DoctorTag';
  contentType: DoctrTagTypeEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  doctorId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type DoctorUntactDepartment = {
  __typename?: 'DoctorUntactDepartment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor?: Maybe<Doctor>;
  /** pk */
  id: Scalars['ID'];
  untactDepartment?: Maybe<UntactDepartment>;
};

export enum DoctrTagTypeEnum {
  CareDisease = 'careDisease',
  Normal = 'normal',
  Specialist = 'specialist'
}

/** 병원어드민 회원가입 직접 입력 병원 */
export type DraftHospital = {
  __typename?: 'DraftHospital';
  /** 주소 */
  address: Scalars['String'];
  /** (현장접수) 병원이 요청한 병원 별칭 */
  addressDetail: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospitalAdminUser: HospitalAdminUser;
  /** pk */
  id: Scalars['ID'];
  /** 병원명 */
  name: Scalars['String'];
  /** 운영상태 */
  operatedStatus: DraftHospitalActivedStatus;
  /** 전화번호 */
  phone: Scalars['String'];
  toBeOperatedAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum DraftHospitalActivedStatus {
  Operated = 'operated',
  ToBeOperated = 'toBeOperated'
}

export enum EducationTypeEnum {
  AdjuctProfessor = 'adjuctProfessor',
  AssistantProfessor = 'assistantProfessor',
  AssociateProfessor = 'associateProfessor',
  ClinicalProfessor = 'clinicalProfessor',
  Fellowship = 'fellowship',
  Graduate = 'graduate',
  Internship = 'internship',
  Professor = 'professor',
  Residency = 'residency',
  Undergraduate = 'undergraduate'
}

export type EnQueueCreateMobileReceiptInput = {
  /** 주소 */
  address: Scalars['String'];
  /** 차트 환자 id (비연동 접수일경우 Nullable) */
  chartPatientId?: InputMaybe<Scalars['String']>;
  /** 동의서 번호 배열 */
  consentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 모바일용 사용자와 병원의 거리(m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 방문 경로 */
  funnel: Scalars['String'];
  /** 모바일용 병원아이디 */
  hospitalId: Scalars['ID'];
  /** 법정대리인 주소 */
  legalRepresentativeAddress?: InputMaybe<Scalars['String']>;
  /** 법정대리인 이름 */
  legalRepresentativeName?: InputMaybe<Scalars['String']>;
  /** 법정대리인 전화번호 */
  legalRepresentativePhone?: InputMaybe<Scalars['String']>;
  /** 법정대리인 관계 */
  legalRepresentativeRelationship?: InputMaybe<Scalars['String']>;
  /** 법정대리인 주소 우편번호 */
  legalRepresentativeZipcode?: InputMaybe<Scalars['String']>;
  /** 접수 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 기타 증상 */
  otherSymptom: Scalars['String'];
  /** 휴대폰 번호 (- 없음) */
  phone: Scalars['String'];
  /** 문진 요청 알림톡 전달받을 번호 */
  requestMedicalQuestionnairePhone?: InputMaybe<Scalars['String']>;
  /** 주민등록번호 (- 없음) */
  residentRegistrationNumber: Scalars['String'];
  /** 진료실 primary key (비연동 접수일경우 Nullable) */
  roomId?: InputMaybe<Scalars['ID']>;
  /** 접수 신청 경로 유형 */
  sourceType?: ReceiptSourceTypeEnum;
  /** 내원 목적 list */
  symptoms?: InputMaybe<Array<Scalars['String']>>;
  /** 접수항목 primary key */
  treatmentItemId: Scalars['ID'];
  /** 모바일용 유저 번호 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 우편번호 */
  zipcode: Scalars['String'];
};

export type EnQueueCreateMobileReceiptOutput = {
  __typename?: 'EnQueueCreateMobileReceiptOutput';
  receiptId?: Maybe<Scalars['String']>;
  receiptOfTodayId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum EstablishmentDivisionEnum {
  /** 군립 */
  Army = 'army',
  /** 도립 */
  Invert = 'invert',
  /** 국립 */
  National = 'national',
  /** 국공립 */
  NationalPublic = 'nationalPublic',
  /** 국립대학 */
  NationalUniversity = 'nationalUniversity',
  /** 사립 */
  Private = 'private',
  /** 모름 */
  Unknown = 'unknown'
}

export type EventCart = {
  __typename?: 'EventCart';
  clinicEvent: ClinicEvent;
  /** 이벤트 아이디 */
  clinicEventId: Scalars['ID'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 병원 아이디 */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  /** 카트 유저 아이디 */
  userId: Scalars['ID'];
};

export type EventLike = {
  __typename?: 'EventLike';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 매칭된 이벤트 아이디 */
  eventId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  /** 매칭된 유저 아이디 */
  userId: Scalars['ID'];
};

export enum ExceptionEligibleTypeEnum {
  ConfirmedInfectiousDisease = 'confirmedInfectiousDisease',
  FollowUpWithin6Months = 'followUpWithin6Months',
  RegisteredDisability = 'registeredDisability',
  ResidentMedicalVulnerable = 'residentMedicalVulnerable',
  SeniorWithLongTermCare = 'seniorWithLongTermCare'
}

export type FilteredReceiptQueryInput = {
  hospitalId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  phone?: InputMaybe<Scalars['String']>;
  residentRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 접수 타입) C01, C03, C04, C07, F03, F05 */
  statuses?: InputMaybe<Array<ReceiptStatusEnum>>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type FilteredReceipts = {
  __typename?: 'FilteredReceipts';
  /** 유저가 생성한 접수 리스트 */
  data: Array<Receipt>;
  /** 조회된 전체 접수 갯수 */
  totalCount: Scalars['Int'];
};

/** 팝업 종류 & 닫기 설정 내역 기반 filtering */
export type FindGoodocPopupInput = {
  /** 닫기 설정 history */
  closeOptionHistories?: Array<UserHistoriesOnCloseOptionOfGoodocPopup>;
  popupType?: InputMaybe<GoodocPopupTypeEnum>;
};

export type FindNonLinkPatientByResidentRegistrationNumberInput = {
  /** 환자주민등록번호 */
  residentRegistrationNumber: Scalars['String'];
  /** 접수 인입 유형 */
  sourceType?: ReceiptSourceTypeEnum;
  /** mobile user id */
  userId?: InputMaybe<Scalars['String']>;
};

export type FindNonLinkPatientsByPhoneInput = {
  /** 환자전화번호 */
  phone: Scalars['String'];
  /** 접수 인입 유형 */
  sourceType?: ReceiptSourceTypeEnum;
  /** mobile user id */
  userId?: InputMaybe<Scalars['String']>;
};

export type FindNonLinkPatientsOutput = {
  __typename?: 'FindNonLinkPatientsOutput';
  address?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['String']>;
  /** 동의서 작성 여부 */
  consentAccepted: Scalars['Boolean'];
  /** 동의서 작성 시각 */
  consentAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  residentRegistrationNumber?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type FindPatientsInChartInput = {
  /** 휴대폰 번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 주민등록 번호 */
  residentRegistrationNumber?: InputMaybe<Scalars['String']>;
};

export type FindPatientsInChartOutput = {
  __typename?: 'FindPatientsInChartOutput';
  address?: Maybe<Scalars['String']>;
  /** 차트상 환자 아이디 */
  chartPatientId?: Maybe<Scalars['String']>;
  /** 동의서 작성 여부 */
  consentAccepted: Scalars['Boolean'];
  /** 동의서 작성 시각 */
  consentAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  residentRegistrationNumber?: Maybe<Scalars['String']>;
  /** 굿닥 유저 아이디 */
  userId?: Maybe<Scalars['ID']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** 투표/이벤트 filtering */
export type FindVotingEventInput = {
  /** 투표 이벤트 종류 : 기본값(all) */
  votingEventType?: InputMaybe<VotingEventTypeEnum>;
};

export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}

export type GoodocPopup = {
  __typename?: 'GoodocPopup';
  /** 화일명 for User */
  attachedFilenames?: Maybe<Array<Scalars['String']>>;
  /** 첨부파일 urls */
  attachedUrls?: Maybe<Array<Scalars['String']>>;
  /** 버튼 레이블 */
  buttonLabel?: Maybe<Scalars['String']>;
  /** 버튼 스타일 2000자내외 Json형식 */
  buttonStyle?: Maybe<Scalars['String']>;
  /** 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 팝업 노출 종료일 */
  exposureEndDate: Scalars['DateTime'];
  /** 팝업 노출 시작일 */
  exposureStartDate: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** image links */
  imageLinks?: Maybe<Array<Scalars['String']>>;
  /** image urls */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 닫기 설정(3) : 오늘 하루 보지 않기(default), 다시 보지 않기, 사용 안 함. */
  popupCloseOptionType: GoodocPopupCloseOptionTypeEnum;
  /** 팝업 종류 구분 */
  popupType: GoodocPopupTypeEnum;
  /** 제목 */
  title: Scalars['String'];
  /** 작성자 */
  uberAdminUser: UberAdminUser;
  uberAdminUserId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 노출 여부, (당연히) 노출 기간 설정 보다 우선 함. */
  visibility: Scalars['Boolean'];
};

/** 닫기 설정 */
export enum GoodocPopupCloseOptionTypeEnum {
  /** 사용 안 함 */
  AlwaysVisible = 'alwaysVisible',
  /** 다시 보지 않기 */
  HideForever = 'hideForever',
  /** 7일간 보지 않기 */
  HideOneWeek = 'hideOneWeek',
  /** 오늘 하루 보지 않기(default) */
  HideTodayOnly = 'hideTodayOnly',
  /** 한번만 노출하기 */
  ShowOnce = 'showOnce'
}

/** 팝업 사용처 */
export enum GoodocPopupTypeEnum {
  /** 전체 */
  All = 'all',
  /** 모바일앱 */
  App = 'app',
  /** 클리닉 */
  Clinic = 'clinic',
  /** 파트너스 */
  Hospital = 'hospital',
  /** 파트너스 - 예약서비스 */
  PartnersAppointments = 'partnersAppointments',
  /** 파트너스 - 접수서비스 */
  PartnersReceipt = 'partnersReceipt',
  /** 현장접수 */
  Tablet = 'tablet',
  /** 비대면병원 */
  UntactHospital = 'untactHospital',
  /** 비대면약국 */
  UntactPharmacy = 'untactPharmacy'
}

/** 구글 스프레드시트 업데이트 타입 */
export enum GoogleSpreadSheetUpdateType {
  Append = 'APPEND',
  Specific = 'SPECIFIC'
}

export type Hospital = {
  __typename?: 'Hospital';
  address?: Maybe<Scalars['String']>;
  admins?: Maybe<Array<Maybe<HospitalAdminUser>>>;
  /** (현장접수) 병원이 요청한 병원 별칭 */
  aliasName?: Maybe<Scalars['String']>;
  appointmentHospitalConfiguration?: Maybe<AppointmentHospitalConfiguration>;
  appointmentHospitalSchedules?: Maybe<Array<Maybe<AppointmentHospitalSchedule>>>;
  appointmentTreatments?: Maybe<Array<Maybe<AppointmentTreatment>>>;
  /**
   * 예약 유형
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  appointmentType: AppointmentTypeEnum;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  aroundStationInformation?: Maybe<Scalars['String']>;
  /** 평균대기시간(분) */
  avgWaitingTime?: Maybe<Scalars['Int']>;
  bankAccount?: Maybe<UntactHospitalBankAccount>;
  bridge?: Maybe<Array<Maybe<ReceiptBridge>>>;
  /** 클리닉 북마크 */
  clinicBookmarks?: Maybe<Array<Maybe<ClinicBookmark>>>;
  /** 연관 이벤트 리스트 */
  clinicEvents?: Maybe<Array<Maybe<ClinicEvent>>>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  crmHistories?: Maybe<Array<CrmSendHistory>>;
  crmSendHistories?: Maybe<Array<Maybe<CrmSendHistory>>>;
  crmSenderProfiles?: Maybe<Array<Maybe<CrmSenderProfile>>>;
  digitalSignage?: Maybe<Array<Maybe<DigitalSignage>>>;
  doctors?: Maybe<Array<Maybe<Doctor>>>;
  emergencyAvailability: Scalars['Boolean'];
  /**
   * 예약가능 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  enableAppointment: Scalars['Boolean'];
  enableReceiptWaitingCounter: Scalars['Boolean'];
  establishmentDivision: EstablishmentDivisionEnum;
  hiraCode?: Maybe<Scalars['String']>;
  /** 건강보험심사평가원(심평원)에 등록된 병원명 */
  hiraHospitalName?: Maybe<Scalars['String']>;
  homepageUrl?: Maybe<Scalars['String']>;
  /**  병원 주소 정보  */
  hospitalAddress?: Maybe<HospitalAddress>;
  hospitalBlogReviews?: Maybe<Array<Maybe<HospitalBlogReview>>>;
  hospitalCareDiseases?: Maybe<Array<Maybe<HospitalCareDisease>>>;
  /** 병원의 진료과목 */
  hospitalDepartments?: Maybe<Array<Maybe<DepartmentHospital>>>;
  /** 병원 구분 (심평원) */
  hospitalDivision?: Maybe<Scalars['String']>;
  hospitalEquipments?: Maybe<Array<Maybe<HospitalEquipment>>>;
  hospitalHospitalAdminUsers?: Maybe<Array<Maybe<HospitalHospitalAdminUser>>>;
  hospitalLikes?: Maybe<Array<Maybe<HospitalLike>>>;
  hospitalNotices?: Maybe<Array<Maybe<HospitalNotice>>>;
  /** @deprecated operationTimes 로 사용해야함 */
  hospitalOperationTimes?: Maybe<Array<Maybe<HospitalOperationTime>>>;
  hospitalPictures?: Maybe<Array<Scalars['String']>>;
  hospitalSearchStatistics?: Maybe<HospitalSearchStatistics>;
  hospitalTags?: Maybe<Array<Maybe<HospitalTag>>>;
  hospitalThemes?: Maybe<Array<Maybe<HospitalTheme>>>;
  /** pk */
  id: Scalars['ID'];
  introductionContent?: Maybe<Scalars['String']>;
  introductionSubTitle?: Maybe<Scalars['String']>;
  introductionTitle?: Maybe<Scalars['String']>;
  /**
   * 인기예약 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  isFamousAppointment: Scalars['Boolean'];
  isMobileReceiptAvailable: Scalars['Boolean'];
  /** 리뷰 소식 알림(리뷰 구분 없이 전체) */
  isReviewNotification?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  legacyHospital?: Maybe<HospitalLegacyHospital>;
  likedUser?: Maybe<Array<Maybe<UserObject>>>;
  /** 좋아요한 유저 수 (목록 쿼리에서 사용 금지) */
  likedUserCount: Scalars['Int'];
  lmsTemplates?: Maybe<Array<Maybe<LmsTemplate>>>;
  longitude?: Maybe<Scalars['Float']>;
  /**  할당된 태그 리스트  */
  managedTags?: Maybe<Array<Maybe<ManagedTag>>>;
  medicalQuestionnaireRequestHistories?: Maybe<
    Array<Maybe<MedicalQuestionnaireRequestHistory>>
  >;
  medicalQuestionnaireSubmittedHistories?: Maybe<
    Array<Maybe<MedicalQuestionnaireSubmittedHistory>>
  >;
  medicalQuestionnaires?: Maybe<Array<Maybe<MedicalQuestionnaire>>>;
  /** 연관 진료과 리스트 */
  medicalServices?: Maybe<Array<Maybe<MedicalService>>>;
  messageTemplates?: Maybe<Array<Maybe<HospitalMessageTemplate>>>;
  /** 클라이언트에 노출되는 병원명 */
  name: Scalars['String'];
  nhisCode?: Maybe<Scalars['String']>;
  nmcCode?: Maybe<Scalars['String']>;
  noWaitingTime?: Maybe<Scalars['String']>;
  /** 개원일 */
  openedAt?: Maybe<Scalars['DateTime']>;
  /** 개원일 */
  openedDate?: Maybe<Scalars['String']>;
  /** 운영 상태 (개폐업여부) */
  operatingState: HospitalOperatingStateEnum;
  operationTimeDescription?: Maybe<Scalars['String']>;
  operationTimes?: Maybe<Array<Maybe<HospitalOperationTime>>>;
  parkingAvailability: Scalars['Boolean'];
  parkingDescription?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receiptHospitalConfiguration?: Maybe<ReceiptHospitalConfiguration>;
  receiptServiceAlliance?: Maybe<ReceiptServiceAlliance>;
  /** 대표 진료 과목 */
  representativeDepartments?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewSummary: ReviewSummary;
  reviewVisibility: Scalars['Boolean'];
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  safetyPhone?: Maybe<Scalars['String']>;
  /** 클리닉마켓 계약 여부 */
  signClinicMarketContract?: Maybe<Scalars['Boolean']>;
  simplyIntroduction?: Maybe<Scalars['String']>;
  themes?: Maybe<Array<Maybe<HospitalThemeObject>>>;
  treatmentItems?: Maybe<Array<Maybe<ReceiptTreatmentItem>>>;
  /**
   * 당일예약불가 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  unableSameDayAppointment: Scalars['Boolean'];
  untactHospitalConfiguration?: Maybe<UntactHospitalConfiguration>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 병원 어드민 계정 사용 유무 */
  usingHospitalAdminUser?: Maybe<Scalars['Boolean']>;
  videoAdvertisementPlaylist?: Maybe<Array<Maybe<VideoAdvertisementPlaylist>>>;
  videoAdvertisementPlaylistHistories?: Maybe<
    Array<Maybe<VideoAdvertisementPlaylistHistory>>
  >;
  visibility: Scalars['Boolean'];
  webcams: Array<Webcam>;
};

export type HospitalAddress = {
  __typename?: 'HospitalAddress';
  /** 주소 코드 (읍면동 코드) */
  addressCode?: Maybe<Scalars['String']>;
  /** 읍/면 이름 ("동" 지역일 경우 null) */
  bname1?: Maybe<Scalars['String']>;
  /** 법정동/법정리 이름 (역삼동, 송전리 등...) */
  bname2: Scalars['String'];
  /** 건물명 */
  buildingName?: Maybe<Scalars['String']>;
  /** 건물번호 */
  buildingNo: Scalars['String'];
  /** 상세 주소 */
  detailAddress?: Maybe<Scalars['String']>;
  /** 행정동 이름 (역삼1동, 송전1동 등...) */
  hname?: Maybe<Scalars['String']>;
  /** 병원 ID */
  hospitalId: Scalars['String'];
  /** 지번 */
  jibun: Scalars['String'];
  /** 지번 주소 */
  jibunAddress: Scalars['String'];
  /** 도로명 주소 */
  roadAddress: Scalars['String'];
  /** 도로명 */
  roadName: Scalars['String'];
  /**  간략 주소  */
  shortAddress: Scalars['String'];
  /**  짧은 시/도 이름 (서울, 인천, 경기 등...) */
  shortSido: Scalars['String'];
  /** 시/도 이름 (서울시, 경기도 등...) */
  sido: Scalars['String'];
  /** 시/군/구 이름 (강남구, 성남시 분당구 등...) */
  sigungu: Scalars['String'];
};

export type HospitalAdminAuthority = {
  __typename?: 'HospitalAdminAuthority';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제 시각 */
  deletedAt: Scalars['DateTime'];
  /** 직무 */
  hospitalAdminRole?: Maybe<HospitalAdminRole>;
  /** 직무 ID */
  hospitalAdminRoleId?: Maybe<Scalars['ID']>;
  /** 병원-계정 매핑 */
  hospitalHospitalAdminUser: HospitalHospitalAdminUser;
  /** 병원-계정 매핑 ID */
  hospitalHospitalAdminUserId: Scalars['ID'];
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 관리자 계정 여부 */
  isMaster: Scalars['Boolean'];
  /** 대표 계정 여부 */
  isRepresentative: Scalars['Boolean'];
  /** 인가 상태 */
  status: HospitalAdminAuthorityStatusEnum;
  /** 인가 타입 */
  type: HospitalAdminAuthorityTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum HospitalAdminAuthorityStatusEnum {
  AuthorityApproved = 'authorityApproved',
  AuthorityRejected = 'authorityRejected',
  AuthorityRequested = 'authorityRequested'
}

export enum HospitalAdminAuthorityTypeEnum {
  PartnersAuthority = 'partnersAuthority'
}

export type HospitalAdminPermission = {
  __typename?: 'HospitalAdminPermission';
  /** 하위 권한 */
  childHospitalAdminPermissions?: Maybe<Array<Maybe<HospitalAdminPermission>>>;
  /** 클라이언트 권한 식별자 */
  clientIdentifier?: Maybe<HospitalAdminPermissionClientIdentifierEnum>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제 시각 */
  deletedAt: Scalars['DateTime'];
  /** 직무-권한 매핑 테이블 */
  hospitalAdminRoleHospitalAdminPermissions?: Maybe<
    Array<Maybe<HospitalAdminRoleHospitalAdminPermission>>
  >;
  /** pk */
  id: Scalars['ID'];
  /** 권한 활성화 여부 */
  isEnabled: Scalars['Boolean'];
  /** 개인정보 관련 권한 여부 */
  isPrivacyRelated: Scalars['Boolean'];
  /** 권한 이름 */
  name: Scalars['String'];
  /** 상위 권한 */
  parentHospitalAdminPermission?: Maybe<HospitalAdminPermission>;
  /** 상위 권한 ID */
  parentHospitalAdminPermissionId?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum HospitalAdminPermissionClientIdentifierEnum {
  Other = 'other',
  PartnersAppointmentConfigurationItemPermission = 'partnersAppointmentConfigurationItemPermission',
  PartnersAppointmentConfigurationOperationPermission = 'partnersAppointmentConfigurationOperationPermission',
  PartnersAppointmentConfigurationPermission = 'partnersAppointmentConfigurationPermission',
  PartnersAppointmentConfigurationSchedulePermission = 'partnersAppointmentConfigurationSchedulePermission',
  PartnersAppointmentListPermission = 'partnersAppointmentListPermission',
  PartnersAppointmentPermission = 'partnersAppointmentPermission',
  PartnersClientMessagePermission = 'partnersClientMessagePermission',
  PartnersClientPatientInformationPermission = 'partnersClientPatientInformationPermission',
  PartnersClientPatientPermission = 'partnersClientPatientPermission',
  PartnersClientPermission = 'partnersClientPermission',
  PartnersClientReviewPermission = 'partnersClientReviewPermission',
  PartnersClinicConfigurationAdvertisementPermission = 'partnersClinicConfigurationAdvertisementPermission',
  PartnersClinicConfigurationContractPermission = 'partnersClinicConfigurationContractPermission',
  PartnersClinicConfigurationManagerPermission = 'partnersClinicConfigurationManagerPermission',
  PartnersClinicConfigurationPermission = 'partnersClinicConfigurationPermission',
  PartnersClinicConfigurationRequesterPermission = 'partnersClinicConfigurationRequesterPermission',
  PartnersClinicConfigurationReservationPermission = 'partnersClinicConfigurationReservationPermission',
  PartnersClinicPermission = 'partnersClinicPermission',
  PartnersHospitalInformationDefaultPermission = 'partnersHospitalInformationDefaultPermission',
  PartnersHospitalInformationDoctorPermission = 'partnersHospitalInformationDoctorPermission',
  PartnersHospitalInformationIntroductionPermission = 'partnersHospitalInformationIntroductionPermission',
  PartnersHospitalInformationOperationPermission = 'partnersHospitalInformationOperationPermission',
  PartnersHospitalInformationPermission = 'partnersHospitalInformationPermission',
  PartnersHospitalNewsNoticePermission = 'partnersHospitalNewsNoticePermission',
  PartnersHospitalNewsPermission = 'partnersHospitalNewsPermission',
  PartnersHospitalNewsTimetablePermission = 'partnersHospitalNewsTimetablePermission',
  PartnersHospitalPermission = 'partnersHospitalPermission',
  PartnersReceiptConfigurationConsentPermission = 'partnersReceiptConfigurationConsentPermission',
  PartnersReceiptConfigurationDevicePermission = 'partnersReceiptConfigurationDevicePermission',
  PartnersReceiptConfigurationItemPermission = 'partnersReceiptConfigurationItemPermission',
  PartnersReceiptConfigurationMobilePermission = 'partnersReceiptConfigurationMobilePermission',
  PartnersReceiptConfigurationPermission = 'partnersReceiptConfigurationPermission',
  PartnersReceiptConfigurationTabletPermission = 'partnersReceiptConfigurationTabletPermission',
  PartnersReceiptPermission = 'partnersReceiptPermission',
  PartnersReceiptQuestionnaireHistoryPermission = 'partnersReceiptQuestionnaireHistoryPermission',
  PartnersReceiptQuestionnaireManagementPermission = 'partnersReceiptQuestionnaireManagementPermission',
  PartnersReceiptQuestionnairePermission = 'partnersReceiptQuestionnairePermission',
  PartnersUntactOfficePermission = 'partnersUntactOfficePermission',
  PartnersUntactPermission = 'partnersUntactPermission'
}

export type HospitalAdminRole = {
  __typename?: 'HospitalAdminRole';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제 시각 */
  deletedAt: Scalars['DateTime'];
  /** 병원 */
  hospital: Hospital;
  /** 인가 리스트 */
  hospitalAdminAuthorities?: Maybe<Array<Maybe<HospitalAdminAuthority>>>;
  /** 직무-권한 매핑 테이블 */
  hospitalAdminRoleHospitalAdminPermissions?: Maybe<
    Array<Maybe<HospitalAdminRoleHospitalAdminPermission>>
  >;
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 보호 여부 */
  isProtected: Scalars['Boolean'];
  /** 직무 이름 */
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type HospitalAdminRoleHospitalAdminPermission = {
  __typename?: 'HospitalAdminRoleHospitalAdminPermission';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 권한 */
  hospitalAdminPermission: HospitalAdminPermission;
  /** 권한 ID */
  hospitalAdminPermissionId: Scalars['ID'];
  /** 직무 */
  hospitalAdminRole: HospitalAdminRole;
  /** 직무 ID */
  hospitalAdminRoleId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
};

export type HospitalAdminUser = {
  __typename?: 'HospitalAdminUser';
  /** 활성화 상태 */
  activedStatus: HospitalAdminUserActivedStatus;
  /** 클리닉마켓 대행사 계정여부 */
  clinicMarketAgencyAccount?: Maybe<Scalars['Boolean']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제 시각 */
  deletedAt: Scalars['DateTime'];
  draftHospital?: Maybe<DraftHospital>;
  /** 이메일 */
  email: Scalars['String'];
  /** 연동 병원 존재 여부 */
  existedLinkingHospital?: Maybe<Scalars['Boolean']>;
  hospitalAdminUserTermsOfServices?: Maybe<Array<Maybe<HospitalAdminUserTermsOfService>>>;
  hospitalHospitalAdminUsers: Array<HospitalHospitalAdminUser>;
  /** pk */
  id: Scalars['ID'];
  isPasswordChangeNeeded?: Maybe<Scalars['Boolean']>;
  /** 미인증 상태 정보 수정 가능 여부 */
  isUnauthenticatedModificationAllowed: Scalars['Boolean'];
  /** 마지막 비밀번호 변경 요청 시간 */
  lastPasswordChangeRequestedAt?: Maybe<Scalars['DateTime']>;
  /** 담당자명 */
  name: Scalars['String'];
  /** 연락처 */
  phone: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 로그인용 아이디 */
  username: Scalars['String'];
};

export enum HospitalAdminUserActivedStatus {
  Actived = 'actived',
  Inactived = 'inactived',
  Init = 'init'
}

export type HospitalAdminUserTermsOfService = {
  __typename?: 'HospitalAdminUserTermsOfService';
  agreement: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospitalAdminUser: HospitalAdminUser;
  hospitalAdminUserId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  signedAt: Scalars['DateTime'];
  termsOfServiceId: Scalars['ID'];
};

export type HospitalAppointmentStatusObject = {
  __typename?: 'HospitalAppointmentStatusObject';
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /**
   * 예약 가능 상태
   *
   * - available: 예약 가능
   *
   * - noAvailableSlot: 예약 마감(예약 가능 슬롯 없음)
   *
   * - unavailable: 예약 불가능 (예약을 받지 않는 병원)
   */
  status: Scalars['String'];
};

export type HospitalBlogReview = {
  __typename?: 'HospitalBlogReview';
  blogSource: HospitalBlogReviewSourceTypeEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hospital: Hospital;
  /** pk */
  id: Scalars['ID'];
  sort: Scalars['Int'];
  title: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  visibility: Scalars['Boolean'];
};

export enum HospitalBlogReviewSourceTypeEnum {
  Etc = 'etc',
  Kakao = 'kakao',
  Naver = 'naver',
  Tistory = 'tistory'
}

export type HospitalCareDisease = {
  __typename?: 'HospitalCareDisease';
  contentType: HospitalDiseaseContentType;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 정렬 순서 (ASC) */
  sequence: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum HospitalDiseaseContentType {
  Admin = 'admin',
  HiraSpecialDiagnosis = 'hiraSpecialDiagnosis',
  HiraSpecializedDisease = 'hiraSpecializedDisease',
  HiraTop5Disease = 'hiraTop5Disease'
}

export type HospitalEquipment = {
  __typename?: 'HospitalEquipment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hospital: Hospital;
  /** pk */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

/** 병원 검색 결과 */
export type HospitalGeoPointObject = {
  __typename?: 'HospitalGeoPointObject';
  /** 위도 */
  lat: Scalars['Float'];
  /** 경도 */
  lon: Scalars['Float'];
};

export type HospitalHospitalAdminUser = {
  __typename?: 'HospitalHospitalAdminUser';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalAdminAuthorities?: Maybe<Array<HospitalAdminAuthority>>;
  hospitalAdminUser: HospitalAdminUser;
  hospitalAdminUserId: Scalars['ID'];
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
};

export type HospitalLegacyHospital = {
  __typename?: 'HospitalLegacyHospital';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  hospitalLegacyId: Scalars['Int'];
  hospitalLegacyName: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
};

export type HospitalLike = {
  __typename?: 'HospitalLike';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  /** 매칭된 병원 아이디 */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 알림 받기 */
  receiveAlarm: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  /** 매칭된 유저 아이디 */
  userId: Scalars['ID'];
};

export type HospitalMessageTemplate = {
  __typename?: 'HospitalMessageTemplate';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 해당 알림톡을 사용해야하는 이벤트 */
  event: Scalars['String'];
  /** 이벤트 타입 ex) report, receipt, crm, hospital, untact, etc.. */
  eventType: Scalars['String'];
  hospital: Hospital;
  /** 지정되지 않은 병원은 ID 0 에서 설정된 messageTemplate을 바라봄 */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  messageTemplate: MessageTemplate;
  messageTemplateId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type HospitalMission = {
  __typename?: 'HospitalMission';
  applyMissionCompleteReward?: Maybe<ApplyMissionCompleteReward>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 상품 요청 여부 */
  isApply: Scalars['Boolean'];
  mission: Mission;
  missionId: Scalars['ID'];
  /** 진행 정도 */
  progress: Scalars['Float'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type HospitalMissionQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type HospitalNearbyStationObject = {
  __typename?: 'HospitalNearbyStationObject';
  code: Scalars['String'];
  cyberStCode: Scalars['String'];
  /** 병원과 거리 (meter) */
  distance: Scalars['Float'];
  lineColor: Scalars['String'];
  lineName: Scalars['String'];
  lineNumber: Scalars['String'];
  name: Scalars['String'];
};

export type HospitalNotice = {
  __typename?: 'HospitalNotice';
  /** 내용 */
  content?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 이미지 URL */
  images?: Maybe<Array<Scalars['String']>>;
  /** 기본공지 여부 */
  isDefault: Scalars['Boolean'];
  /** 푸시 발송 여부 */
  sendPush: Scalars['Boolean'];
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 공지 타입 */
  type: HospitalNoticeTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type HospitalNoticeQueryInput = {
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  type?: HospitalNoticeTypeEnum;
};

export enum HospitalNoticeTypeEnum {
  Normal = 'normal',
  TimeTable = 'timeTable'
}

export type HospitalObject = {
  __typename?: 'HospitalObject';
  address?: Maybe<Scalars['String']>;
  admins?: Maybe<Array<Maybe<HospitalAdminUser>>>;
  /** (현장접수) 병원이 요청한 병원 별칭 */
  aliasName?: Maybe<Scalars['String']>;
  appointmentHospitalConfiguration?: Maybe<AppointmentHospitalConfiguration>;
  appointmentHospitalSchedules?: Maybe<Array<Maybe<AppointmentHospitalSchedule>>>;
  appointmentTreatments?: Maybe<Array<Maybe<AppointmentTreatment>>>;
  /**
   * 예약 유형
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  appointmentType: AppointmentTypeEnum;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  aroundStationInformation?: Maybe<Scalars['String']>;
  /** 평균대기시간(분) */
  avgWaitingTime?: Maybe<Scalars['Int']>;
  bankAccount?: Maybe<UntactHospitalBankAccount>;
  bridge?: Maybe<Array<Maybe<ReceiptBridge>>>;
  /** 클리닉 북마크 */
  clinicBookmarks?: Maybe<Array<Maybe<ClinicBookmark>>>;
  /** 클리닉이벤트 시술 여부 */
  clinicEventAvailable?: Maybe<Scalars['Boolean']>;
  /** 연관 이벤트 리스트 */
  clinicEvents?: Maybe<Array<Maybe<ClinicEvent>>>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  crmHistories?: Maybe<Array<CrmSendHistory>>;
  crmSendHistories?: Maybe<Array<Maybe<CrmSendHistory>>>;
  crmSenderProfiles?: Maybe<Array<Maybe<CrmSenderProfile>>>;
  /**  큐레이션 병원 목록 조회 */
  curationHospitals: Array<Maybe<SearchV2HomeHospitalObject>>;
  /** 병원 기본이미지 */
  defaultImage: Scalars['String'];
  digitalSignage?: Maybe<Array<Maybe<DigitalSignage>>>;
  doctors?: Maybe<Array<Maybe<Doctor>>>;
  emergencyAvailability: Scalars['Boolean'];
  /**
   * 예약가능 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  enableAppointment: Scalars['Boolean'];
  enableReceiptWaitingCounter: Scalars['Boolean'];
  /** 비대면 진료 여부 */
  enableUntactTreatment?: Maybe<Scalars['Boolean']>;
  establishmentDivision: EstablishmentDivisionEnum;
  hiraCode?: Maybe<Scalars['String']>;
  /** 건강보험심사평가원(심평원)에 등록된 병원명 */
  hiraHospitalName?: Maybe<Scalars['String']>;
  homepageUrl?: Maybe<Scalars['String']>;
  /**  병원 주소 정보  */
  hospitalAddress?: Maybe<HospitalAddress>;
  hospitalBlogReviews?: Maybe<Array<Maybe<HospitalBlogReview>>>;
  hospitalCareDiseases?: Maybe<Array<Maybe<HospitalCareDisease>>>;
  /** 병원의 진료과목 */
  hospitalDepartments?: Maybe<Array<Maybe<DepartmentHospital>>>;
  /** 병원 구분 (심평원) */
  hospitalDivision?: Maybe<Scalars['String']>;
  hospitalEquipments?: Maybe<Array<Maybe<HospitalEquipment>>>;
  hospitalHospitalAdminUsers?: Maybe<Array<Maybe<HospitalHospitalAdminUser>>>;
  hospitalLikes?: Maybe<Array<Maybe<HospitalLike>>>;
  /** @deprecated operationTimes 로 사용해야함 */
  hospitalOperationTimes?: Maybe<Array<Maybe<HospitalOperationTime>>>;
  hospitalPictures?: Maybe<Array<Scalars['String']>>;
  hospitalSearchStatistics?: Maybe<HospitalSearchStatistics>;
  hospitalTags?: Maybe<Array<Maybe<HospitalTag>>>;
  hospitalThemes?: Maybe<Array<Maybe<HospitalTheme>>>;
  /** pk */
  id: Scalars['ID'];
  introductionContent?: Maybe<Scalars['String']>;
  introductionSubTitle?: Maybe<Scalars['String']>;
  introductionTitle?: Maybe<Scalars['String']>;
  /**
   * 인기예약 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  isFamousAppointment: Scalars['Boolean'];
  isMobileReceiptAvailable: Scalars['Boolean'];
  /** 리뷰 소식 알림(리뷰 구분 없이 전체) */
  isReviewNotification?: Maybe<Scalars['Boolean']>;
  /** 라벨(병원명 상위에 위치) */
  labels?: Maybe<Array<Maybe<LabelObject>>>;
  latitude?: Maybe<Scalars['Float']>;
  legacyHospital?: Maybe<HospitalLegacyHospital>;
  likedUser?: Maybe<Array<Maybe<UserObject>>>;
  /** 좋아요한 유저 수 (목록 쿼리에서 사용 금지) */
  likedUserCount: Scalars['Int'];
  lmsTemplates?: Maybe<Array<Maybe<LmsTemplate>>>;
  longitude?: Maybe<Scalars['Float']>;
  /**  할당된 태그 리스트  */
  managedTags?: Maybe<Array<Maybe<ManagedTag>>>;
  /** 진료실 중 최대 접수 대기자 수(불가능병원: -1) */
  maxReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  medicalQuestionnaireRequestHistories?: Maybe<
    Array<Maybe<MedicalQuestionnaireRequestHistory>>
  >;
  medicalQuestionnaireSubmittedHistories?: Maybe<
    Array<Maybe<MedicalQuestionnaireSubmittedHistory>>
  >;
  medicalQuestionnaires?: Maybe<Array<Maybe<MedicalQuestionnaire>>>;
  /** 연관 진료과 리스트 */
  medicalServices?: Maybe<Array<Maybe<MedicalService>>>;
  messageTemplates?: Maybe<Array<Maybe<HospitalMessageTemplate>>>;
  /** 진료실 중 최소 접수 대기자 수 */
  minReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 클라이언트에 노출되는 병원명 */
  name: Scalars['String'];
  /** 가까운 지하철역 */
  nearbySubwayStations?: Maybe<Array<Maybe<HospitalNearbyStationObject>>>;
  nhisCode?: Maybe<Scalars['String']>;
  nmcCode?: Maybe<Scalars['String']>;
  noWaitingTime?: Maybe<Scalars['String']>;
  /** 비대면 진료 가능 여부 */
  onUntactTreatment?: Maybe<Scalars['Boolean']>;
  openClosedStatus?: Maybe<HospitalOpenClosedStatusEnum>;
  /** 개원일 */
  openedAt?: Maybe<Scalars['DateTime']>;
  /** 개원일 */
  openedDate?: Maybe<Scalars['String']>;
  /** 운영 상태 (개폐업여부) */
  operatingState: HospitalOperatingStateEnum;
  operationTimeDescription?: Maybe<Scalars['String']>;
  operationTimes?: Maybe<Array<Maybe<HospitalOperationTime>>>;
  parkingAvailability: Scalars['Boolean'];
  parkingDescription?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receiptHospitalConfiguration?: Maybe<ReceiptHospitalConfiguration>;
  receiptServiceAlliance?: Maybe<ReceiptServiceAlliance>;
  receiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 접수 대기 타입(시간/사람수) */
  receiptWaitingType?: Maybe<ReceiptWaitingTypeEnum>;
  /** 대표 진료 과목 */
  representativeDepartments?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewSummary: ReviewSummary;
  reviewVisibility: Scalars['Boolean'];
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  safetyPhone?: Maybe<Scalars['String']>;
  /** 클리닉마켓 계약 여부 */
  signClinicMarketContract?: Maybe<Scalars['Boolean']>;
  simplyIntroduction?: Maybe<Scalars['String']>;
  themes?: Maybe<Array<Maybe<HospitalThemeObject>>>;
  treatmentItems?: Maybe<Array<Maybe<ReceiptTreatmentItem>>>;
  /**
   * 당일예약불가 여부
   * @deprecated appointmentHospitalConfiguration을 사용 (SP30)
   */
  unableSameDayAppointment: Scalars['Boolean'];
  untactHospitalConfiguration?: Maybe<UntactHospitalConfiguration>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 병원 어드민 계정 사용 유무 */
  usingHospitalAdminUser?: Maybe<Scalars['Boolean']>;
  videoAdvertisementPlaylist?: Maybe<Array<Maybe<VideoAdvertisementPlaylist>>>;
  videoAdvertisementPlaylistHistories?: Maybe<
    Array<Maybe<VideoAdvertisementPlaylistHistory>>
  >;
  visibility: Scalars['Boolean'];
  webcams: Array<Webcam>;
};

export type HospitalObjectCurationHospitalsArgs = {
  input: CurationHospitalsQueryInput;
};

export enum HospitalOpenClosedStatusEnum {
  /** 진료종료 */
  Closed = 'closed',
  /** 휴진 */
  DayOff = 'dayOff',
  /** 진료중 */
  Open = 'open',
  /** 진료준비 */
  Ready = 'ready',
  /** 모름 */
  Unknown = 'unknown'
}

export enum HospitalOperatingStateEnum {
  /** 폐업 */
  Closed = 'closed',
  /** 정상 */
  Operated = 'operated',
  /** 휴업 */
  Pended = 'pended',
  /** 모릅 */
  Unknown = 'unknown'
}

export type HospitalOperationTime = {
  __typename?: 'HospitalOperationTime';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 시작시간(kst) hh:mm:ss */
  from: Scalars['String'];
  /** 공휴일 이름 */
  holidayName?: Maybe<Scalars['String']>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  operationOff: Scalars['Boolean'];
  operationType: HospitalOperationTimeTypeEnum;
  /** 해당하는 날짜 */
  targetDate?: Maybe<Scalars['DateTime']>;
  /** 종료시간(kst) hh:mm:ss */
  to: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum HospitalOperationTimeTypeEnum {
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  WeekdayLunch = 'weekdayLunch',
  WeekendLunch = 'weekendLunch'
}

export type HospitalPictureUploadUrlResult = {
  __typename?: 'HospitalPictureUploadUrlResult';
  /** 다운로드 url */
  downloadUrl: Scalars['String'];
  /** 업로드 url, Content-Type에 파일 타입을 넣어야 주어야 합니다. */
  uploadUrl: Scalars['String'];
};

export enum HospitalReceiptRequestInstallStatusEnum {
  Call = 'call',
  Self = 'self'
}

export enum HospitalReceiptStatusEnum {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  W1 = 'W1',
  W2 = 'W2'
}

/** 병원 대표리뷰 */
export type HospitalRepresentativeReviewObject = {
  __typename?: 'HospitalRepresentativeReviewObject';
  /** 리뷰 8점 이상 여부 */
  is_recommended?: Maybe<Scalars['Boolean']>;
  /** 리뷰 컨텐츠 */
  text?: Maybe<Scalars['String']>;
};

/** 병원 검색 필터 인풋 */
export type HospitalSearchFilterInput = {
  /** 진료 요일 - 병원 */
  availableDays?: InputMaybe<Array<InputMaybe<HospitalWorkingDays>>>;
  /** 어린이 치과 여부 - 테마 */
  childDentalClinic?: InputMaybe<Scalars['Boolean']>;
  /** 어린이 한의원 여부 - 테마 */
  childOrientalClinic?: InputMaybe<Scalars['Boolean']>;
  /** 클리닉이벤트 여부 */
  clinicEventAvailable?: InputMaybe<Scalars['Boolean']>;
  /** 클리닉마켓 등록 병원 여부 - 직접 계산 */
  clinicMarketAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 현재 운영중 여부 - 직접 계산 */
  currentAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 진료 과목 - 진료과목 */
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 국민안심병원 여부 - 테마 */
  designatedPublicHospital?: InputMaybe<Scalars['Boolean']>;
  /** 증상 및 질환 */
  diseases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 거리제한 */
  distance?: InputMaybe<Scalars['Float']>;
  /** 응급진료 여부 - 병원 */
  emergencyCareAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 예약가능 여부 */
  enableAppointment?: InputMaybe<Scalars['Boolean']>;
  /** 비대면진료 여부 */
  enableUntactService?: InputMaybe<Scalars['Boolean']>;
  /** 여의사 여부 - 의료진 */
  femaleDoctorAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 굿닥스초이스병원 - 테마 */
  goodocChoice?: InputMaybe<Scalars['Boolean']>;
  /** 코로나 전화상담(처방) - 테마 */
  hasCovid19CallService?: InputMaybe<Scalars['Boolean']>;
  /** 코로나 PCR검사 - 테마 */
  hasCovid19PCR?: InputMaybe<Scalars['Boolean']>;
  /** 코로나 신속항원검사 - 테마 */
  hasCovid19RAT?: InputMaybe<Scalars['Boolean']>;
  /** 독감예방접종 - 테마 */
  hasFluShot?: InputMaybe<Scalars['Boolean']>;
  /** 보건증 여부 */
  hasHealthCertificate?: InputMaybe<Scalars['Boolean']>;
  /** 휴일(명절) 중 하루라도 운영 여부 */
  hasHolidayOpenDays?: InputMaybe<Scalars['Boolean']>;
  /** 공단검진 여부 */
  hasIndustrialExamination?: InputMaybe<Scalars['Boolean']>;
  /** 영유아검진 여부 */
  hasInfantExamination?: InputMaybe<Scalars['Boolean']>;
  /** 접수 대기자 수 확인 가능 여부 */
  hasReceiptWaitingNumber?: InputMaybe<Scalars['Boolean']>;
  /** 산재병원 여부 - 테마 */
  industrialAccidentCareAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 바로예약 여부 */
  isDirectOfAppointmentType?: InputMaybe<Scalars['Boolean']>;
  /** 인기예약 여부 */
  isFamousAppointment?: InputMaybe<Scalars['Boolean']>;
  /** 건강검진 여부 - 테마 */
  medicalCheckupAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 시술상담 가능 여부 */
  medicalServiceAvailable?: InputMaybe<Scalars['Boolean']>;
  /** 모바일 접수 on/off */
  mobileReceiptAvailable?: InputMaybe<Scalars['Boolean']>;
  /** 모바일 현재 접수중 여부 */
  mobileReceiptOpen?: InputMaybe<Scalars['Boolean']>;
  /** 야간진료 여부 - 직접 계산 */
  nightCareAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 주차가능 여부 - 병원 */
  parkingAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 태블릿 접수 on/off */
  receptionAvailable?: InputMaybe<Scalars['Boolean']>;
  /** 비대면 진료 병원 여부 - TODO */
  remoteCateAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 선별진료소 여부 - 테마 */
  screenedHospital?: InputMaybe<Scalars['Boolean']>;
  /** 전문의 여부 - 의료진 */
  specialistAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 특정 요일, 시간에 진료 여부 */
  specificDateAvailability?: InputMaybe<HospitalSpecificDateAvailabilityFilterInput>;
  /** 주말진료 여부 */
  weekendAvailability?: InputMaybe<Scalars['Boolean']>;
};

/** 병원 검색 결과 */
export type HospitalSearchObject = {
  __typename?: 'HospitalSearchObject';
  /** 병원 주소 */
  address: Scalars['String'];
  /** 진료항목 */
  appointment_treatments?: Maybe<Array<Maybe<AppointmentTreatmentObject>>>;
  /** 병원 뱃지 */
  badges: Array<Scalars['String']>;
  /** 진료부서 */
  departments: Array<Scalars['String']>;
  /** 거리 */
  distance: Scalars['Int'];
  /** 병원 의료진 (노출X) */
  doctors?: Maybe<Array<Scalars['String']>>;
  /** 예약가능 여부 */
  enable_appointment: Scalars['Boolean'];
  /** 관련 클리닉마켓 수 */
  event_count?: Maybe<Scalars['Int']>;
  /** 독감예방접종 최고가격 */
  flu_shot_max_price?: Maybe<Scalars['String']>;
  /** 독감예방접종 최저가격 */
  flu_shot_min_price?: Maybe<Scalars['String']>;
  /** 코로나 전화상담 여부 */
  has_covid19_call_service: Scalars['Boolean'];
  /** 코로나 PCR검사 여부 */
  has_covid19_pcr: Scalars['Boolean'];
  /** 코로나 신속항원검사 여부 */
  has_covid19_rat: Scalars['Boolean'];
  /** 휴일(명절) 중 하루라도 운영 여부 */
  has_holiday_open_days?: Maybe<Scalars['Boolean']>;
  /** 접수 대기자 확인 가능 여부 */
  has_receipt_waiting_number: Scalars['Boolean'];
  /** 병원 아이디 */
  id: Scalars['ID'];
  /** 코로나 급여 rat 가격 */
  insured_rat_price?: Maybe<Scalars['String']>;
  /** 바로예약 여부 */
  is_direct_of_appointment_type: Scalars['Boolean'];
  /** 인기예약 여부 */
  is_famous_appointment: Scalars['Boolean'];
  /** 병원 라벨 */
  labels?: Maybe<Array<Maybe<LabelObject>>>;
  location: HospitalGeoPointObject;
  /** 모바일 접수 on/off */
  mobile_receipt_available?: Maybe<Scalars['Boolean']>;
  /** 모바일 현재 접수중 여부 */
  mobile_receipt_open?: Maybe<Scalars['Boolean']>;
  /** 병원 이름 */
  name: Scalars['String'];
  /** 진료중 여부 */
  open_closed_status?: Maybe<Scalars['String']>;
  /** pcr 가격 */
  pcr_price?: Maybe<Scalars['String']>;
  /** 인기 점수 */
  popularity_score?: Maybe<Scalars['Int']>;
  /** 접수 대기자 수 */
  receipt_waiting_number: Scalars['Int'];
  /** 병원 관련 질병 (노출X) */
  related_diseases?: Maybe<Array<Scalars['String']>>;
  /** 병원 대표 리뷰 */
  representative_review?: Maybe<HospitalRepresentativeReviewObject>;
  /** 병원 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 병원 리뷰 평균점수 */
  review_rating?: Maybe<Scalars['Float']>;
  /** 병원 태그 (노출X) */
  tags?: Maybe<Array<Scalars['String']>>;
  /** 병원 테마 */
  themes: Array<Scalars['String']>;
  time_table: Array<HospitalTimeTableObject>;
  /** 코로나 비급여 rat 가격 */
  uninsured_rat_price?: Maybe<Scalars['String']>;
  /** 비대면진료 서비스 가입 여부 */
  untact_service: Scalars['Boolean'];
  /** 비대면진료 가능 여부 */
  untact_service_on_treatment: Scalars['Boolean'];
  /** 노출 여부 */
  visibility: Scalars['Boolean'];
};

export type HospitalSearchObjectEdge = {
  __typename?: 'HospitalSearchObjectEdge';
  cursor: Scalars['String'];
  node: HospitalSearchObject;
};

/** 병원 검색 정렬 인풋 */
export type HospitalSearchSortInput = {
  /** 검색 정확도순 정렬 (DESC) */
  accuracy?: InputMaybe<Scalars['String']>;
  /** 거리순 정렬 (ASC) */
  distance?: InputMaybe<Scalars['String']>;
  /** 검색 인기도순 정렬 (DESC) */
  popularity?: InputMaybe<Scalars['String']>;
};

export type HospitalSearchStatistics = {
  __typename?: 'HospitalSearchStatistics';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 조회수 */
  hitCount?: Maybe<Scalars['Int']>;
  hospital: Hospital;
  /** 병원 아이디 */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** uuid별 순 방문자수 */
  uniqueVisitor?: Maybe<Scalars['Int']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

/** 병원 특정 요일/시간의 진료 여부 객체 */
export type HospitalSpecificDateAvailabilityFilterInput = {
  /** 타겟 요일 */
  dayOfTheWeek: HospitalWorkingDays;
  /** 타겟 시간 */
  time: Scalars['String'];
};

export type HospitalTag = {
  __typename?: 'HospitalTag';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type HospitalTheme = {
  __typename?: 'HospitalTheme';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 테마 관련 메모 */
  memo?: Maybe<Scalars['String']>;
  theme: Theme;
  themeId: Scalars['ID'];
};

/** 병원테마 */
export type HospitalThemeObject = {
  __typename?: 'HospitalThemeObject';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 테마 관련 메모 */
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  theme: Theme;
  themeId: Scalars['ID'];
  visibility: Scalars['Boolean'];
};

/** 병원 운영 시간표 */
export type HospitalTimeTableObject = {
  __typename?: 'HospitalTimeTableObject';
  /** 종료시간 */
  closed_at: Scalars['String'];
  /** 요일 */
  day_type: Scalars['String'];
  /** 공휴일 */
  holiday_name: Scalars['String'];
  /** 시작시간 */
  opened_at: Scalars['String'];
  /** 날짜 */
  target_date: Scalars['String'];
  /** 미운영 여부 */
  time_off: Scalars['Boolean'];
};

export enum HospitalWorkingDays {
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type HospitalsForTagManagingQueryInput = {
  /** 태그이름 */
  managedTagName: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type IdentityVerificationObject = {
  __typename?: 'IdentityVerificationObject';
  /** pk */
  id: Scalars['ID'];
  /** 본인 인증 시각 | null인 경우 미인증 */
  identifiedAt?: Maybe<Scalars['DateTime']>;
};

export enum InsuredTypeEnum {
  Insured = 'insured',
  UnInsured = 'unInsured'
}

export type KakaopayApproveInput = {
  /** kakaopay pgtoken */
  pg_token: Scalars['String'];
  /** kakaopay tid */
  tid: Scalars['String'];
};

/** 카카오페이 결제준비 응답(https://developers.kakao.com/docs/latest/ko/kakaopay/common) */
export type KakaopayReadyResponseObject = {
  __typename?: 'KakaopayReadyResponseObject';
  android_app_scheme: Scalars['String'];
  created_at: Scalars['String'];
  ios_app_scheme: Scalars['String'];
  next_redirect_app_url: Scalars['String'];
  next_redirect_mobile_url: Scalars['String'];
  next_redirect_pc_url: Scalars['String'];
  /** 결제 고유 번호 */
  tid: Scalars['String'];
};

export enum KakaopayRequestTypeEnum {
  Local = 'local',
  Mobile = 'mobile',
  TvUser = 'tvUser',
  TvUserDevelop = 'tvUserDevelop',
  TvUserRc = 'tvUserRc',
  TvUserStaging = 'tvUserStaging'
}

export type KeyValueObject = {
  __typename?: 'KeyValueObject';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** 키워드 주소 검색 내용 */
export type KeywordAddressItemObject = {
  __typename?: 'KeywordAddressItemObject';
  /** 주소 */
  address: Scalars['String'];
  /** 위도 */
  lat: Scalars['Float'];
  /** 경도 */
  lng: Scalars['Float'];
  /** 위치명 */
  name: Scalars['String'];
  /** 도로명 주소 */
  roadAddress: Scalars['String'];
};

/** 키워드 통한 사용자 위치 검색 */
export type KeywordAddressObject = {
  __typename?: 'KeywordAddressObject';
  /** 검색 내역 */
  data?: Maybe<Array<Maybe<KeywordAddressItemObject>>>;
  /** 현재 페이지 ( 1 ~ ) */
  page: Scalars['Int'];
  /** 페이지 사이즈 */
  size: Scalars['Int'];
  /** 전체 결과수 */
  total: Scalars['Int'];
};

/** 병원 약국 라벨 */
export type LabelObject = {
  __typename?: 'LabelObject';
  /** 라벨색깔타입 */
  color: Scalars['String'];
  /** 라벨명 */
  name: Scalars['String'];
};

export enum LastUseServiceType {
  Appointment = 'appointment',
  Receipt = 'receipt',
  Unknown = 'unknown',
  Untact = 'untact'
}

export type LegalRepresentative = {
  __typename?: 'LegalRepresentative';
  address?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  receiptPatient: ReceiptPatient;
  receiptPatientId: Scalars['ID'];
  relationship: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type LmsTemplate = {
  __typename?: 'LmsTemplate';
  /** 탬플릿 내용 */
  contents: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

/** 위경도 주소 검색 내용 */
export type LocationAddressItemObject = {
  __typename?: 'LocationAddressItemObject';
  /** 주소 */
  address: Scalars['String'];
  /** 위도 */
  lat: Scalars['Float'];
  /** 경도 */
  lng: Scalars['Float'];
  /** 도로명 주소 */
  roadAddress: Scalars['String'];
};

/** 위경도 통한 사용자 위치 검색 */
export type LocationAddressObject = {
  __typename?: 'LocationAddressObject';
  /** 검색 내역 */
  data?: Maybe<Array<Maybe<LocationAddressItemObject>>>;
  /** 전체 결과수 */
  total: Scalars['Int'];
};

export type ManagedTag = {
  __typename?: 'ManagedTag';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 태그 설명 */
  description?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 자동으로 할당되는 태그 여부 (여의사, 야간진료 등..) */
  isAutoAssignable: Scalars['Boolean'];
  /** 태그명 */
  name: Scalars['String'];
  /** 태그 종류 */
  searchIndexType: ManagedTagSearchIndexTypeEnum;
  /** 태그 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum ManagedTagSearchIndexTypeEnum {
  Curation = 'curation',
  Filter = 'filter',
  Keyword = 'keyword'
}

export type MedicalCategory = {
  __typename?: 'MedicalCategory';
  /** chatbot 사용 여부 */
  chatBotEnabled?: Maybe<Scalars['Boolean']>;
  /** 자식 카테고리 */
  children?: Maybe<Array<Maybe<MedicalCategory>>>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 카테고리 뎁스 */
  depth: Scalars['Int'];
  /** 카테고리 활성화 여부 */
  enabled: Scalars['Boolean'];
  /** 카테고리 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 카테고리 키워드 */
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 카테고리 커리에이션 매핑 */
  medicalCategoryCurationMappings?: Maybe<Array<Maybe<MedicalCategoryCurationMapping>>>;
  /** 카테고리 속성 */
  medicalCategoryProperties?: Maybe<Array<Maybe<MedicalCategoryProperty>>>;
  /** 시술명 */
  medicalServices?: Maybe<Array<Maybe<MedicalService>>>;
  /** 카타고리 이름 */
  name: Scalars['String'];
  /** 부모 카테고리 */
  parent?: Maybe<MedicalCategory>;
  /** 부모 카테고리 ID */
  parentId?: Maybe<Scalars['Int']>;
  /** 2depth 필터 사용 여부 */
  secondDepthFilter: Scalars['Boolean'];
  /** 카테고리 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalCategoryCurationMapping = {
  __typename?: 'MedicalCategoryCurationMapping';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 삭제 여부 */
  isDeleted: Scalars['Boolean'];
  /** 카테고리 */
  medicalCategory: MedicalCategory;
  /** 카테고리 ID */
  medicalCategoryId: Scalars['Int'];
  /** 큐레이션 */
  medicalCuration: MedicalCuration;
  /** 큐레이션 ID */
  medicalCurationId: Scalars['Int'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalCategoryProperty = {
  __typename?: 'MedicalCategoryProperty';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 사용 여부 */
  enabled: Scalars['Boolean'];
  /** pk */
  id: Scalars['ID'];
  /** 내부사용 이름 */
  internalName?: Maybe<Scalars['String']>;
  /** 카테고리 */
  medicalCategory: MedicalCategory;
  medicalCategoryId: Scalars['Int'];
  /** 카테고리 속성 값 매핑 */
  medicalCategoryPropertyValueMappings?: Maybe<
    Array<Maybe<MedicalCategoryPropertyValueMapping>>
  >;
  /** 카테고리 속성 값 */
  medicalCategoryPropertyValues?: Maybe<Array<Maybe<MedicalCategoryPropertyValue>>>;
  /** 이름 */
  name: Scalars['String'];
  /** 속성타입 */
  propertyType: Scalars['String'];
  /** 선택타입 */
  selectionType: Scalars['String'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 설명 사용 */
  useDescription: Scalars['Boolean'];
  /** 아이콘 사용 */
  useIcon: Scalars['Boolean'];
};

export type MedicalCategoryPropertyValue = {
  __typename?: 'MedicalCategoryPropertyValue';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 삭제 여부 */
  isDeleted: Scalars['Boolean'];
  /** 카테고리 속성 */
  medicalCategoryProperty: MedicalCategoryProperty;
  medicalCategoryPropertyId: Scalars['Int'];
  /** 카테고리 속성 값 매핑 */
  medicalCategoryPropertyValueMappings?: Maybe<
    Array<Maybe<MedicalCategoryPropertyValueMapping>>
  >;
  /** 이름 */
  name: Scalars['String'];
  /** 원산지 */
  origin?: Maybe<Scalars['String']>;
  /** 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalCategoryPropertyValueMapping = {
  __typename?: 'MedicalCategoryPropertyValueMapping';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 사용 여부 */
  enabled: Scalars['Boolean'];
  /** pk */
  id: Scalars['ID'];
  /** 카테고리 속성 */
  medicalCategoryProperty: MedicalCategoryProperty;
  medicalCategoryPropertyId: Scalars['ID'];
  /** 카테고리 속성 값 */
  medicalCategoryPropertyValue: MedicalCategoryPropertyValue;
  medicalCategoryPropertyValueId: Scalars['ID'];
  /** 의료 서비스 */
  medicalService: MedicalService;
  medicalServiceId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalCuration = {
  __typename?: 'MedicalCuration';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 주제별/진료과목별 */
  curationType: Scalars['String'];
  /** 사용 여부 */
  enabled: Scalars['Boolean'];
  /** 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 카테고리 큐레이션 매핑 */
  medicalCategoryCurationMappings?: Maybe<Array<Maybe<MedicalCategoryCurationMapping>>>;
  /** 이름 */
  name: Scalars['String'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum MedicalFacilityTypeEnum {
  Hospital = 'hospital',
  Pharmacy = 'pharmacy'
}

export type MedicalQuestionnaire = {
  __typename?: 'MedicalQuestionnaire';
  /** 보기 */
  cases?: Maybe<Array<Scalars['String']>>;
  /** 영유아검진의 경우 몇차 검진인지 */
  childScreeningRound?: Maybe<Scalars['Int']>;
  /** 자식 문진 */
  children?: Maybe<Array<Maybe<MedicalQuestionnaire>>>;
  /** 구성요소 타입 */
  contentsType: MedicalQuestionnaireContentsTypes;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 문진 depth */
  depth: Scalars['Int'];
  /** 입력받을 정보 dynamic Input Field */
  dynamicInputFields?: Maybe<Array<Scalars['String']>>;
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 질문 입력 타입 */
  inputType?: Maybe<MedicalQuestionnaireInputTypes>;
  medicalQuestionnaireRequestHistories?: Maybe<
    Array<Maybe<MedicalQuestionnaireRequestHistory>>
  >;
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType: MedicalQuestionnaireTypes;
  /** 문진 이름 */
  name: Scalars['String'];
  /** 상위 문진 (self ref) */
  parent?: Maybe<MedicalQuestionnaire>;
  /** 상위 문진 ID (self ref) */
  parentId?: Maybe<Scalars['Int']>;
  receiptTreatmentItemMedicalQuestionnaires?: Maybe<
    Array<Maybe<ReceiptTreatmentItemMedicalQuestionnaire>>
  >;
  /** 같은 depth에서의 순서 */
  sortIndex?: Maybe<Scalars['Int']>;
  /** 입력받을 정보 필수 입력 Field */
  staticInputFields?: Maybe<Array<Scalars['String']>>;
  /** 문진 노출 대상 나이대 */
  targetAgeTypes?: Maybe<Array<TargetAgeTypes>>;
  /** 문진 노출 대상 성별 */
  targetGenderTypes?: Maybe<Array<TargetGenderTypes>>;
  /** 문진 노출 대상 환자 유형 */
  targetReceiptPatientTypes?: Maybe<Array<TargetReceiptPatientTypes>>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalQuestionnaireRequestHistory = {
  __typename?: 'MedicalQuestionnaireRequestHistory';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  gender?: Maybe<GenderEnum>;
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  isSubmitted: Scalars['Boolean'];
  medicalQuestionnaire: MedicalQuestionnaire;
  /** 최상위 medicalQuestionnaireId */
  medicalQuestionnaireId: Scalars['ID'];
  /** 문진 이름 */
  medicalQuestionnaireName?: Maybe<Scalars['String']>;
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType: MedicalQuestionnaireTypes;
  /** 문진 작성대상자 (주로 환자) 이름 */
  name?: Maybe<Scalars['String']>;
  /** 문진 작성대상자 (주로 환자) 번호 */
  phone: Scalars['String'];
  /** 문진 요청 경로 */
  source?: Maybe<MedicalQuestionnaireRequestHistorySourceEnum>;
  /** 문진 요청 경로 object id - ex) receipt : receiptId */
  sourceId?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MedicalQuestionnaireRequestHistoryObject = {
  __typename?: 'MedicalQuestionnaireRequestHistoryObject';
  /** 주소 */
  address?: Maybe<Scalars['String']>;
  /** 내원 경로 */
  funnel?: Maybe<Scalars['String']>;
  hospital?: Maybe<Hospital>;
  hospitalId?: Maybe<Scalars['ID']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  /** 법정대리인 주소 */
  legalRepresentativeAddress?: Maybe<Scalars['String']>;
  /** 법정대리인 이름 */
  legalRepresentativeName?: Maybe<Scalars['String']>;
  /** 법정대리인 연락처 */
  legalRepresentativePhone?: Maybe<Scalars['String']>;
  /** 법정대리인과의 관계 */
  legalRepresentativeRelationship?: Maybe<Scalars['String']>;
  medicalQuestionnaire?: Maybe<MedicalQuestionnaire>;
  /** 최상위 medicalQuestionnaireId */
  medicalQuestionnaireId?: Maybe<Scalars['ID']>;
  /** 문진 이름 */
  medicalQuestionnaireName?: Maybe<Scalars['String']>;
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType?: Maybe<MedicalQuestionnaireTypes>;
  /** 문진 작성대상자 (주로 환자) 이름 */
  name?: Maybe<Scalars['String']>;
  /** 문진 작성대상자 (주로 환자) 번호 */
  phone?: Maybe<Scalars['String']>;
  /** 문진 요청 경로 */
  source?: Maybe<MedicalQuestionnaireRequestHistorySourceEnum>;
};

export enum MedicalQuestionnaireRequestHistorySourceEnum {
  Direct = 'direct',
  Receipt = 'receipt'
}

export type MedicalQuestionnaireSubmittedHistory = {
  __typename?: 'MedicalQuestionnaireSubmittedHistory';
  /** 생년월일 YYYYMMDD */
  birth: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 성별 unknown/male/female */
  gender?: Maybe<GenderEnum>;
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 문진 이름 */
  medicalQuestionnaireName: Scalars['String'];
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType: MedicalQuestionnaireTypes;
  /** 문진 작성대상자 (주로 환자) 이름 */
  name?: Maybe<Scalars['String']>;
  /** 문진 작성대상자 (주로 환자) 번호 */
  phone: Scalars['String'];
  /** 제출문진 raw data */
  submittedJsonData: Scalars['JSONObject'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 문진 s3 경로 */
  url?: Maybe<Scalars['String']>;
};

export type MedicalQuestionnaireSubmittedHistoryQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 문진 이름 */
  medicalQuestionnaireName?: InputMaybe<Scalars['String']>;
  /** 문진표 유형 (영유아 / 일반) */
  medicalQuestionnaireType?: InputMaybe<MedicalQuestionnaireTypes>;
  /** 문진 환자명 */
  name?: InputMaybe<Scalars['String']>;
  /** 문진 수신번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type MedicalService = {
  __typename?: 'MedicalService';
  /** 진료 이벤트 */
  clinicEvent?: Maybe<ClinicEvent>;
  clinicEventId?: Maybe<Scalars['ID']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 설명 */
  description: Scalars['String'];
  /** 표시 이름 */
  displayName: Scalars['String'];
  /** 사용 여부 */
  enabled: Scalars['Boolean'];
  /** 병원 */
  hospital: Hospital;
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 마켓 가격 여부 */
  marketPrice: Scalars['Boolean'];
  /** 카테고리 */
  medicalCategory?: Maybe<MedicalCategory>;
  /** 카테고리 ID */
  medicalCategoryId: Scalars['ID'];
  /** 카테고리 속성 값 매핑 */
  medicalCategoryPropertyValueMappings?: Maybe<
    Array<Maybe<MedicalCategoryPropertyValueMapping>>
  >;
  /** 진료 서비스 요약 */
  medicalServiceSummary?: Maybe<MedicalServiceSummary>;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']>;
  /** 원가 */
  originalPrice?: Maybe<Scalars['Int']>;
  /** 가격 */
  price?: Maybe<Scalars['Int']>;
  /** 가격소스 */
  priceSource?: Maybe<Scalars['String']>;
  /** 가격종류 */
  priceType?: Maybe<Scalars['String']>;
  /** 서비스 타입 */
  serviceType: Scalars['String'];
  /** 표시 가격 */
  textPrice?: Maybe<Scalars['String']>;
  /** 썸네일 */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** URL */
  url?: Maybe<Scalars['String']>;
};

export type MedicalServiceSummary = {
  __typename?: 'MedicalServiceSummary';
  address?: Maybe<Scalars['String']>;
  /** Best 리뷰s */
  bestReviews?: Maybe<Array<MedicalServiceSummaryReviewObject>>;
  /** 이벤트명 */
  clinicEventName?: Maybe<Scalars['String']>;
  /** ClinicLab의 추천순 정렬을 위한 추천 점수 */
  clinicScore: Scalars['Float'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 병원의 의사 목록 */
  doctors: Array<Scalars['JSONObject']>;
  /** 이벤트랑 연결된 MedicalService 종료 날짜 */
  endAt?: Maybe<Scalars['DateTime']>;
  /** 병원명 */
  hospitalName?: Maybe<Scalars['String']>;
  /** MedicalService 테이블과 1:1 관계로서, AutoIncrement 하지 않는다 */
  id: Scalars['ID'];
  /** 병원 위치 위도 */
  latitude?: Maybe<Scalars['Float']>;
  /** 병원 위치 경도 */
  longitude?: Maybe<Scalars['Float']>;
  /** 대표로 보여줄 의사 학력 */
  mainDoctorEducation?: Maybe<Scalars['JSONObject']>;
  /** 병원이 가진 진료 ID 목록 */
  medicalCategoryNames: Array<Scalars['String']>;
  /** 진료 서비스 */
  medicalService: MedicalService;
  /** MedicalService 테이블과 1:1 관계, id 와 같다 */
  medicalServiceId: Scalars['ID'];
  /** 병원 개원일 */
  openedDate?: Maybe<Scalars['String']>;
  /** 병원의 가격 정보 목록 */
  priceDetails: Array<Scalars['JSONObject']>;
  /** 리뷰 평점 평균 */
  ratingMean: Scalars['Float'];
  /** 리뷰 개수 */
  reviewsCount?: Maybe<Scalars['Int']>;
  /** 이벤트랑 연결된 MedicalService 시작 날짜 */
  startAt?: Maybe<Scalars['DateTime']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum MedicalTypeEnum {
  DentalMedicine = 'dentalMedicine',
  KoreanMedicine = 'koreanMedicine',
  Medicine = 'medicine'
}

/** 복약 알람 */
export type MedicineAlarm = {
  __typename?: 'MedicineAlarm';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 알람 시간 */
  medicineAlarmTimes: Array<MedicineAlarmTime>;
  /** 알람 이름 */
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 유저 ID */
  userId: Scalars['ID'];
};

/** 복약 알람 시간 */
export type MedicineAlarmTime = {
  __typename?: 'MedicineAlarmTime';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 복약 여부 */
  isTaken: Scalars['Boolean'];
  /** 마지막 복약 날짜 (KST) */
  lastTakenDate?: Maybe<Scalars['DateTime']>;
  /** 알람 ID */
  medicineAlarmId: Scalars['ID'];
  /** 알람 시간 (HH:mm) */
  time: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  buttonNames: Array<Scalars['String']>;
  /** 카카오 알림톡 버튼 타입 12종: AL | WL */
  buttonTypes: Array<Scalars['String']>;
  /** 중괄호를 포함한 문자열 replace */
  buttonUrls: Array<Scalars['String']>;
  buttonsCount: Scalars['Int'];
  code: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospitals?: Maybe<Array<Maybe<Hospital>>>;
  /** pk */
  id: Scalars['ID'];
  /** SMS에 버튼링크 포함 유무 */
  includeLinksInSMS: Scalars['Boolean'];
  /** 중괄호를 포함한 문자열 replace */
  message: Scalars['String'];
  name: Scalars['String'];
  /** 알림톡-복합형 타이틀, lms-문자메시지 타이틀 */
  title: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type Mission = {
  __typename?: 'Mission';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  /** 목표 event type */
  eventSource: MissionEventTypeEnum;
  /** 목표달성 수치 */
  goal: Scalars['Float'];
  hospitalMissions?: Maybe<Array<Maybe<HospitalMission>>>;
  /** pk */
  id: Scalars['ID'];
  /** 긴급 차단 여부 */
  isBlocked: Scalars['Boolean'];
  /** 미션 이름 */
  name: Scalars['String'];
  /** 미션 보상 상품 사진 */
  rewardImage: Scalars['String'];
  /** 미션 보상 상품 이름 */
  rewardName: Scalars['String'];
  /** 상품 예상 지급일자 */
  rewardSendAt?: Maybe<Scalars['DateTime']>;
  startedAt: Scalars['DateTime'];
  /** 미션 달성 총 단계 */
  step: Scalars['Float'];
  /** 미션 부제목 ex) 스타벅스 리워드 100% 지급 */
  subName: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum MissionEventTypeEnum {
  Appointment = 'Appointment',
  Receipt = 'Receipt',
  ReviewReply = 'ReviewReply'
}

export type MissionQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export enum MobileCarrierEnum {
  Ktf = 'ktf',
  Ktm = 'ktm',
  Lgm = 'lgm',
  Lgt = 'lgt',
  Skm = 'skm',
  Skt = 'skt'
}

export type MobileReceiptAvailabilityObject = {
  __typename?: 'MobileReceiptAvailabilityObject';
  /** 병원의 모바일 접수 가능 여부 상태 코드 */
  hospitalStatus: Scalars['String'];
  /** 각 접수 항목별 모바일 접수 가능 여부 */
  receiptTreatmentItemStatuses: Array<MobileReceiptTreatmentItemStatuses>;
};

export type MobileReceiptCountOfTodayObject = {
  __typename?: 'MobileReceiptCountOfTodayObject';
  /** 접수(진료)항목 ID */
  receiptTreatmentItemId: Scalars['ID'];
  /** 오후 접수 인원(누적), 기준 : 13시~ */
  receiptsOfAfternoon: Scalars['Int'];
  /** 오전 접수 인원(누적), 기준 : ~13시 */
  receiptsOfMorning: Scalars['Int'];
};

export type MobileReceiptHospitalConfigurationInput = {
  /** 오후 접수 인원 제한 */
  afternoonReceiptLimit?: InputMaybe<Scalars['Int']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 모바일 접수 시작 전 설정(분) */
  minuteBeforeFirstMobileReceipt?: InputMaybe<Scalars['Int']>;
  /** 모바일 접수 종료 전 설정(분) */
  minuteBeforeLastMobileReceipt?: InputMaybe<Scalars['Int']>;
  /** 모바일 접수 on/off */
  mobileReceiptAvailable?: InputMaybe<Scalars['Boolean']>;
  /** 모바일 현재 접수중 여부 */
  mobileReceiptOpen?: InputMaybe<Scalars['Boolean']>;
  /** 오전 접수 인원 제한 */
  morningReceiptLimit?: InputMaybe<Scalars['Int']>;
};

export type MobileReceiptTreatmentItemStatuses = {
  __typename?: 'MobileReceiptTreatmentItemStatuses';
  /** 모바일 접수 가능 여부 */
  available: Scalars['Boolean'];
  /** 모바일 접수 항목 */
  item: ReceiptTreatmentItem;
  /** 모바일 접수 불가 사유 색상 코드 */
  reasonColor?: Maybe<Scalars['String']>;
  /** 모바일 접수 불가 사유 메세지 */
  unavailableReason?: Maybe<Scalars['String']>;
};

export type MobileReceiptsOfTodayObject = {
  __typename?: 'MobileReceiptsOfTodayObject';
  /** 오후 접수 인원 제한 */
  afternoonReceiptLimit: Scalars['Int'];
  /** 오전 접수 인원 제한 */
  morningReceiptLimit: Scalars['Int'];
  /** 오후 접수 인원(누적), 기준 : 13시~ */
  receiptsOfAfternoon: Scalars['Int'];
  /** 오전 접수 인원(누적), 기준 : ~13시 */
  receiptsOfMorning: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 내 건강검진 기록 조회 */
  appendNhisMedicalCheckupHistories: CodeFMedicalCheckupHistoryUnionOutputType;
  /** @deprecated 웹뷰 통한 인증 X.. (220411) */
  applyIdentityVerification: SuccessObject;
  applyTermsOfService: SuccessObject;
  approveKakaopayRequest: Scalars['Boolean'];
  /** @deprecated do not use mutation (task-scheduler test mutation) */
  autoCreateReviewLikes: Scalars['Boolean'];
  cancelParticipationInVotingEvent: VotingEventOutput;
  cancelReceiptInQueue: Scalars['Boolean'];
  cancelVisitPharmacy: CancelVisitPharmacyOutput;
  /** 파트너스 어드민 - 관리자 인증 코드 유효성 검사 */
  checkHospitalAdminVerificationCode: Scalars['Boolean'];
  createAppointment: Appointment;
  createAppointmentProfile: AppointmentProfile;
  createCardRequest: Nicepay;
  /** 통합 동의 내역 생성 */
  createConsentHistory: ConsentHistory;
  createDirectTreatment: TreatmentOnCreate;
  createImageUploadUrls: Array<HospitalPictureUploadUrlResult>;
  createKakaopayRequest: KakaopayReadyResponseObject;
  /** 복약 알람 생성 */
  createMedicineAlarm: MedicineAlarm;
  /** 모바일 접수 요청 */
  createMobileReceipt: Scalars['ID'];
  createPatient: Patient;
  /** QR 접수 요청 */
  createQRReceipt: Scalars['ID'];
  /**
   * 앱 유저 접수 요청
   * @deprecated createMobileReceipt을 대신 사용할 것
   */
  createReceipt: Scalars['ID'];
  createReview: Review;
  createSymptomImage: Array<CreateSymptomImage>;
  createTreatmentPayment: Treatment;
  createTreatmentWithPatient: TreatmentOnCreate;
  createUntactQuestionnaireResponse: Array<UntactQuestionnaireResponse>;
  /** 유저 주소 생성 */
  createUserAddress: UserAddressObject;
  /** 앱 유저 내원진료 접수내역 가져오기 */
  createUserReceipt: Scalars['Float'];
  /** 모바일 접수(deQueue) */
  deQueueCreateMobileReceipt: Scalars['ID'];
  deleteAppointmentProfile: SuccessObject;
  deleteMe: SuccessObject;
  /** 복약 알람 삭제 */
  deleteMedicineAlarm: Scalars['Boolean'];
  deleteNotificationCenterPush: SuccessObject;
  deletePatient: SuccessObject;
  deletePaymentMethod: Scalars['Boolean'];
  deleteReview: Scalars['Boolean'];
  /** 유저 주소 삭제 */
  deleteUserAddress: Scalars['Boolean'];
  /** 앱 유저 내원진료 접수내역 삭제 */
  deleteUserReceipt: Scalars['Boolean'];
  /** 모바일 접수(enQueue) */
  enQueueCreateMobileReceipt: EnQueueCreateMobileReceiptOutput;
  /** accessToken으로 refreshToken 발급 */
  generateRefreshTokenByAccessToken: RefreshTokenObject;
  generateSmartTVCode: SmartTvCodeObject;
  /** 문진 S3 리소스 업로드, 다운로드 URL */
  issueMedicalQuestionnaireSubmittedHistoryUrl: S3Output;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  issueReviewImageUploads: Array<Scalars['String']>;
  /** 리뷰 이미지용 S3 리소스 및 업로드 URL 생성 요청 */
  issueReviewImageUrls: Array<Scalars['String']>;
  /** 리뷰 좋아요 토글 API - 현상태 리턴 */
  likeReview: Scalars['Boolean'];
  mappingAppUserToReceiptPatient: Scalars['Boolean'];
  mappingReceiptPatientToPatient: Patient;
  /** 최근 1년내 진료 기록 조회 */
  nhisTreatmentAndDrugContents: CodeFTreatmentAndDrugContentsUnionOutput;
  participatingInVotingEvent: VotingEventOutput;
  possibleDeleteMe: SuccessReasonObject;
  publishRejectCall: Scalars['Boolean'];
  /** 만보기 당일 걸음수 기록 */
  recordPedometer: Pedometer;
  /** 리뷰 신고 */
  reportReview: Scalars['Boolean'];
  requestBankTransferConfirmation: Scalars['Boolean'];
  requestCancelAppointment: Appointment;
  /** 본인인증 메시지 요청 */
  requestIdentityVerfication: RequestIdentityVerificationOutput;
  requestPrescriptionFee: RequestPrescriptionFeeOutput;
  requestVisitPharmacy: RequestVisitPharmacyOutput;
  retryTreatmentPayment: Treatment;
  /** 문진 S3 리소스 업로드 URL 생성 취소 */
  revokeMedicalQuestionnaireSubmittedHistoryUrl: Scalars['Boolean'];
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  revokeReviewImageUploads: Scalars['Boolean'];
  /** 리뷰 이미지용 S3 리소스 및 업로드 URL 생성 취소 */
  revokeReviewImageUrls: Scalars['Boolean'];
  revokeSymptomImage: Scalars['Boolean'];
  /** 만보기 당일 걸음수와 보상을 기록 */
  rewardPedometer: Pedometer;
  /** refreshToken 재발급 */
  rotateRefreshToken: RefreshTokenObject;
  samsungLifeEventRegister: Scalars['Boolean'];
  selectPaymentMethodToBankTransfer: Treatment;
  sendFaxVisitDelivery: Prescription;
  /** 약국 좋아요 (북마크) 수정 */
  setHospitalLike: Scalars['Boolean'];
  /** 약국 좋아요 (북마크) 수정 */
  setPharmacyLike: Scalars['Boolean'];
  signInDevice: SignInDeviceResult;
  socialSignIn: SignInUserResult;
  submitMedicalQuestionnaire: MedicalQuestionnaireSubmittedHistory;
  unmappingReceiptPatientToPatient: Patient;
  untactVisitSuccess: Array<UntactQuestionnaireResponse>;
  updateAppointment: Appointment;
  updateGoogleSpreadsheet: Scalars['Boolean'];
  updateHospital: Scalars['Boolean'];
  /** @deprecated updateHospital */
  updateHospitalDepartments: Scalars['Boolean'];
  /** 병원 리뷰 좋아요 (북마크) 수정 */
  updateHospitalLike: Scalars['Boolean'];
  /** @deprecated updateHospital */
  updateHospitalTags: Scalars['Boolean'];
  updateLikeOfVotingEvent: VotingEventSummary;
  /** 사용자 본인 프로필 정보 수정 */
  updateMe: UserObject;
  /** 복약 알람 수정 */
  updateMedicineAlarm: MedicineAlarm;
  /** 복약 여부 수정 */
  updateMedicineTaken: Scalars['Boolean'];
  /** 모바일 접수 병원 설정 */
  updateMobileHospitalReceptionAvailable: Scalars['Boolean'];
  updatePatient: Patient;
  updatePaymentMethodSetDefault: Scalars['Boolean'];
  updateReview: Review;
  updateTreatmentCallbackRequest: Treatment;
  updateTreatmentCancel: Treatment;
  updateTreatmentEndCall: Treatment;
  updateTreatmentMatchingFail: Treatment;
  updateTreatmentRetry: Treatment;
  /** 유저 주소 수정 */
  updateUserAddress: Scalars['Boolean'];
  upsertHospitalOperationTimes: Array<HospitalOperationTime>;
  validateSmartTVCode: Scalars['Boolean'];
  /** 본인인증 메시지 인증 및 나의 환자 생성 */
  verifyIdentityAndCreateMyPatient: SuccessObject;
  /** 본인인증 메시지 인증 */
  verifyIdentityVerfication: SuccessObject;
};

export type MutationAppendNhisMedicalCheckupHistoriesArgs = {
  input: CodeFMedicalCheckupInput;
};

export type MutationApplyIdentityVerificationArgs = {
  requestSequence: Scalars['String'];
};

export type MutationApplyTermsOfServiceArgs = {
  agreement: Scalars['Boolean'];
  termsOfServiceId: Scalars['ID'];
};

export type MutationApproveKakaopayRequestArgs = {
  data: KakaopayApproveInput;
};

export type MutationCancelParticipationInVotingEventArgs = {
  id: Scalars['ID'];
  numberOfVoters: Scalars['Int'];
};

export type MutationCancelReceiptInQueueArgs = {
  input?: InputMaybe<CancelReceiptInQueueInput>;
};

export type MutationCancelVisitPharmacyArgs = {
  reason?: InputMaybe<Scalars['String']>;
  treatmentId: Scalars['ID'];
};

export type MutationCheckHospitalAdminVerificationCodeArgs = {
  hospitalId: Scalars['ID'];
  targetAdminVerificationCode: Scalars['String'];
};

export type MutationCreateAppointmentArgs = {
  data: AppointmentInput;
};

export type MutationCreateAppointmentProfileArgs = {
  appointmentProfileInput: AppointmentProfileInput;
};

export type MutationCreateCardRequestArgs = {
  returnPage: Scalars['String'];
};

export type MutationCreateConsentHistoryArgs = {
  input: ConsentHistoryCreateInput;
};

export type MutationCreateDirectTreatmentArgs = {
  createTreatmentInput: TreatmentInput;
};

export type MutationCreateImageUploadUrlsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  hospitalId: Scalars['ID'];
};

export type MutationCreateKakaopayRequestArgs = {
  requestType?: InputMaybe<KakaopayRequestTypeEnum>;
};

export type MutationCreateMedicineAlarmArgs = {
  input: CreateMedicineAlarmInput;
};

export type MutationCreateMobileReceiptArgs = {
  input: CreateReceiptMobileInput;
};

export type MutationCreatePatientArgs = {
  data: PatientInput;
};

export type MutationCreateQrReceiptArgs = {
  input: CreateReceiptQrInput;
};

export type MutationCreateReceiptArgs = {
  input: CreateReceiptMobileInput;
};

export type MutationCreateReviewArgs = {
  reviewInfo: CreateReviewInput;
};

export type MutationCreateSymptomImageArgs = {
  fileNames: Array<Scalars['String']>;
};

export type MutationCreateTreatmentPaymentArgs = {
  installmentMonths?: InputMaybe<Scalars['Float']>;
  paymentMethodId: Scalars['ID'];
  signData?: InputMaybe<Scalars['String']>;
  treatmentFee: Scalars['Float'];
  treatmentId: Scalars['ID'];
};

export type MutationCreateTreatmentWithPatientArgs = {
  createTreatmentInput: TreatmentInput;
};

export type MutationCreateUntactQuestionnaireResponseArgs = {
  input: UntactQuestionnaireResponseInput;
};

export type MutationCreateUserAddressArgs = {
  input: UserAddressCreateInput;
};

export type MutationCreateUserReceiptArgs = {
  input: CreateUserReceiptInput;
};

export type MutationDeQueueCreateMobileReceiptArgs = {
  input: EnQueueCreateMobileReceiptInput;
};

export type MutationDeleteAppointmentProfileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMedicineAlarmArgs = {
  medicineAlarmId: Scalars['ID'];
};

export type MutationDeleteNotificationCenterPushArgs = {
  notificationCenterId: Scalars['ID'];
  notificationUserType: NotificationUserTypeEnum;
};

export type MutationDeletePatientArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePaymentMethodArgs = {
  clientMethodId: Scalars['ID'];
};

export type MutationDeleteReviewArgs = {
  reviewId: Scalars['ID'];
};

export type MutationDeleteUserAddressArgs = {
  userAddressId: Scalars['ID'];
};

export type MutationDeleteUserReceiptArgs = {
  receiptId: Scalars['ID'];
};

export type MutationEnQueueCreateMobileReceiptArgs = {
  input: EnQueueCreateMobileReceiptInput;
};

export type MutationIssueMedicalQuestionnaireSubmittedHistoryUrlArgs = {
  fileName: Scalars['String'];
  phone: Scalars['String'];
};

export type MutationIssueReviewImageUploadsArgs = {
  fileNames: Array<Scalars['String']>;
  reviewType: ReviewTypeEnum;
  targetId: Scalars['ID'];
};

export type MutationIssueReviewImageUrlsArgs = {
  fileNames: Array<Scalars['String']>;
  source: ReviewSourceEnum;
  sourceId: Scalars['ID'];
};

export type MutationLikeReviewArgs = {
  reviewId: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationMappingAppUserToReceiptPatientArgs = {
  receiptIdentityVerificationInput: ReceiptIdentityVerificationInput;
};

export type MutationMappingReceiptPatientToPatientArgs = {
  patientId: Scalars['ID'];
};

export type MutationNhisTreatmentAndDrugContentsArgs = {
  input: CodeFTreatmentAndDrugContentsInput;
};

export type MutationParticipatingInVotingEventArgs = {
  choices: Array<Scalars['Int']>;
  id: Scalars['ID'];
  numberOfVoters: Scalars['Int'];
};

export type MutationPublishRejectCallArgs = {
  treatmentId: Scalars['ID'];
};

export type MutationRecordPedometerArgs = {
  pedometerInput: PedometerInput;
};

export type MutationReportReviewArgs = {
  data: CreateReviewReportInput;
};

export type MutationRequestBankTransferConfirmationArgs = {
  treatmentId: Scalars['ID'];
};

export type MutationRequestCancelAppointmentArgs = {
  canceledDetailReason?: InputMaybe<Scalars['String']>;
  canceledReason?: InputMaybe<AppointmentCancelReasonEnum>;
  id: Scalars['ID'];
};

export type MutationRequestIdentityVerficationArgs = {
  data: RequestIdentityVerificationInput;
};

export type MutationRequestPrescriptionFeeArgs = {
  input: RequestPrescriptionFeeInput;
};

export type MutationRequestVisitPharmacyArgs = {
  input: RequestVisitPharmacyInput;
};

export type MutationRetryTreatmentPaymentArgs = {
  clientMethodId: Scalars['ID'];
  treatmentId: Scalars['ID'];
};

export type MutationRevokeMedicalQuestionnaireSubmittedHistoryUrlArgs = {
  presignedUrl: Scalars['String'];
};

export type MutationRevokeReviewImageUploadsArgs = {
  presignedUrls: Array<Scalars['String']>;
};

export type MutationRevokeReviewImageUrlsArgs = {
  presignedUrls: Array<Scalars['String']>;
};

export type MutationRevokeSymptomImageArgs = {
  resourceKeys: Array<Scalars['String']>;
};

export type MutationRewardPedometerArgs = {
  pedometerRewardInput: PedometerRewardInput;
};

export type MutationRotateRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationSelectPaymentMethodToBankTransferArgs = {
  treatmentFee: Scalars['Float'];
  treatmentId: Scalars['ID'];
};

export type MutationSendFaxVisitDeliveryArgs = {
  faxInput: VisitFaxInput;
};

export type MutationSetHospitalLikeArgs = {
  hospitalId: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationSetPharmacyLikeArgs = {
  pharmacyId: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationSignInDeviceArgs = {
  data: SignInDeviceInput;
};

export type MutationSocialSignInArgs = {
  data: SocialSignInInput;
};

export type MutationSubmitMedicalQuestionnaireArgs = {
  input: CreateMedicalQuestionnaireSubmittedHistoryInput;
};

export type MutationUnmappingReceiptPatientToPatientArgs = {
  patientId: Scalars['ID'];
};

export type MutationUntactVisitSuccessArgs = {
  input: UntactVisitSuccessInput;
};

export type MutationUpdateAppointmentArgs = {
  data: AppointmentInput;
  id: Scalars['ID'];
};

export type MutationUpdateGoogleSpreadsheetArgs = {
  input: UpdateGoogleSpreadsheetInput;
};

export type MutationUpdateHospitalArgs = {
  data: UpdateHospitalInput;
  id: Scalars['ID'];
};

export type MutationUpdateHospitalDepartmentsArgs = {
  departmentIds: Array<Scalars['ID']>;
  hospitalId: Scalars['ID'];
};

export type MutationUpdateHospitalLikeArgs = {
  input?: InputMaybe<UpdateHospitalLikeInput>;
};

export type MutationUpdateHospitalTagsArgs = {
  hospitalId: Scalars['ID'];
  tagNames: Array<Scalars['String']>;
};

export type MutationUpdateLikeOfVotingEventArgs = {
  id: Scalars['ID'];
  isLike: Scalars['Boolean'];
  numberOfLikes: Scalars['Int'];
};

export type MutationUpdateMeArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateMedicineAlarmArgs = {
  input: UpdateMedicineAlarmInput;
};

export type MutationUpdateMedicineTakenArgs = {
  medicineAlarmTimeId: Scalars['ID'];
  medicineTaken: Scalars['Boolean'];
};

export type MutationUpdateMobileHospitalReceptionAvailableArgs = {
  input: MobileReceiptHospitalConfigurationInput;
};

export type MutationUpdatePatientArgs = {
  data: PatientInput;
  id: Scalars['ID'];
};

export type MutationUpdatePaymentMethodSetDefaultArgs = {
  clientMethodId: Scalars['ID'];
};

export type MutationUpdateReviewArgs = {
  reviewId: Scalars['ID'];
  reviewInfo: UpdateReviewInput;
};

export type MutationUpdateTreatmentCallbackRequestArgs = {
  sendAlimtalk?: InputMaybe<Scalars['Boolean']>;
  treatmentId: Scalars['ID'];
};

export type MutationUpdateTreatmentCancelArgs = {
  enablePublish?: InputMaybe<Scalars['Boolean']>;
  treatmentId: Scalars['ID'];
};

export type MutationUpdateTreatmentEndCallArgs = {
  treatmentId: Scalars['ID'];
};

export type MutationUpdateTreatmentMatchingFailArgs = {
  treatmentId: Scalars['ID'];
};

export type MutationUpdateTreatmentRetryArgs = {
  treatmentId: Scalars['ID'];
};

export type MutationUpdateUserAddressArgs = {
  input: UserAddressUpdateInput;
};

export type MutationUpsertHospitalOperationTimesArgs = {
  data: Array<UpsertHospitalOperationTimeInput>;
  hospitalId: Scalars['ID'];
};

export type MutationValidateSmartTvCodeArgs = {
  code: Scalars['String'];
};

export type MutationVerifyIdentityAndCreateMyPatientArgs = {
  authNumber: Scalars['String'];
  identityVerificationId: Scalars['ID'];
};

export type MutationVerifyIdentityVerficationArgs = {
  authNumber: Scalars['String'];
  enableCI?: Scalars['Boolean'];
  identityVerificationId: Scalars['ID'];
};

export type NhisMedicalCheckupHistory = {
  __typename?: 'NhisMedicalCheckupHistory';
  alt?: Maybe<Scalars['String']>;
  ast?: Maybe<Scalars['String']>;
  bloodPressure?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['String']>;
  checkupYear?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  fastingBloodSugar?: Maybe<Scalars['String']>;
  gfr?: Maybe<Scalars['String']>;
  hdlCholesterol?: Maybe<Scalars['String']>;
  hearing?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  hemoglobin?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  judgement?: Maybe<Scalars['String']>;
  ldlCholesterol?: Maybe<Scalars['String']>;
  osteoporosis?: Maybe<Scalars['String']>;
  serumCreatinine?: Maybe<Scalars['String']>;
  sight?: Maybe<Scalars['String']>;
  tbChestDisease?: Maybe<Scalars['String']>;
  totalCholesterol?: Maybe<Scalars['String']>;
  triglyceride?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  urinaryProtein?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
  waist?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  yGpt?: Maybe<Scalars['String']>;
};

export type NhisMedicalCheckupHistoryQueryInput = {
  /** 검진년도 */
  checkupYear?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  /** userId (스키마파일에 우버에만 노출) */
  userId?: InputMaybe<Scalars['ID']>;
};

export type NhisMedicalDetail = {
  __typename?: 'NhisMedicalDetail';
  precribeDrugEffect?: Maybe<Scalars['String']>;
  prescribeCountDetail?: Maybe<Scalars['Float']>;
  prescribeDays?: Maybe<Scalars['Float']>;
  prescribeDrugCode?: Maybe<Scalars['String']>;
  prescribeDrugGuide?: Maybe<Scalars['String']>;
  prescribeDrugImage?: Maybe<Scalars['String']>;
  prescribeDrugName?: Maybe<Scalars['String']>;
  treatmentDate: Scalars['DateTime'];
  treatmentTypeDetail?: Maybe<Scalars['String']>;
};

export type NhisMedicalHistory = {
  __typename?: 'NhisMedicalHistory';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  medicalCount?: Maybe<Scalars['Float']>;
  medicalDetail?: Maybe<Array<Maybe<NhisMedicalDetail>>>;
  medicalFacilityName: Scalars['String'];
  pharmacyId?: Maybe<Scalars['ID']>;
  prescribeCount?: Maybe<Scalars['Float']>;
  treatmentDate: Scalars['DateTime'];
  treatmentType: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  userType: Scalars['String'];
  visitDays?: Maybe<Scalars['Float']>;
};

export type Nicepay = {
  __typename?: 'Nicepay';
  B_DAY: Scalars['String'];
  C_INFO_URL: Scalars['String'];
  METHOD: Scalars['String'];
  MID: Scalars['String'];
  M_NAME: Scalars['String'];
  M_NUM: Scalars['String'];
  OS_TYPE: Scalars['String'];
  OTP_TOKEN: Scalars['String'];
  PARTNER_CODE: Scalars['String'];
  RESULT_PAGE: Scalars['String'];
  SEX: Scalars['String'];
  SIGN: Scalars['String'];
  TRANS_DT: Scalars['String'];
  TR_ID: Scalars['String'];
};

/** 알림센터 푸시 */
export type NotificationCenterPush = {
  __typename?: 'NotificationCenterPush';
  /** 버튼명 */
  buttonLabel?: Maybe<Scalars['String']>;
  /** 분류명 */
  categoryName?: Maybe<Scalars['String']>;
  /** 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 단체 및 개인 알림 각 DB TABLE ID */
  notificationCenterId: Scalars['ID'];
  /** 유저타입 */
  notificationUserType: NotificationUserTypeEnum;
  /** oneSignal notificationID */
  oneSignalId: Scalars['ID'];
  /** 발송일 */
  sentAt: Scalars['DateTime'];
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 링크 URL */
  url?: Maybe<Scalars['String']>;
};

export type NotificationCenterPushQueryInput = {
  countPerPage?: Scalars['Int'];
  currentPage?: Scalars['Int'];
  monthBeforeStart?: Scalars['Int'];
  notificationUserType?: InputMaybe<NotificationUserTypeEnum>;
};

export enum NotificationUserTypeEnum {
  Group = 'group',
  Individual = 'individual'
}

/** 오프셋 페이지네이션된 병원 검색 결과 */
export type OffsetPaginatedHospitalObject = {
  __typename?: 'OffsetPaginatedHospitalObject';
  countTotal: Scalars['Int'];
  data: Array<Maybe<Hospital>>;
};

/** 오프셋 페이지네이션 리뷰 결과 */
export type OffsetPaginatedReviewObject = {
  __typename?: 'OffsetPaginatedReviewObject';
  countTotal: Scalars['Int'];
  data: Array<Maybe<ReviewObject>>;
};

export type OffsetPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OpentokConnectionOutput = {
  __typename?: 'OpentokConnectionOutput';
  /** Opentok Session Id */
  sessionId: Scalars['String'];
  /** Opentok Token */
  token: Scalars['String'];
};

export type OperationTime = {
  __typename?: 'OperationTime';
  from?: Maybe<Scalars['String']>;
  operationType: Scalars['String'];
  to?: Maybe<Scalars['String']>;
};

export enum OperatorTypeEnum {
  HospitalAdminUser = 'hospitalAdminUser',
  UberAdminUser = 'uberAdminUser'
}

export type PagePaginationInput = {
  /** 현재 페이지 번호 인덱스(0부터 시작) */
  currentPageIndex?: Scalars['Int'];
  /** 한 페이지내 출력개수 */
  limit?: Scalars['Int'];
};

export type PaginatedAppBanners = {
  __typename?: 'PaginatedAppBanners';
  countTotal: Scalars['Int'];
  data: Array<Maybe<AppBanner>>;
};

export type PaginatedAppointmentTreatments = {
  __typename?: 'PaginatedAppointmentTreatments';
  countTotal: Scalars['Int'];
  data: Array<Maybe<AppointmentTreatment>>;
};

export type PaginatedAppointments = {
  __typename?: 'PaginatedAppointments';
  countTotal: Scalars['Int'];
  data: Array<Maybe<Appointment>>;
};

/** 통합 동의서 수집내역 Paginated */
export type PaginatedConsentHistories = {
  __typename?: 'PaginatedConsentHistories';
  countTotal: Scalars['Int'];
  data: Array<Maybe<ConsentHistory>>;
};

export type PaginatedGoodocPopup = {
  __typename?: 'PaginatedGoodocPopup';
  countTotal: Scalars['Int'];
  data: Array<Maybe<GoodocPopup>>;
};

export type PaginatedHospitalNotices = {
  __typename?: 'PaginatedHospitalNotices';
  countTotal: Scalars['Int'];
  data: Array<Maybe<HospitalNotice>>;
};

export type PaginatedMedicalQuestionnaireSubmittedHistory = {
  __typename?: 'PaginatedMedicalQuestionnaireSubmittedHistory';
  countTotal: Scalars['Int'];
  data: Array<Maybe<MedicalQuestionnaireSubmittedHistory>>;
};

export type PaginatedNotificationCenterPushes = {
  __typename?: 'PaginatedNotificationCenterPushes';
  countTotal: Scalars['Int'];
  data: Array<Maybe<NotificationCenterPush>>;
};

export type PaginatedPopularSearchWords = {
  __typename?: 'PaginatedPopularSearchWords';
  countTotal: Scalars['Int'];
  data: Array<Maybe<PopularSearchWord>>;
};

export type PaginatedReceiptTreatmentItems = {
  __typename?: 'PaginatedReceiptTreatmentItems';
  countTotal: Scalars['Int'];
  data: Array<Maybe<ReceiptTreatmentItem>>;
};

export type PaginatedReceipts = {
  __typename?: 'PaginatedReceipts';
  countTotal: Scalars['Int'];
  data: Array<Maybe<Receipt>>;
};

export type PaginatedReviews = {
  __typename?: 'PaginatedReviews';
  countTotal: Scalars['Int'];
  data: Array<Maybe<Review>>;
};

export type PaginatedTreatmentHistories = {
  __typename?: 'PaginatedTreatmentHistories';
  countTotal: Scalars['Int'];
  data: Array<Maybe<TreatmentHistory>>;
};

export type PaginatedUserReceiptObjectsWithSearchedHospitals = {
  __typename?: 'PaginatedUserReceiptObjectsWithSearchedHospitals';
  countTotal: Scalars['Int'];
  data: Array<Maybe<UserReceipt>>;
  searchedHospitals?: Maybe<Scalars['JSONObject']>;
};

export type PaginatedUserReceiptOfTodayObjectsWithSearchedHospitals = {
  __typename?: 'PaginatedUserReceiptOfTodayObjectsWithSearchedHospitals';
  countTotal: Scalars['Int'];
  data: Array<Maybe<UserReceiptOfToday>>;
  searchedHospitals?: Maybe<Scalars['JSONObject']>;
};

export type PaginatedVotingEvents = {
  __typename?: 'PaginatedVotingEvents';
  countTotal: Scalars['Int'];
  data: Array<Maybe<VotingEventOutput>>;
};

export enum ParcelDeliveryStatusEnum {
  DeliveryStart = 'deliveryStart',
  MakingStart = 'makingStart',
  Waiting = 'waiting'
}

export type Patient = {
  __typename?: 'Patient';
  address?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Appointment>>;
  birth?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  deniedReceivingMessageAt?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Float']>;
  /** pk */
  id: Scalars['ID'];
  isUser: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  receiptPatient?: Maybe<ReceiptPatient>;
  receiptPatientId?: Maybe<Scalars['ID']>;
  residentRegistrationNumber?: Maybe<Scalars['String']>;
  sexdstn?: Maybe<Scalars['Int']>;
  treatments: Array<Treatment>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  weight?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type PatientInput = {
  /** 주소 */
  address?: InputMaybe<Scalars['String']>;
  /** 생년월일 */
  birth?: InputMaybe<Scalars['String']>;
  /** 수신거부일 */
  deniedReceivingMessageAt?: InputMaybe<Scalars['DateTime']>;
  /** 키 */
  height?: InputMaybe<Scalars['Float']>;
  /** 본인 여부 */
  isUser?: InputMaybe<Scalars['Boolean']>;
  /** 환자이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 핸드폰 번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 프로필 이미지 URL */
  profileImageUrl?: InputMaybe<Scalars['String']>;
  /** 주민등록번호 */
  residentRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 주민등록번호 7번째 자리 숫자 */
  sexdstn?: InputMaybe<Scalars['Int']>;
  /** 몸무게 */
  weight?: InputMaybe<Scalars['Float']>;
  /** 우편번호 */
  zipcode?: InputMaybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  cardName?: Maybe<Scalars['String']>;
  clientMethodId?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  lastCardNumber?: Maybe<Scalars['String']>;
  /** provider값, 기존 nicepay데이터는 null이라 임시조치 */
  provider?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  userId: Scalars['String'];
};

export enum PaymentMethodTypeEnum {
  BankTransfer = 'bankTransfer',
  Kakaopay = 'kakaopay',
  Nicepay = 'nicepay'
}

export enum PaymentStatusEnum {
  ConfirmationRequested = 'confirmationRequested',
  PaymentCompleted = 'paymentCompleted',
  PaymentFailed = 'paymentFailed',
  RefundCompleted = 'refundCompleted',
  RefundFailed = 'refundFailed',
  Requested = 'requested'
}

export type Pedometer = {
  __typename?: 'Pedometer';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 걸음수 */
  numberOfSteps: Scalars['Int'];
  /** 1000보 보상 푸시발송시간 */
  pushedAtFor1000?: Maybe<Scalars['DateTime']>;
  /** 5000보 보상 푸시발송시간 */
  pushedAtFor5000?: Maybe<Scalars['DateTime']>;
  /** 10000보 보상 푸시발송시간 */
  pushedAtFor10000?: Maybe<Scalars['DateTime']>;
  /** 기록 날짜(KST) YYYY-MM-DD */
  recordDate?: Maybe<Scalars['String']>;
  /** 1000보 보상받은 시간 */
  rewardedAtFor1000?: Maybe<Scalars['DateTime']>;
  /** 5000보 보상받은 시간 */
  rewardedAtFor5000?: Maybe<Scalars['DateTime']>;
  /** 10000보 보상받은 시간 */
  rewardedAtFor10000?: Maybe<Scalars['DateTime']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** 만보기 기록 */
export type PedometerInput = {
  /** 걸음수 */
  numberOfSteps: Scalars['Int'];
};

export enum PedometerRewardEnum {
  /** 1000보 보상 */
  Reward1000 = 'reward1000',
  /** 5000보 보상 */
  Reward5000 = 'reward5000',
  /** 10000보 보상 */
  Reward10000 = 'reward10000'
}

/** 만보기 보상 기록 */
export type PedometerRewardInput = {
  /** 걸음수 */
  numberOfSteps: Scalars['Int'];
  /** 만보기 보상기록 분류(1000,5000,10000) */
  rewardType: PedometerRewardEnum;
};

export type Pharmacy = {
  __typename?: 'Pharmacy';
  address?: Maybe<Scalars['String']>;
  aroundStationInformation?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  fax?: Maybe<Scalars['String']>;
  hiraCode?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nearbySubwayStations?: Maybe<Array<Maybe<PharmacyNearbyStationObject>>>;
  nmcCode?: Maybe<Scalars['String']>;
  openClosedStatus?: Maybe<PharmacyOpenClosedStatusEnum>;
  /** 운영 상태 (개폐업여부) */
  operatingState: PharmacyOperatingStateEnum;
  /** 운영시간 설명 2000자내외 */
  operationTimeDescription?: Maybe<Scalars['String']>;
  pharmacyHoliday114: PharmacyHoliday114;
  pharmacyLikes?: Maybe<Array<Maybe<PharmacyLike>>>;
  pharmacyOperationTimes: Array<PharmacyOperationTime>;
  pharmacyPharmacyAdminUsers: Array<PharmacyPharmacyAdminUser>;
  pharmacyPictures?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  prescriptions: Array<Prescription>;
  reviewSummary: ReviewSummary;
  reviewVisibility: Scalars['Boolean'];
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  safetyPhone?: Maybe<Scalars['String']>;
  untactVisibility: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type PharmacyAdminPermission = {
  __typename?: 'PharmacyAdminPermission';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  pharmacyAdminPermissionType: PharmacyAdminPermissionTypeEnum;
  pharmacyAdminUser: PharmacyAdminUser;
  pharmacyAdminUserId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum PharmacyAdminPermissionTypeEnum {
  Admin = 'admin',
  Default = 'default',
  DirectDelivery = 'directDelivery',
  Parcel = 'parcel'
}

export type PharmacyAdminUser = {
  __typename?: 'PharmacyAdminUser';
  active: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 담당자명 */
  name: Scalars['String'];
  permission?: Maybe<PharmacyAdminPermission>;
  /** 맵핑된 약국 테이블 */
  pharmacyPharmacyAdminUsers: Array<PharmacyPharmacyAdminUser>;
  /** 연락처 */
  phone?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 로그인용 아이디 */
  username: Scalars['String'];
};

/** 약국 검색 결과 */
export type PharmacyGeoPointObject = {
  __typename?: 'PharmacyGeoPointObject';
  /** 위도 */
  lat: Scalars['Float'];
  /** 경도 */
  lon: Scalars['Float'];
};

export type PharmacyHoliday114 = {
  __typename?: 'PharmacyHoliday114';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  operationType: Scalars['String'];
  pharmacy: Pharmacy;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type PharmacyHoliday114Object = {
  __typename?: 'PharmacyHoliday114Object';
  description?: Maybe<Scalars['String']>;
  operationType: Scalars['String'];
};

export type PharmacyLike = {
  __typename?: 'PharmacyLike';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  pharmacy: Pharmacy;
  /** 매칭된 약국 아이디 */
  pharmacyId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  /** 매칭된 유저 아이디 */
  userId: Scalars['ID'];
};

export type PharmacyNearbyStationObject = {
  __typename?: 'PharmacyNearbyStationObject';
  code: Scalars['String'];
  cyberStCode: Scalars['String'];
  /** 약국과 거리 (meter) */
  distance: Scalars['Float'];
  lineColor: Scalars['String'];
  lineName: Scalars['String'];
  lineNumber: Scalars['String'];
  name: Scalars['String'];
};

export type PharmacyObject = {
  __typename?: 'PharmacyObject';
  address?: Maybe<Scalars['String']>;
  aroundStationInformation?: Maybe<Scalars['String']>;
  /** 약국 기본이미지 */
  defaultImage: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  /** 라벨 */
  labels?: Maybe<Array<Maybe<LabelObject>>>;
  latitude?: Maybe<Scalars['Float']>;
  likedUser?: Maybe<Array<Maybe<UserObject>>>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** 가까운 지하철역 */
  nearbySubwayStations?: Maybe<Array<Maybe<PharmacyNearbyStationObject>>>;
  openClosedStatus?: Maybe<PharmacyOpenClosedStatusEnum>;
  /** 운영시간 설명 2000자내외 */
  operationTimeDescription?: Maybe<Scalars['String']>;
  /** 약국 휴일지킴이 운영정보 (연중무휴, 야간) */
  pharmacyHoliday114?: Maybe<PharmacyHoliday114Object>;
  /** 약국 운영시간 */
  pharmacyOperationTimes?: Maybe<Array<Maybe<PharmacyOperationTimeObject>>>;
  pharmacyPharmacyAdminUsers: Array<PharmacyPharmacyAdminUser>;
  pharmacyPictures?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  reviewSummary: ReviewSummary;
  reviews?: Maybe<Array<Maybe<Review>>>;
  safetyPhone?: Maybe<Scalars['String']>;
  visibility: Scalars['Boolean'];
};

export enum PharmacyOpenClosedStatusEnum {
  /** 영업종료 */
  Closed = 'closed',
  /** 휴무 */
  DayOff = 'dayOff',
  /** 영업중 */
  Open = 'open',
  /** 영업준비 */
  Ready = 'ready',
  /** 모름 */
  Unknown = 'unknown'
}

export enum PharmacyOperatingStateEnum {
  /** 폐업 */
  Closed = 'closed',
  /** 정상 */
  Operated = 'operated',
  /** 휴업 */
  Pended = 'pended',
  /** 모릅 */
  Unknown = 'unknown'
}

export type PharmacyOperationTime = {
  __typename?: 'PharmacyOperationTime';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  /** 시작시간(kst) hh:mm:ss */
  from: Scalars['String'];
  /** 공휴일 이름 */
  holidayName?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  operationOff: Scalars['Boolean'];
  operationType: PharmacyOperationTimeTypeEnum;
  pharmacy: Pharmacy;
  /** 해당하는 날짜 */
  targetDate?: Maybe<Scalars['DateTime']>;
  /** 종료시간(kst) hh:mm:ss */
  to: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type PharmacyOperationTimeObject = {
  __typename?: 'PharmacyOperationTimeObject';
  /** 시작시간(kst) hh:mm:ss */
  from: Scalars['String'];
  /** 공휴일 이름 */
  holidayName?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  operationOff: Scalars['Boolean'];
  operationType: PharmacyOperationTimeTypeEnum;
  /** 해당하는 날짜 */
  targetDate?: Maybe<Scalars['DateTime']>;
  /** 종료시간(kst) hh:mm:ss */
  to: Scalars['String'];
};

export enum PharmacyOperationTimeTypeEnum {
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  WeekdayLunch = 'weekdayLunch',
  WeekendLunch = 'weekendLunch'
}

export type PharmacyPharmacyAdminUser = {
  __typename?: 'PharmacyPharmacyAdminUser';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  pharmacy: Pharmacy;
  pharmacyAdminUser: PharmacyAdminUser;
  pharmacyAdminUserId: Scalars['ID'];
  pharmacyId: Scalars['ID'];
};

/** 약국 대표리뷰 */
export type PharmacyRepresentativeReviewObject = {
  __typename?: 'PharmacyRepresentativeReviewObject';
  /** 리뷰 8점 이상 여부 */
  is_recommended: Scalars['Boolean'];
  /** 리뷰 컨텐츠 */
  text: Scalars['String'];
};

/** 약국 검색 필터 인풋 */
export type PharmacySearchFilterInput = {
  /** 연중무휴 여부 - 병원 */
  allYearRoundAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 진료 요일 - 약국 */
  availableDays?: InputMaybe<Array<InputMaybe<PharmacyWorkingDays>>>;
  /** 현재 운영중 여부 - 직접 계산 */
  currentAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 거리제한(단위 km) */
  distance?: InputMaybe<Scalars['Float']>;
  /** Fax 번호 유무 */
  hasFax?: InputMaybe<Scalars['Boolean']>;
  /** 휴일(명절) 중 하루라도 운영 여부 */
  hasHolidayOpenDays?: InputMaybe<Scalars['Boolean']>;
  /** 24시 약국 여부 - 의료진 */
  open24HoursAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 비대면 추천 약국 검색 */
  recommendedUntactService?: InputMaybe<Scalars['Boolean']>;
  /** 특정 요일, 시간에 진료 여부 */
  specificDateAvailability?: InputMaybe<PharmacySpecificDateAvailabilityFilterInput>;
  /** 비대면 노출 약국 확인 */
  untactPartner?: InputMaybe<Scalars['Boolean']>;
};

/** 약국 리스트 결과 */
export type PharmacySearchObject = {
  __typename?: 'PharmacySearchObject';
  /** 약국 주소 */
  address: Scalars['String'];
  /** 약국 뱃지 */
  badges: Array<Scalars['String']>;
  /** 거리 */
  distance: Scalars['Float'];
  /** 약국 팩스번호 */
  fax?: Maybe<Scalars['String']>;
  /** 휴일(명절) 중 하루라도 운영 여부 */
  has_holiday_open_days?: Maybe<Scalars['Boolean']>;
  /** 약국 아이디 */
  id: Scalars['ID'];
  /** 비대면 제휴 약국 여부 */
  isUntactPartner?: Maybe<Scalars['Boolean']>;
  /** 약국 라벨 */
  labels?: Maybe<Array<Maybe<LabelObject>>>;
  location: PharmacyGeoPointObject;
  /** 약국 이름 */
  name: Scalars['String'];
  /** 영업중 여부 */
  open_closed_status?: Maybe<Scalars['String']>;
  /** 약국 전화번호 */
  phone?: Maybe<Scalars['String']>;
  /** 해당 약국에서 처방한 처방전 숫자 */
  prescriptionCount?: Maybe<Scalars['Float']>;
  /** 약국 대표 리뷰 */
  representative_review?: Maybe<PharmacyRepresentativeReviewObject>;
  /** 약국 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 약국 리뷰 평균점수 */
  review_rating?: Maybe<Scalars['Float']>;
  /** 약국 태그 (노출X) */
  tags?: Maybe<Array<Scalars['String']>>;
  time_table: Array<PharmacyTimeTableObject>;
  /** 노출 여부 */
  visibility: Scalars['Boolean'];
};

export type PharmacySearchObjectEdge = {
  __typename?: 'PharmacySearchObjectEdge';
  cursor: Scalars['String'];
  node: PharmacySearchObject;
};

/** 약국 검색 정렬 인풋 */
export type PharmacySearchSortInput = {
  /** 검색 정확도순 정렬 (DESC) */
  accuracy?: InputMaybe<Scalars['String']>;
  /** 거리순 정렬 (ASC) */
  distance?: InputMaybe<Scalars['String']>;
};

/** 약국 특정 요일/시간의 진료 여부 객체 */
export type PharmacySpecificDateAvailabilityFilterInput = {
  /** 타겟 요일 */
  dayOfTheWeek: PharmacyWorkingDays;
  /** 타겟 시간 */
  time: Scalars['String'];
};

/** 약국 운영 시간표 */
export type PharmacyTimeTableObject = {
  __typename?: 'PharmacyTimeTableObject';
  /** 종료시간 */
  closed_at: Scalars['String'];
  /** 요일 */
  day_type: Scalars['String'];
  /** 공휴일 */
  holiday_name: Scalars['String'];
  /** 시작시간 */
  opened_at: Scalars['String'];
  /** 날짜 */
  target_date: Scalars['String'];
  /** 미운영 여부 */
  time_off: Scalars['Boolean'];
};

export enum PharmacyWorkingDays {
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type PopularHospital = {
  __typename?: 'PopularHospital';
  /** 구분 조건 */
  categorizeBy: PopularHospitalByEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 병원 리스트 */
  hospitals?: Maybe<Array<Scalars['JSONObject']>>;
  /** pk */
  id: Scalars['ID'];
  /** 인기기준 이름 */
  name: Scalars['String'];
  /** 정렬 */
  sortIndex: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum PopularHospitalByEnum {
  Category = 'category'
}

export type PopularSearchWord = {
  __typename?: 'PopularSearchWord';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 순위 */
  ranking?: Maybe<Scalars['Int']>;
  /** 검색어 */
  searchWord: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type PopularSearchWordQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  rankings?: InputMaybe<Array<Scalars['Float']>>;
  searchWord?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type Prescription = {
  __typename?: 'Prescription';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  delivery?: Maybe<Delivery>;
  deliveryMethod?: Maybe<DeliveryMethodEnum>;
  faxRequestedAt?: Maybe<Scalars['DateTime']>;
  faxRespondedAt?: Maybe<Scalars['DateTime']>;
  faxResultCode?: Maybe<Scalars['String']>;
  /** 조제비 */
  fee?: Maybe<Scalars['Float']>;
  fileS3Keys?: Maybe<Array<Scalars['String']>>;
  fileUrls?: Maybe<Array<Scalars['String']>>;
  /** pk */
  id: Scalars['ID'];
  /** 처방전이 보험인지 비보험인지 여부 */
  insuredType?: Maybe<InsuredTypeEnum>;
  /** 결제 취소용 ID */
  paymentId?: Maybe<Scalars['ID']>;
  /** 결제 수단 ID */
  paymentMethodId?: Maybe<Scalars['String']>;
  /** 결제 수단 종류 */
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>;
  /** 결제 상태 */
  paymentStatus?: Maybe<PaymentStatusEnum>;
  pharmacy?: Maybe<Pharmacy>;
  pharmacyId?: Maybe<Scalars['ID']>;
  popbillReceiptNumber?: Maybe<Scalars['String']>;
  prescriptionDispatchHistories?: Maybe<Array<PrescriptionDispatchHistory>>;
  /** 직접수령 약국 전송 히스토리 최신 ID */
  prescriptionDispatchHistoryId?: Maybe<Scalars['ID']>;
  prescriptionPayments?: Maybe<Array<PrescriptionPayment>>;
  status: PrescriptionStatusEnum;
  treatment: Treatment;
  treatmentId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type PrescriptionDispatchHistory = {
  __typename?: 'PrescriptionDispatchHistory';
  alternativeDispensing?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  estimateVisitTime?: Maybe<Scalars['DateTime']>;
  estimatedDispensingTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  /** pk */
  id: Scalars['ID'];
  patientName: Scalars['String'];
  patientPhone: Scalars['String'];
  patientRequests?: Maybe<Scalars['String']>;
  pharmacyAccountHolder?: Maybe<Scalars['String']>;
  pharmacyAddress: Scalars['String'];
  pharmacyBankAccount?: Maybe<Scalars['String']>;
  pharmacyBankName?: Maybe<Scalars['String']>;
  pharmacyCode: Scalars['String'];
  pharmacyName: Scalars['String'];
  pharmacyPhone: Scalars['String'];
  prescription: Prescription;
  prescriptionId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  receiptType: PrescriptionReceiptTypeEnum;
  /** 발송 상태 */
  status: PrescriptionDispatchStatusEnum;
  substituteName?: Maybe<Scalars['String']>;
  substitutePhone?: Maybe<Scalars['String']>;
  substituteRelation?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum PrescriptionDispatchStatusEnum {
  PatientAsk = 'patientAsk',
  PatientCancel = 'patientCancel',
  PatientReceived = 'patientReceived',
  PatientRequest = 'patientRequest',
  PharmacyAvailable = 'pharmacyAvailable',
  PharmacyDispensed = 'pharmacyDispensed',
  PharmacyDispensing = 'pharmacyDispensing',
  PharmacyRejected = 'pharmacyRejected'
}

export type PrescriptionPayment = {
  __typename?: 'PrescriptionPayment';
  comment?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  paymentId?: Maybe<Scalars['ID']>;
  paymentMethodId: Scalars['ID'];
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>;
  paymentStatus?: Maybe<Scalars['String']>;
  prescription: Prescription;
  prescriptionId: Scalars['ID'];
  price: Scalars['Int'];
  status?: Maybe<PaymentStatusEnum>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum PrescriptionReceiptTypeEnum {
  RepresentativeReceiving = 'representativeReceiving',
  SelfReceiving = 'selfReceiving'
}

export enum PrescriptionStateEnum {
  None = 'none',
  Required = 'required'
}

export enum PrescriptionStatusEnum {
  Faxfail = 'faxfail',
  Faxsuccess = 'faxsuccess',
  Faxwaiting = 'faxwaiting',
  Issued = 'issued'
}

export enum PriorityTypeOnTabletReceipt {
  Phone = 'phone',
  ResidentNumber = 'residentNumber'
}

export enum ProductTypeEnum {
  AddOn = 'addOn',
  Mobile = 'mobile',
  Tv = 'tv'
}

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  addressCoordinates: LocationAddressObject;
  /** 배너 조회 */
  appBanners: PaginatedAppBanners;
  /** 취소 사유 리스트 */
  appointmentCancelReasons: Array<KeyValueObject>;
  /** 병원 예약 사용자 프로필 */
  appointmentProfiles: Array<AppointmentProfile>;
  /**
   * 예약 목적
   * @deprecated appointmentTreatment의 ID 값을 사용할 것
   */
  appointmentPurposes: Array<KeyValueObject>;
  /** 예약 가능 슬롯 */
  appointmentTimeslots: Array<AppointmentTimeslot>;
  /** 진료항목 조회 */
  appointmentTreatments: PaginatedAppointmentTreatments;
  /** 병원 예약 */
  appointments: PaginatedAppointments;
  autocompleteUser: AutocompleteUserResult;
  /** 자동완성 */
  autocompletes: Array<AutocompleOutput>;
  /** 자동완성 2 */
  autocompletes_2: Array<AutocompleOutput>;
  /** 예약 가능 일정 (날짜) */
  availableAppointmentDates: Array<Maybe<AppointmentHospitalSchedule>>;
  /**
   * 예약 가능 슬롯
   * @deprecated appointmentTimeslots를 호출할 것
   */
  availableTimesForAppointment: Array<Scalars['String']>;
  /** 2022 올해의 굿닥 명의  */
  awards2022Doctors: Array<Doctor>;
  /** 진행 가능 여부 확인 : 재진 환자 설정 기반 */
  checkConsentAndReturnVisitorPreference: Array<Maybe<FindPatientsInChartOutput>>;
  /**  병원 예약 가능 상태 확인  */
  checkHospitalAppointmentStatus: HospitalAppointmentStatusObject;
  /** 회원 가입 여부 확인 */
  checkSocialSignedUp: Scalars['Boolean'];
  /** 통합 동의 내역 */
  consentHistories: PaginatedConsentHistories;
  /** 동의서 리스트 */
  consents: Array<Consent>;
  /**  큐레이션 병원 목록 조회 */
  curationHospitals: Array<Maybe<SearchV2HomeHospitalObject>>;
  departments: Array<Department>;
  /** 의사 정보 */
  doctor: Doctor;
  /** 앱 유저 접수 내역 조회 - 본인인증 / 매핑 불필요 */
  filteredReceipts: FilteredReceipts;
  /** 주민등록번호로 비연동 병원 내원진료 환자 검색 */
  findNonLinkPatientByResidentRegistrationNumber?: Maybe<FindNonLinkPatientsOutput>;
  /** 전화번호로 비연동 병원 환자 검색 */
  findNonLinkPatientsByPhone: Array<FindNonLinkPatientsOutput>;
  /** 모바일 접수 -> 굿닥 서버 / 내원진료 환자 정보 조회 */
  findPatientsInChart: Array<FindPatientsInChartOutput>;
  /** 접수 동의정보 가져오기 */
  getConsents: Array<Consent>;
  getPopularHospitals: Array<PopularHospital>;
  goodocPopups: PaginatedGoodocPopup;
  hasReceiptPatientInPatient: Scalars['Boolean'];
  /** 본인인증을 완료한 사용자인지 확인 */
  haveBeenIdentified: SuccessObject;
  /** 병원 쿼리 */
  hospital: HospitalObject;
  hospitalNotice: HospitalNotice;
  /** 병원 공지사항 */
  hospitalNotices: PaginatedHospitalNotices;
  hospitalReviews: OffsetPaginatedReviewObject;
  /** 병원 필터 */
  hospitalSearchfilters: Scalars['JSON'];
  hospitalTimeTable?: Maybe<HospitalNotice>;
  /** 복수 병원 쿼리 */
  hospitals: Array<HospitalObject>;
  /**  병원 목록 조회 (병원 태그 관리) */
  hospitalsForTagManaging: OffsetPaginatedHospitalObject;
  /** 미리 접수 가능 여부 */
  isReceiptAvailable: Scalars['Boolean'];
  /** 문진 전송 대상자인지 확인하는 query */
  isTargetRequestMedicalQuestionnaire: Scalars['Boolean'];
  /** 앱 유저 본인이 태블릿 유저 간 매핑 가능여부. true: 매핑가능하지만 안 되어있음 / false: 매핑 되어있거나 매핑가능 환자가 없는 경우 */
  joinableReceiptPatient: Scalars['Boolean'];
  /** 앱 유저 본인을 제외하고 태블릿 유저 간 매핑 가능여부. true: 매핑가능하지만 안 되어있음 / false: 매핑 되어있거나 매핑가능 환자가 없는 경우 */
  joinableReceiptPatientExceptMe: Scalars['Boolean'];
  keywordAddresses: KeywordAddressObject;
  /** 법정동 주소 가져오기 */
  listDistrictCodes?: Maybe<Array<DistrictObject>>;
  locationAddresses: LocationAddressObject;
  /** 태그 조회 (병원 태그 관리) */
  managedTags: Array<ManagedTag>;
  /** 사용자 본인의 프로필 정보 */
  me: UserObject;
  /** 링크복사를 통한 문진 조회 */
  medicalQuestionnaire: MedicalQuestionnaire;
  /** 문진 요청 기록 조회 */
  medicalQuestionnaireRequestHistory: MedicalQuestionnaireRequestHistoryObject;
  medicalQuestionnaireSubmittedHistory: PaginatedMedicalQuestionnaireSubmittedHistory;
  medicalServicesById: MedicalService;
  /** 복약 알람 목록 */
  medicineAlarms: Array<MedicineAlarm>;
  /** 모바일 접수 현재 상태 조회 */
  mobileReceiptAvailability: MobileReceiptAvailabilityObject;
  /** 병원 접수(진료) 항목별 오늘의 모바일 접수 내역 */
  mobileReceiptCountOfToday: Array<MobileReceiptCountOfTodayObject>;
  /** 병원별 오늘의 모바일 접수 내역 */
  mobileReceiptsOfToday: MobileReceiptsOfTodayObject;
  /** 좋아요 약국 목록 */
  myHospitalLikes: Array<HospitalObject>;
  /** 좋아요 약국 목록 */
  myPharmacyLikes: Array<PharmacyObject>;
  /** 신규 의사 목록 */
  newUntactDoctors: Array<Doctor>;
  /** 내 건강검진 기록 조회 */
  nhisMedicalCheckupHistories: Array<NhisMedicalCheckupHistory>;
  nhisMedicalHistories: Array<NhisMedicalHistory>;
  /** 알림센터 푸시내역 */
  notificationCenterPushes: PaginatedNotificationCenterPushes;
  opentokConnection: OpentokConnectionOutput;
  patients: Array<Patient>;
  paymentMethods: Array<PaymentMethod>;
  /** 약국 상세 */
  pharmacy: PharmacyObject;
  pharmacyReviews: OffsetPaginatedReviewObject;
  pharmacySearchfilters: Scalars['JSON'];
  /** @deprecated HospitalObject 타입 Pruning 방지를 위한 Placeholder */
  placeholderHospitalObject: HospitalObject;
  /** 인기검색어 조회 */
  popularSearchWords: PaginatedPopularSearchWords;
  prescription: Prescription;
  /** 파트너스 어드민 - 관리자 인증 코드 조회 */
  readHospitalAdminVerificationCode?: Maybe<Scalars['String']>;
  /** 만보기 당일 걸음수 기록 읽어오기 */
  readPedometer: Pedometer;
  receipt: ReceiptOfToday;
  /** 접수(진료)항목 모바일 설정 조회 */
  receiptTreatmentItemMobileConfiguration: ReceiptTreatmentItemMobileConfiguration;
  /** 접수(진료)항목 모바일 설정 목록 조회 */
  receiptTreatmentItemMobileConfigurations: Array<ReceiptTreatmentItemMobileConfiguration>;
  /** 접수(진료)항목 모바일 운영시간 설정 조회 */
  receiptTreatmentItemMobileOperationTimes: Array<ReceiptTreatmentItemMobileOperationTime>;
  /** 병원별 내원목적 및 진료과목 */
  receiptTreatmentItems: PaginatedReceiptTreatmentItems;
  /**
   * 앱 유저 내원진료 내역
   * @deprecated userReceipts를 대신 사용할 것 (SP 42)
   */
  receipts: PaginatedReceipts;
  /** 앱 유저 내원 진료 접수 내역 조회 : 진행 중인 것만 조회 */
  receiptsInProgress: PaginatedUserReceiptOfTodayObjectsWithSearchedHospitals;
  recentAppointmentTriedHospitals: Array<HospitalObject>;
  /**  최근 진료받은 병원 목록  */
  recentTreatedHospitals?: Maybe<Array<Maybe<RecentTreatedHospitalObject>>>;
  recentTreatment: RecentTreatment;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  review: ReviewObject;
  reviewReplies: Array<ReviewReply>;
  reviews: PaginatedReviews;
  samsungLifeEventVerify: Scalars['Boolean'];
  /** 통합 검색 결과 */
  searchAll: SearchAllOutput;
  /** 병원 검색 리스트 */
  searchHospitals: CursorPaginatedHospitalSearchObject;
  /** 약국 검색 리스트 */
  searchPharmacies: CursorPaginatedPharmacySearchObject;
  searchVisitPharmacies: VisitPharmaciesOutput;
  specialistDepartments: Array<SpecialistDepartment>;
  termsOfServices: Array<TermsOfServiceObject>;
  /** 진료내역 쿼리 */
  treatmentHistories: PaginatedTreatmentHistories;
  treatmentV3: Treatment;
  treatments: Array<Treatment>;
  treatmentsV3: Array<Treatment>;
  trySignInSmartTV: SignInUserResult;
  /** 통합 검색 리스트 */
  universalSearch: UniversalSearchListObject;
  /** 통합 검색 의사 리스트 */
  universalSearchDoctors: UniversalSearchDoctorListObject;
  /** 통합 검색 시술 리스트 */
  universalSearchEvents: UniversalSearchEventListObject;
  /** 통합 검색 병원 리스트 */
  universalSearchHospitals: UniversalSearchHospitalListObject;
  untactBanners: Array<UntactBannerObject>;
  untactDepartments: Array<UntactDepartment>;
  untactDepartmentsDoctors: Array<UntactDepartmentsDoctorsObject>;
  untactDoctor: Doctor;
  untactGuides: Array<UntactGuideObject>;
  untactHospitalDoctors: Array<Doctor>;
  untactOperation: UntactOperation;
  untactQuestionnaire: Array<UntactQuestionnaire>;
  untactQuestionnaireResponse: Array<UntactQuestionnaireResponse>;
  untactQuestionnaireResponses: Array<UntactQuestionnaireResponse>;
  /** 전문의 과목별 의사 목록 */
  untactSpecialistDepartmentDoctors: Array<Doctor>;
  untactTvBanners: Array<UntactTvBanner>;
  untactTvDepartmentDoctors: Array<Doctor>;
  /** 앱 유저의 현재 진료, 예약 등의 상태를 조회한다. */
  useServiceStatus: UseServiceStatusObject;
  /** 유저의 주소 목록 */
  userAddresses: Array<Maybe<UserAddressObject>>;
  /** 앱 유저 내원진료 접수내역 조회 */
  userReceipts: PaginatedUserReceiptObjectsWithSearchedHospitals;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  userReviews: PaginatedReviews;
  votingEvent: VotingEventOutput;
  votingEventHistory: VotingEventHistory;
  votingEventSummary: VotingEventSummary;
  votingEvents: PaginatedVotingEvents;
  waitingOrderInQueue: Scalars['Int'];
};

export type QueryAddressCoordinatesArgs = {
  address: Scalars['String'];
};

export type QueryAppBannersArgs = {
  appBannerQueryInput: AppBannerQueryInput;
};

export type QueryAppointmentTimeslotsArgs = {
  input: AppointmentTimeslotQueryInput;
};

export type QueryAppointmentTreatmentsArgs = {
  appointmentTreatmentQueryInput: AppointmentTreatmentQueryInput;
};

export type QueryAppointmentsArgs = {
  input: AppointmentQueryInput;
};

export type QueryAutocompleteUserArgs = {
  where: AutocompleteUserInput;
};

export type QueryAutocompletesArgs = {
  size?: Scalars['Int'];
  text: Scalars['String'];
  type?: SearchTypeEnum;
};

export type QueryAutocompletes_2Args = {
  size?: Scalars['Int'];
  text: Scalars['String'];
  type?: SearchTypeEnum;
};

export type QueryAvailableAppointmentDatesArgs = {
  appointmentTreatmentId: Scalars['ID'];
  fromDate: Scalars['DateTime'];
  hospitalId: Scalars['ID'];
  toDate: Scalars['DateTime'];
};

export type QueryAvailableTimesForAppointmentArgs = {
  appointmentTreatmentId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  hospitalId: Scalars['ID'];
  patientId?: InputMaybe<Scalars['ID']>;
};

export type QueryCheckConsentAndReturnVisitorPreferenceArgs = {
  hospitalId: Scalars['ID'];
  input: CheckConsentAndReturnVisitorPreferenceInput;
};

export type QueryCheckHospitalAppointmentStatusArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryCheckSocialSignedUpArgs = {
  provider: SocialAuthProviderEnum;
  uid: Scalars['String'];
};

export type QueryConsentHistoriesArgs = {
  input: ConsentQueryInput;
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryConsentsArgs = {
  input?: ConsentDocumentQueryInput;
};

export type QueryCurationHospitalsArgs = {
  input: CurationHospitalsQueryInput;
};

export type QueryDepartmentsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryDoctorArgs = {
  id: Scalars['ID'];
};

export type QueryFilteredReceiptsArgs = {
  input?: InputMaybe<FilteredReceiptQueryInput>;
};

export type QueryFindNonLinkPatientByResidentRegistrationNumberArgs = {
  data: FindNonLinkPatientByResidentRegistrationNumberInput;
  hospitalId: Scalars['ID'];
};

export type QueryFindNonLinkPatientsByPhoneArgs = {
  data: FindNonLinkPatientsByPhoneInput;
  hospitalId: Scalars['ID'];
};

export type QueryFindPatientsInChartArgs = {
  data: FindPatientsInChartInput;
  hospitalId: Scalars['ID'];
};

export type QueryGetConsentsArgs = {
  useOldMarketingAgreement?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPopularHospitalsArgs = {
  input: ClinicPopularHospitalQueryInput;
};

export type QueryGoodocPopupsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FindGoodocPopupInput>;
};

export type QueryHasReceiptPatientInPatientArgs = {
  patientId?: InputMaybe<Scalars['ID']>;
};

export type QueryHospitalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  legacyId?: InputMaybe<Scalars['ID']>;
};

export type QueryHospitalNoticeArgs = {
  id: Scalars['ID'];
};

export type QueryHospitalNoticesArgs = {
  input?: InputMaybe<HospitalNoticeQueryInput>;
};

export type QueryHospitalReviewsArgs = {
  hospitalId: Scalars['ID'];
  pagination: OffsetPaginationInput;
};

export type QueryHospitalTimeTableArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryHospitalsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryHospitalsForTagManagingArgs = {
  input: HospitalsForTagManagingQueryInput;
};

export type QueryIsReceiptAvailableArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryIsTargetRequestMedicalQuestionnaireArgs = {
  isReturnReceiptPatient: Scalars['Boolean'];
  medicalQuestionnaireId: Scalars['String'];
  residentRegistrationNumber: Scalars['String'];
};

export type QueryKeywordAddressesArgs = {
  addressKeyword: Scalars['String'];
};

export type QueryListDistrictCodesArgs = {
  data: QueryDistrictInput;
};

export type QueryLocationAddressesArgs = {
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type QueryManagedTagsArgs = {
  type?: InputMaybe<ManagedTagSearchIndexTypeEnum>;
};

export type QueryMedicalQuestionnaireArgs = {
  check: Scalars['String'];
  hospitalCheck: Scalars['String'];
  hospitalId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryMedicalQuestionnaireRequestHistoryArgs = {
  check: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryMedicalQuestionnaireSubmittedHistoryArgs = {
  input: MedicalQuestionnaireSubmittedHistoryQueryInput;
};

export type QueryMedicalServicesByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMobileReceiptAvailabilityArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryMobileReceiptCountOfTodayArgs = {
  input: ReceiptTreatmentItemMobileOperationTimeQueryInput;
};

export type QueryMobileReceiptsOfTodayArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryMyHospitalLikesArgs = {
  hospitalId?: InputMaybe<Scalars['ID']>;
};

export type QueryMyPharmacyLikesArgs = {
  pharmacyId?: InputMaybe<Scalars['ID']>;
};

export type QueryNhisMedicalCheckupHistoriesArgs = {
  input: NhisMedicalCheckupHistoryQueryInput;
};

export type QueryNhisMedicalHistoriesArgs = {
  input?: InputMaybe<CodeFTreatmentAndDrugContentsQueryInput>;
};

export type QueryNotificationCenterPushesArgs = {
  notificationCenterPushQueryInput: NotificationCenterPushQueryInput;
};

export type QueryOpentokConnectionArgs = {
  treatmentId: Scalars['ID'];
};

export type QueryPatientsArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryPharmacyArgs = {
  id: Scalars['ID'];
};

export type QueryPharmacyReviewsArgs = {
  pagination: OffsetPaginationInput;
  pharmacyId: Scalars['ID'];
};

export type QueryPopularSearchWordsArgs = {
  input: PopularSearchWordQueryInput;
};

export type QueryPrescriptionArgs = {
  id: Scalars['String'];
};

export type QueryReadHospitalAdminVerificationCodeArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryReceiptArgs = {
  input?: InputMaybe<ReceiptSingleRecordQueryInput>;
};

export type QueryReceiptTreatmentItemMobileConfigurationArgs = {
  receiptTreatmentItemId: Scalars['ID'];
};

export type QueryReceiptTreatmentItemMobileConfigurationsArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryReceiptTreatmentItemMobileOperationTimesArgs = {
  input: ReceiptTreatmentItemMobileOperationTimeQueryInput;
};

export type QueryReceiptTreatmentItemsArgs = {
  input: ReceiptTreatmentItemQueryInput;
};

export type QueryReceiptsArgs = {
  input: ReceiptQueryInput;
};

export type QueryReceiptsInProgressArgs = {
  input: UserReceiptQueryInput;
};

export type QueryRecentAppointmentTriedHospitalsArgs = {
  count: Scalars['Int'];
};

export type QueryRecentTreatedHospitalsArgs = {
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryRecentTreatmentArgs = {
  status?: InputMaybe<Array<TreatmentStatusEnum>>;
};

export type QueryReviewArgs = {
  reviewId: Scalars['ID'];
};

export type QueryReviewRepliesArgs = {
  reviewId: Scalars['ID'];
  withDeleted?: Scalars['Boolean'];
};

export type QueryReviewsArgs = {
  input: ReviewQueryInput;
};

export type QuerySearchAllArgs = {
  text: Scalars['String'];
};

export type QuerySearchHospitalsArgs = {
  filter: HospitalSearchFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: CursorPaginationInput;
  query?: InputMaybe<Scalars['String']>;
  sort: HospitalSearchSortInput;
};

export type QuerySearchPharmaciesArgs = {
  filter: PharmacySearchFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: CursorPaginationInput;
  query?: InputMaybe<Scalars['String']>;
  sort: PharmacySearchSortInput;
};

export type QuerySearchVisitPharmaciesArgs = {
  distance?: Scalars['Int'];
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type QuerySpecialistDepartmentsArgs = {
  existsDoctor?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTermsOfServicesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  types?: InputMaybe<Array<AgreementTypeEnum>>;
};

export type QueryTreatmentHistoriesArgs = {
  input: TreatmentHistoryQueryInput;
};

export type QueryTreatmentV3Args = {
  treatmentId: Scalars['ID'];
};

export type QueryTreatmentsArgs = {
  status?: InputMaybe<Array<TreatmentStatusEnum>>;
};

export type QueryUniversalSearchArgs = {
  filter: UniversalSearchFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: UniversalSearchPaginationInput;
  query?: Scalars['String'];
  sort: UniversalSearchSortInput;
  type?: UniversalSearchTypeEnum;
};

export type QueryUniversalSearchDoctorsArgs = {
  filter: UniversalSearchDoctorFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: PagePaginationInput;
  query?: Scalars['String'];
  sort?: UniversalSearchDoctorSortEnum;
};

export type QueryUniversalSearchEventsArgs = {
  filter: UniversalSearchEventFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: PagePaginationInput;
  query?: Scalars['String'];
  sort?: UniversalSearchEventSortEnum;
};

export type QueryUniversalSearchHospitalsArgs = {
  filter: HospitalSearchFilterInput;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
  pagination: PagePaginationInput;
  query?: Scalars['String'];
  sort?: UniversalSearchHospitalSortEnum;
};

export type QueryUntactDepartmentsArgs = {
  input?: InputMaybe<UntactDepartmentQueryInput>;
};

export type QueryUntactDepartmentsDoctorsArgs = {
  input: UntactDepartmentsDoctorsQueryInput;
};

export type QueryUntactDoctorArgs = {
  id: Scalars['ID'];
};

export type QueryUntactHospitalDoctorsArgs = {
  hospitalId: Scalars['ID'];
};

export type QueryUntactQuestionnaireArgs = {
  questionnaireType: QuestionnaireTypeEnum;
};

export type QueryUntactQuestionnaireResponseArgs = {
  questionnaireType: QuestionnaireTypeEnum;
  treatmentId: Scalars['ID'];
};

export type QueryUntactQuestionnaireResponsesArgs = {
  questionnaireType: QuestionnaireTypeEnum;
};

export type QueryUntactSpecialistDepartmentDoctorsArgs = {
  specialistDepartmentId: Scalars['ID'];
};

export type QueryUntactTvDepartmentDoctorsArgs = {
  untactDepartmentId: Scalars['ID'];
};

export type QueryUserReceiptsArgs = {
  input: UserReceiptQueryInput;
};

export type QueryUserReviewsArgs = {
  input: ReviewQueryInput;
};

export type QueryVotingEventArgs = {
  id: Scalars['ID'];
};

export type QueryVotingEventHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryVotingEventSummaryArgs = {
  id: Scalars['ID'];
};

export type QueryVotingEventsArgs = {
  input?: InputMaybe<FindVotingEventInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryWaitingOrderInQueueArgs = {
  input?: InputMaybe<WaitingOrderInQueueQueryInput>;
};

export type QueryDistrictInput = {
  /** 상위 법정동 코드 */
  upperCode?: InputMaybe<Scalars['String']>;
};

export enum QuestionnaireContentTypeEnum {
  Check = 'check',
  Text = 'text'
}

export enum QuestionnaireTypeEnum {
  Prescription = 'prescription'
}

export type Receipt = {
  __typename?: 'Receipt';
  cancelMemo?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chartReceiptId?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  departmentCode?: Maybe<Scalars['String']>;
  departmentName?: Maybe<Scalars['String']>;
  /** 사용자와 병원의 거리(m) */
  distance?: Maybe<Scalars['Int']>;
  doctorCode?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  /** 접수 대기시간 위한 기준 종료 시간 */
  endTimeOfReceiptForWaitingMinutes?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
  failReason?: Maybe<Scalars['String']>;
  funnel?: Maybe<Scalars['String']>;
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  otherSymptom?: Maybe<Scalars['String']>;
  patientFirstReceipt?: Maybe<Scalars['Boolean']>;
  receiptPatient: ReceiptPatient;
  receiptPatientId: Scalars['ID'];
  receiptStatus: ReceiptStatusEnum;
  receiptedAt?: Maybe<Scalars['DateTime']>;
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  roomCode?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  sourceType: ReceiptSourceTypeEnum;
  /** 접수 대기시간 위한 기준 시작 시간 */
  startTimeOfReceiptForWaitingMinutes?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  treatmentItemId?: Maybe<Scalars['ID']>;
  treatmentName?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userReceipts?: Maybe<Array<Maybe<UserReceipt>>>;
  /** 접수 대기시간 마지막 업데이트 시각 */
  waitingMinuteUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 접수 대기시간 (단위 : 분) */
  waitingMinutes?: Maybe<Scalars['Int']>;
  waitingNumber?: Maybe<Scalars['Int']>;
  waitingNumberFristOrderSetedAt?: Maybe<Scalars['DateTime']>;
  waitingNumberUpdatedAt?: Maybe<Scalars['DateTime']>;
  waitingOrderInQueue?: Maybe<Scalars['Int']>;
};

export enum ReceiptAlimtalkEvent {
  C01MobileReceiptNonLink = 'C01MobileReceiptNonLink',
  C01MobileReceiptNonLinkAndNonLinkHookingNotUseWaitMinute = 'C01MobileReceiptNonLinkAndNonLinkHookingNotUseWaitMinute',
  C01MobileReceiptNonLinkHooking = 'C01MobileReceiptNonLinkHooking',
  C01NonLinkAndNonLinkHooking = 'C01NonLinkAndNonLinkHooking',
  C01NonLinkAndNonLinkHookingNotUseWaitMinute = 'C01NonLinkAndNonLinkHookingNotUseWaitMinute',
  C01NonLinkHooking = 'C01NonLinkHooking',
  C01NonLinkUseWaitMinute = 'C01NonLinkUseWaitMinute',
  C01QrReceipt = 'C01QRReceipt',
  C01QrReceiptNonLink = 'C01QRReceiptNonLink',
  C01QrReceiptNonLinkAndNonLinkHookingNotUseWaitMinute = 'C01QRReceiptNonLinkAndNonLinkHookingNotUseWaitMinute',
  C01QrReceiptNonLinkHooking = 'C01QRReceiptNonLinkHooking',
  C03MobileReceiptNonLinkAndNonLinkHooking = 'C03MobileReceiptNonLinkAndNonLinkHooking',
  C03MobileReceiptNonLinkAndNonLinkHookingNotUseWaitMinute = 'C03MobileReceiptNonLinkAndNonLinkHookingNotUseWaitMinute',
  C03MobileReceiptNonLinkAndNonLinkHookingUseWaitMinute = 'C03MobileReceiptNonLinkAndNonLinkHookingUseWaitMinute',
  C03MobileReceiptNotUseWaitingNumber = 'C03MobileReceiptNotUseWaitingNumber',
  C03MobileReceiptUseWaitingNumber = 'C03MobileReceiptUseWaitingNumber',
  C03NonLinkAndNonLinkHookingNotUseWaitMinute = 'C03NonLinkAndNonLinkHookingNotUseWaitMinute',
  C03NonLinkAndNonLinkHookingUseWaitMinute = 'C03NonLinkAndNonLinkHookingUseWaitMinute',
  C03NotUseWaitingNumber = 'C03NotUseWaitingNumber',
  C03QrReceipt = 'C03QRReceipt',
  C03QrReceiptNonLinkAndNonLinkHookingNotUseWaitMinute = 'C03QRReceiptNonLinkAndNonLinkHookingNotUseWaitMinute',
  C03QrReceiptNonLinkAndNonLinkHookingUseWaitMinute = 'C03QRReceiptNonLinkAndNonLinkHookingUseWaitMinute',
  C03QrReceiptNotUseWaitingNumber = 'C03QRReceiptNotUseWaitingNumber',
  C03QrReceiptUseWaitingNumber = 'C03QRReceiptUseWaitingNumber',
  C03UseWaitingNumber = 'C03UseWaitingNumber',
  C04 = 'C04',
  C04NonLink = 'C04NonLink',
  C04NonLinkNotUseWaitMinute = 'C04NonLinkNotUseWaitMinute',
  C04NonLinkUseWaitMinute = 'C04NonLinkUseWaitMinute',
  C07 = 'C07',
  F03 = 'F03',
  F05 = 'F05',
  F05QrReceipt = 'F05QRReceipt',
  After1Day = 'after1Day',
  After3Day = 'after3Day'
}

export type ReceiptBridge = {
  __typename?: 'ReceiptBridge';
  bridgeClientId?: Maybe<Scalars['ID']>;
  connected: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  installed: Scalars['Boolean'];
  installedAt?: Maybe<Scalars['DateTime']>;
  lastLoggedInAt?: Maybe<Scalars['DateTime']>;
  osVersion: Scalars['String'];
  pcName: Scalars['String'];
  softwareVersion?: Maybe<ReceiptSoftwareVersion>;
  softwareVersionId: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ReceiptChart = {
  __typename?: 'ReceiptChart';
  bridgeSoftwareVersion?: Maybe<ReceiptSoftwareVersion>;
  bridgeSoftwareVersionId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  companyAddress: Scalars['String'];
  companyName: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  /** pk */
  id: Scalars['ID'];
  installationMethod: ChartInstallationEnum;
  linked: Scalars['Boolean'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nonLinkSoftwareVersion?: Maybe<ReceiptSoftwareVersion>;
  nonLinkSoftwareVersionId?: Maybe<Scalars['ID']>;
  responsibilityName: Scalars['String'];
  responsibilityPhone: Scalars['String'];
  tabletSoftwareVersion?: Maybe<ReceiptSoftwareVersion>;
  tabletSoftwareVersionId?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  used: Scalars['Boolean'];
};

export enum ReceiptConsentTypeEnum {
  Cs1 = 'CS1',
  Cs2 = 'CS2',
  Cs3 = 'CS3',
  Cs4 = 'CS4',
  Other = 'OTHER'
}

export type ReceiptHospitalConfiguration = {
  __typename?: 'ReceiptHospitalConfiguration';
  /** 오후 접수 인원 제한 */
  afternoonReceiptLimit: Scalars['Int'];
  averageTreatmentTime?: Maybe<Scalars['Int']>;
  chart?: Maybe<ReceiptChart>;
  chartId?: Maybe<Scalars['ID']>;
  contractAccepted: Scalars['Boolean'];
  contractAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  criteriaChangingWaitingCountToTime?: Maybe<Scalars['Int']>;
  /** CRM Message 사용 유무 */
  crmMessageAvailable?: Maybe<Scalars['Boolean']>;
  /** 개별 접수 대기시간(분) 기준 값 */
  defaultWaitMinutesForEach: Scalars['Int'];
  dummyReceiptAlarmUse: Scalars['Boolean'];
  /** 내원경로 종류 ex) 굿닥앱,검색,블로그,배너 */
  funnelList?: Maybe<Array<Scalars['String']>>;
  hospital: Hospital;
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  imminentAlarmUse: Scalars['Boolean'];
  /** (차트 기준) 재진 환자에게 동의서 동의 화면 노출 여부(default : true) */
  isVisibleReceiptConsentForReturnVisitors: Scalars['Boolean'];
  /** 모바일 접수 시작 전 설정(분) */
  minuteBeforeFirstMobileReceipt: Scalars['Int'];
  minuteBeforeLastMobileReceipt: Scalars['Int'];
  mobileReceiptAvailable: Scalars['Boolean'];
  mobileReceiptClosedAt?: Maybe<Scalars['DateTime']>;
  mobileReceiptOpen: Scalars['Boolean'];
  /** 오전 접수 인원 제한 */
  morningReceiptLimit: Scalars['Int'];
  /** 알림톡 이벤트별로 전송 제한하는 기능 (우버용 기능) */
  nonuseReceiptAlimtalkEventList?: Maybe<Array<ReceiptAlimtalkEvent>>;
  onlyReturnPatient: Scalars['Boolean'];
  priorityTypeOnTabletReceipt: PriorityTypeOnTabletReceipt;
  /** QR 현재 접수중 여부 */
  qrReceiptOpen?: Maybe<Scalars['Boolean']>;
  receptionAvailable: Scalars['Boolean'];
  sendWaitingMinuteAlarmNumber: Scalars['Int'];
  sendWaitingOrderAlarmNumber: Scalars['Int'];
  tabletAddressInputType: TabletAddressInputEnum;
  tabletAddressInputUse?: Maybe<TabletAddressInputAccept>;
  tabletDetailAddressInputUse: Scalars['Boolean'];
  tabletReceiptNoticeContent?: Maybe<Scalars['String']>;
  tabletReceiptNoticeExpose: Scalars['Boolean'];
  treatmentAlarmUse: Scalars['Boolean'];
  treatmentCompleteAlarmUse: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 초진접수시 내원경로 입력받는 기능 on/off */
  useFunnel: Scalars['Boolean'];
  /** 접수 통계 알림톡 전송 기능 on/off */
  useReceiptStatisticsAlimtalk: Scalars['Boolean'];
  waitingMinuteAlarmUse: Scalars['Boolean'];
  waitingOrderAlarmUse: Scalars['Boolean'];
  waitingOrderUse: Scalars['Boolean'];
  waitingPatientTime?: Maybe<Scalars['Int']>;
};

export type ReceiptIdentityVerificationInput = {
  /** 이름 */
  name: Scalars['String'];
  /** 휴대폰 번호 */
  phone: Scalars['String'];
  userId: Scalars['ID'];
  /** 생년월일성별 */
  yymmddi: Scalars['String'];
};

export type ReceiptOfToday = {
  __typename?: 'ReceiptOfToday';
  chartReceiptId?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  endTimeOfReceiptForWaitingMinutes?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
  failReason?: Maybe<Scalars['String']>;
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** queue를 통한 접수 */
  isCreatedByQueue: Scalars['Boolean'];
  /** deQueue executed */
  isDequeueExecuted: Scalars['Boolean'];
  jobId?: Maybe<Scalars['String']>;
  patientFirstReceipt?: Maybe<Scalars['Boolean']>;
  receipt?: Maybe<Receipt>;
  receiptId?: Maybe<Scalars['String']>;
  receiptPatient?: Maybe<ReceiptPatient>;
  receiptPatientId?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatusEnum;
  receiptTimeOfDay: ReceiptTimeOfDay;
  receiptedAt?: Maybe<Scalars['DateTime']>;
  /** 연관 리뷰 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  roomId?: Maybe<Scalars['String']>;
  sourceType: ReceiptSourceTypeEnum;
  startTimeOfReceiptForWaitingMinutes?: Maybe<Scalars['DateTime']>;
  treatmentItemId?: Maybe<Scalars['String']>;
  treatmentName?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  userReceiptsOfToday?: Maybe<Array<Maybe<UserReceiptOfToday>>>;
  waitingMinuteUpdatedAt?: Maybe<Scalars['DateTime']>;
  waitingMinutes?: Maybe<Scalars['Int']>;
  waitingNumber?: Maybe<Scalars['Int']>;
  waitingNumberUpdatedAt?: Maybe<Scalars['DateTime']>;
  waitingOrderInQueue?: Maybe<Scalars['Int']>;
};

export type ReceiptPatient = {
  __typename?: 'ReceiptPatient';
  address?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  patients?: Maybe<Array<Maybe<Patient>>>;
  phone?: Maybe<Scalars['String']>;
  receipts: Array<Receipt>;
  residentRegistrationNumber?: Maybe<Scalars['String']>;
  sexdstn?: Maybe<Scalars['Int']>;
  sourceType?: Maybe<ReceiptPatientSourceTypeEnum>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type ReceiptPatientObject = {
  __typename?: 'ReceiptPatientObject';
  address?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  sexdstn?: Maybe<Scalars['Int']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum ReceiptPatientSourceTypeEnum {
  Hospital = 'hospital',
  Receipt = 'receipt'
}

export type ReceiptQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  hospitalName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  receiptPatientId?: InputMaybe<Scalars['ID']>;
  receiptPatientName?: InputMaybe<Scalars['String']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  /** 접수 신청 경로 유형 */
  sourceTypes?: InputMaybe<Array<ReceiptSourceTypeEnum>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 접수 타입) C01, C03, C04, C07, F03, F05 */
  statuses?: InputMaybe<Array<ReceiptStatusEnum>>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type ReceiptRoom = {
  __typename?: 'ReceiptRoom';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  departmentCode?: Maybe<Scalars['String']>;
  departmentName?: Maybe<Scalars['String']>;
  doctorCode?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  roomCode: Scalars['String'];
  roomName: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 접수 대기시간 마지막 업데이트 시각 */
  waitingMinuteUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 대기 시간(단위 : 분) */
  waitingMinutes?: Maybe<Scalars['Int']>;
  waitingNumber: Scalars['Int'];
};

export type ReceiptServiceAlliance = {
  __typename?: 'ReceiptServiceAlliance';
  /** 태블릿 접수 신청 */
  acceptTabletReceipt: Scalars['Boolean'];
  /** 설치 전 이탈일(UTC) */
  bouncedAt?: Maybe<Scalars['DateTime']>;
  businessChannel?: Maybe<BusinessChannelEnum>;
  businessChannelId?: Maybe<Scalars['String']>;
  businessManagerOrHospitalName?: Maybe<Scalars['String']>;
  chart?: Maybe<ReceiptChart>;
  chartId?: Maybe<Scalars['ID']>;
  chartLink?: Maybe<Scalars['Boolean']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 병원 정보 */
  hospital?: Maybe<Hospital>;
  hospitalAddress?: Maybe<Scalars['String']>;
  hospitalId: Scalars['ID'];
  hospitalName?: Maybe<Scalars['String']>;
  hospitalResponsibilityEmail?: Maybe<Scalars['String']>;
  hospitalResponsibilityName?: Maybe<Scalars['String']>;
  hospitalResponsibilityPhone?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  inquiry?: Maybe<Scalars['String']>;
  installationMethod: HospitalReceiptRequestInstallStatusEnum;
  /** 설치일 YYYY-MM-DD HH:mm:ss */
  installedAt?: Maybe<Scalars['DateTime']>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  nhisCode?: Maybe<Scalars['String']>;
  otherBusinessChannelName?: Maybe<Scalars['String']>;
  otherChartName?: Maybe<Scalars['String']>;
  /** 신청일 YYYY-MM-DD HH:mm:ss */
  requestedAt?: Maybe<Scalars['DateTime']>;
  status: HospitalReceiptStatusEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 철수 요청일(UTC) */
  withdrawalRequestedAt?: Maybe<Scalars['DateTime']>;
  /** 철회시간 YYYY-MM-DD HH:mm:ss */
  withdrawaledAt?: Maybe<Scalars['DateTime']>;
};

export type ReceiptSingleRecordQueryInput = {
  receiptId?: InputMaybe<Scalars['ID']>;
  receiptOfTodayId?: InputMaybe<Scalars['ID']>;
};

export enum ReceiptSoftwareDeviceTypeEnum {
  Bridge = 'bridge',
  Nonlink = 'nonlink',
  Tablet = 'tablet'
}

export type ReceiptSoftwareVersion = {
  __typename?: 'ReceiptSoftwareVersion';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  deviceType?: Maybe<ReceiptSoftwareDeviceTypeEnum>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  version?: Maybe<Scalars['String']>;
};

export enum ReceiptSourceTypeEnum {
  Appointment = 'appointment',
  Mobile = 'mobile',
  Qr = 'qr',
  Tablet = 'tablet'
}

export enum ReceiptStatusEnum {
  C00 = 'C00',
  C01 = 'C01',
  C02 = 'C02',
  C03 = 'C03',
  C04 = 'C04',
  C06 = 'C06',
  C07 = 'C07',
  C08 = 'C08',
  F02 = 'F02',
  F03 = 'F03',
  F05 = 'F05'
}

export type ReceiptStatusObject = {
  __typename?: 'ReceiptStatusObject';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital?: Maybe<Hospital>;
  /** pk */
  id: Scalars['ID'];
  /** 환자 이름 */
  patient: ReceiptPatientObject;
  receiptStatus: ReceiptStatusEnum;
  roomName?: Maybe<Scalars['String']>;
  treatmentName?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  waitingMinuteUpdatedAt?: Maybe<Scalars['DateTime']>;
  waitingMinutes?: Maybe<Scalars['Int']>;
  waitingNumber?: Maybe<Scalars['Int']>;
  waitingNumberUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ReceiptTimeOfDay {
  Afternoon = 'afternoon',
  Morning = 'morning'
}

export type ReceiptTreatmentItem = {
  __typename?: 'ReceiptTreatmentItem';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableSymptomMultipleResponse: Scalars['Boolean'];
  hospital?: Maybe<Hospital>;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  isAutoCreated: Scalars['Boolean'];
  name: Scalars['String'];
  receiptTreatmentItemMedicalQuestionnaires?: Maybe<
    Array<Maybe<ReceiptTreatmentItemMedicalQuestionnaire>>
  >;
  receiptTreatmentItemMobileConfiguration?: Maybe<ReceiptTreatmentItemMobileConfiguration>;
  room?: Maybe<ReceiptRoom>;
  roomId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  symptomQuestion?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Array<Scalars['String']>>;
  tabletAcceptReceipt: Scalars['Boolean'];
  tabletUseSymptom: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ReceiptTreatmentItemMedicalQuestionnaire = {
  __typename?: 'ReceiptTreatmentItemMedicalQuestionnaire';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 문진과 매핑할 내원 목적 */
  mappingSymptom: Scalars['String'];
  medicalQuestionnaire: MedicalQuestionnaire;
  medicalQuestionnaireId?: Maybe<Scalars['ID']>;
  medicalQuestionnaireName?: Maybe<Scalars['String']>;
  receiptTreatmentItem: ReceiptTreatmentItem;
  receiptTreatmentItemId?: Maybe<Scalars['ID']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type ReceiptTreatmentItemMobileConfiguration = {
  __typename?: 'ReceiptTreatmentItemMobileConfiguration';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 모바일 접수 가능 여부 */
  mobileReceiptAvailable: Scalars['Boolean'];
  /** 모바일 접수 가능 요일 */
  mobileReceiptAvailableOperationTypes?: Maybe<
    Array<Maybe<ReceiptTreatmentItemMobileOperationTypeEnum>>
  >;
  /** 모바일 접수 불가 요일 */
  mobileReceiptUnavailableOperationTypes?: Maybe<
    Array<Maybe<ReceiptTreatmentItemMobileOperationTypeEnum>>
  >;
  /** 접수(진료)항목 */
  receiptTreatmentItem: ReceiptTreatmentItem;
  /** 접수(진료)항목 ID */
  receiptTreatmentItemId: Scalars['ID'];
  /** 운영 시간 설정 */
  receiptTreatmentItemMobileOperationTimes?: Maybe<
    Array<Maybe<ReceiptTreatmentItemMobileOperationTime>>
  >;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ReceiptTreatmentItemMobileOperationTime = {
  __typename?: 'ReceiptTreatmentItemMobileOperationTime';
  /** 오후 접수 제한 수 */
  afternoonReceiptLimit: Scalars['Int'];
  /** 오후 접수 제한 없음 여부 */
  afternoonReceiptUnlimited: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 병원 오픈 시간 전 자동 시작 */
  minuteBeforeFirstMobileReceipt: Scalars['Int'];
  /** 병원 종료 시간 전 자동 마감 */
  minuteBeforeLastMobileReceipt: Scalars['Int'];
  /** 모바일 접수 가능 여부 */
  mobileReceiptAvailable: Scalars['Boolean'];
  /** 오전 접수 제한 수 */
  morningReceiptLimit: Scalars['Int'];
  /** 오전 접수 제한 없음 여부 */
  morningReceiptUnlimited: Scalars['Boolean'];
  /** 운영 시간 타입 */
  operationType: ReceiptTreatmentItemMobileOperationTypeEnum;
  /** 접수(진료)항목 ID */
  receiptTreatmentItemId: Scalars['ID'];
  receiptTreatmentItemMobileConfiguration: ReceiptTreatmentItemMobileConfiguration;
  receiptTreatmentItemMobileOperationTimeSlots: Array<ReceiptTreatmentItemMobileOperationTimeSlot>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ReceiptTreatmentItemMobileOperationTimeQueryInput = {
  hospitalId: Scalars['ID'];
  receiptTreatmentItemIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 접수 항목 사용 여부 */
  tabletAcceptReceipt?: InputMaybe<Scalars['Boolean']>;
};

export type ReceiptTreatmentItemMobileOperationTimeSlot = {
  __typename?: 'ReceiptTreatmentItemMobileOperationTimeSlot';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 적용 날짜(KST) YYYY-MM-DD */
  date?: Maybe<Scalars['String']>;
  /** 시작 시각(KST) HH:mm:ss */
  from: Scalars['String'];
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 특이사항 */
  name?: Maybe<Scalars['String']>;
  /** 운영 시간 타입 */
  operationType: ReceiptTreatmentItemMobileOperationTypeEnum;
  /** 접수 제한 수 */
  receiptLimit: Scalars['Int'];
  /** 접수(진료)항목 ID */
  receiptTreatmentItemId: Scalars['ID'];
  /** 운영 시간 설정 */
  receiptTreatmentItemMobileOperationTime: ReceiptTreatmentItemMobileOperationTime;
  /** 접수(진료)항목 운영 시간 설정 ID */
  receiptTreatmentItemMobileOperationTimeId: Scalars['ID'];
  /** 접수 제한 없음 여부 */
  receiptUnlimited: Scalars['Boolean'];
  /** 종료 시각(KST) HH:mm:ss */
  to: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum ReceiptTreatmentItemMobileOperationTypeEnum {
  Custom = 'custom',
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type ReceiptTreatmentItemQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 접수 항목 사용 여부 */
  tabletAcceptReceipt?: InputMaybe<Scalars['Boolean']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export enum ReceiptWaitingTypeEnum {
  /** 시간 */
  Minute = 'minute',
  /** 없음 */
  None = 'none',
  /** 인원 */
  Person = 'person'
}

export type RecentTreatedHospitalObject = {
  __typename?: 'RecentTreatedHospitalObject';
  hospital: HospitalObject;
  treatedAt: Scalars['DateTime'];
};

export type RecentTreatment = {
  __typename?: 'RecentTreatment';
  treatment?: Maybe<Treatment>;
};

export type RefreshTokenObject = {
  __typename?: 'RefreshTokenObject';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshTokenExpiredAt: Scalars['DateTime'];
};

export type RequestIdentityVerificationInput = {
  mobileCarrier?: InputMaybe<MobileCarrierEnum>;
  /** 이름 */
  name: Scalars['String'];
  /** 휴대폰 번호 */
  phone: Scalars['String'];
  /** 생년월일성별 */
  yymmddi: Scalars['String'];
};

export type RequestIdentityVerificationOutput = {
  __typename?: 'RequestIdentityVerificationOutput';
  identityVerificationId: Scalars['ID'];
};

export type RequestPrescriptionFeeInput = {
  /** 예상 방문 수령 시간(YYYY-MM-DD HH:mm:ss) */
  estimateVisitTime: Scalars['DateTime'];
  /** 유저 요청 사항 */
  patientRequests?: InputMaybe<Scalars['String']>;
  /** 계좌주 */
  pharmacyAccountHolder?: InputMaybe<Scalars['String']>;
  /** 약국주소 */
  pharmacyAddress: Scalars['String'];
  /** 계좌번호 */
  pharmacyBankAccount?: InputMaybe<Scalars['String']>;
  /** 은행명 */
  pharmacyBankName?: InputMaybe<Scalars['String']>;
  /** 약국코드 */
  pharmacyCode: Scalars['String'];
  /** 약국명 */
  pharmacyName: Scalars['String'];
  /** 약국전화번호 */
  pharmacyPhone: Scalars['String'];
  /** 약 수령 타입 */
  receiptType: PrescriptionReceiptTypeEnum;
  /** 대리수령자 이름 */
  substituteName?: InputMaybe<Scalars['String']>;
  /** 대리수령자 연락처 */
  substitutePhone?: InputMaybe<Scalars['String']>;
  /** 환자와 대리수령자의 관계 */
  substituteRelation?: InputMaybe<Scalars['String']>;
  treatmentId: Scalars['ID'];
};

export type RequestPrescriptionFeeOutput = {
  __typename?: 'RequestPrescriptionFeeOutput';
  code: Scalars['Float'];
  message: Scalars['String'];
  prescriptionCode: Scalars['String'];
};

export type RequestVisitPharmacyInput = {
  treatmentId: Scalars['ID'];
};

export type RequestVisitPharmacyOutput = {
  __typename?: 'RequestVisitPharmacyOutput';
  code: Scalars['Float'];
  message: Scalars['String'];
  prescriptionCode: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  appointment?: Maybe<Appointment>;
  /** 연계 예약 ID */
  appointmentId?: Maybe<Scalars['ID']>;
  /** 후기글 */
  contents?: Maybe<Scalars['String']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['Int']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 진료결과 */
  diagnosisScore?: Maybe<Scalars['Int']>;
  doctor?: Maybe<Doctor>;
  doctorId?: Maybe<Scalars['ID']>;
  /** 연계 이벤트 ID */
  eventId?: Maybe<Scalars['ID']>;
  /** 사진유무 */
  existImages?: Maybe<Scalars['Boolean']>;
  /** 시설/청결도 */
  facilityScore?: Maybe<Scalars['Int']>;
  /** 노출 off 일시 */
  hiddenAt?: Maybe<Scalars['DateTime']>;
  hospital?: Maybe<Hospital>;
  /** 연계 병원 ID */
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  /** 사진 */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  isUserLikesReview?: Maybe<Scalars['Boolean']>;
  /** 인증/비인증 */
  isVisited?: Maybe<Scalars['Boolean']>;
  /** 친절도 */
  kindnessScore?: Maybe<Scalars['Int']>;
  likeUsers?: Maybe<Array<Maybe<User>>>;
  /**
   * 좋아요 개수
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  likesCount?: Maybe<Scalars['Int']>;
  pharmacy?: Maybe<Pharmacy>;
  /** 연계 약국 ID */
  pharmacyId?: Maybe<Scalars['ID']>;
  /** 평점 */
  rating?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Receipt>;
  /** 연계 접수 ID */
  receiptId?: Maybe<Scalars['ID']>;
  receiptOfToday?: Maybe<ReceiptOfToday>;
  /** 연계 오늘의 접수 ID */
  receiptOfTodayId?: Maybe<Scalars['ID']>;
  /** 재방문여부 */
  recommendation?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<Maybe<ReviewReport>>>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  reviewLikeCount?: Maybe<Scalars['Int']>;
  reviewLikes?: Maybe<Array<Maybe<ReviewLike>>>;
  reviewReplies?: Maybe<Array<Maybe<ReviewReply>>>;
  /**
   * 병원, 약국, 클리닉시술, 이벤트, 비대면 리뷰로 구분
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  reviewType?: Maybe<ReviewTypeEnum>;
  /** 리뷰 작성 경로 */
  source?: Maybe<ReviewSourceEnum>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  targetId?: Maybe<Scalars['ID']>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  targetType?: Maybe<ReviewTypeEnum>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  treatmentId?: Maybe<Scalars['ID']>;
  /** 진료항목 */
  treatmentName?: Maybe<Scalars['String']>;
  /** 진료비용 */
  treatmentPrice?: Maybe<Scalars['Int']>;
  untactTreatment?: Maybe<Treatment>;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  /**
   * 작성자이메일
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  /** 작성자 */
  userName: Scalars['String'];
  /** 리뷰 노출 */
  visibility: Scalars['Boolean'];
  /** 방문일 */
  visitedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewLike = {
  __typename?: 'ReviewLike';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  review: Review;
  reviewId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** DEPRECATED Review 사용 */
export type ReviewObject = {
  __typename?: 'ReviewObject';
  appointment?: Maybe<Appointment>;
  /** 연계 예약 ID */
  appointmentId?: Maybe<Scalars['ID']>;
  /** 후기글 */
  contents?: Maybe<Scalars['String']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['Int']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 진료결과 */
  diagnosisScore?: Maybe<Scalars['Int']>;
  doctor?: Maybe<Doctor>;
  doctorId?: Maybe<Scalars['ID']>;
  /** 연계 이벤트 ID */
  eventId?: Maybe<Scalars['ID']>;
  /** 사진유무 */
  existImages?: Maybe<Scalars['Boolean']>;
  /** 시설/청결도 */
  facilityScore?: Maybe<Scalars['Int']>;
  /** 노출 off 일시 */
  hiddenAt?: Maybe<Scalars['DateTime']>;
  hospital?: Maybe<Hospital>;
  /** 연계 병원 ID */
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  /** 사진 */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  isUserLikesReview?: Maybe<Scalars['Boolean']>;
  /** 인증/비인증 */
  isVisited?: Maybe<Scalars['Boolean']>;
  /** 친절도 */
  kindnessScore?: Maybe<Scalars['Int']>;
  likeUsers?: Maybe<Array<Maybe<User>>>;
  /**
   * 좋아요 개수
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  likesCount?: Maybe<Scalars['Int']>;
  pharmacy?: Maybe<Pharmacy>;
  /** 연계 약국 ID */
  pharmacyId?: Maybe<Scalars['ID']>;
  /** 평점 */
  rating?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Receipt>;
  /** 연계 접수 ID */
  receiptId?: Maybe<Scalars['ID']>;
  receiptOfToday?: Maybe<ReceiptOfToday>;
  /** 연계 오늘의 접수 ID */
  receiptOfTodayId?: Maybe<Scalars['ID']>;
  /** 재방문여부 */
  recommendation?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<Maybe<ReviewReport>>>;
  /** virtual field 리뷰 좋아요 카운트 정보 */
  reviewLikeCount?: Maybe<Scalars['Int']>;
  reviewLikes?: Maybe<Array<Maybe<ReviewLike>>>;
  reviewReplies?: Maybe<Array<Maybe<ReviewReply>>>;
  /**
   * 병원, 약국, 클리닉시술, 이벤트, 비대면 리뷰로 구분
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  reviewType?: Maybe<ReviewTypeEnum>;
  /** 리뷰 작성 경로 */
  source?: Maybe<ReviewSourceEnum>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  targetId?: Maybe<Scalars['ID']>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  targetType?: Maybe<ReviewTypeEnum>;
  /** @deprecated 강제 업데이트 이후 삭제 예정 */
  treatmentId?: Maybe<Scalars['ID']>;
  /** 진료항목 */
  treatmentName?: Maybe<Scalars['String']>;
  /** 진료비용 */
  treatmentPrice?: Maybe<Scalars['Int']>;
  untactTreatment?: Maybe<Treatment>;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: Maybe<Scalars['ID']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  /**
   * 작성자이메일
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  /** 작성자 */
  userName: Scalars['String'];
  /** 리뷰 노출 */
  visibility: Scalars['Boolean'];
  /** 방문일 */
  visitedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewQueryInput = {
  contents?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  existImages?: InputMaybe<Scalars['Boolean']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  hospitalQuery?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  pharmacyId?: InputMaybe<Scalars['ID']>;
  pharmacyQuery?: InputMaybe<Scalars['String']>;
  realName?: InputMaybe<Scalars['String']>;
  reviewTypes?: InputMaybe<Array<ReviewTypeEnum>>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: ReviewSortEnum;
  sources?: InputMaybe<Array<ReviewSourceEnum>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['ID']>;
  targetName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
  userName?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewReply = {
  __typename?: 'ReviewReply';
  /** 답글 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  review?: Maybe<Review>;
  /** 리뷰 ID */
  reviewId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type ReviewReport = {
  __typename?: 'ReviewReport';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  review?: Maybe<Review>;
  reviewId: Scalars['ID'];
  /** 신고 상태 */
  status: ReviewReportStatusEnum;
  /** obscenity:음란성, abuse:욕설/영업방해, infringement:개인정보/저작권 침해, etc:기타 부적절한 내용 */
  type: ReviewReportTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export enum ReviewReportStatusEnum {
  Complete = 'complete',
  Request = 'request'
}

export enum ReviewReportTypeEnum {
  Abuse = 'abuse',
  Etc = 'etc',
  Infringement = 'infringement',
  Obscenity = 'obscenity'
}

export enum ReviewSortEnum {
  Recent = 'recent',
  Recommendation = 'recommendation'
}

export enum ReviewSourceEnum {
  Appointment = 'appointment',
  Event = 'event',
  HospitalDetail = 'hospitalDetail',
  NewEvent = 'newEvent',
  PharmacyDetail = 'pharmacyDetail',
  Receipt = 'receipt',
  UntactTreatment = 'untactTreatment'
}

export type ReviewSummary = {
  __typename?: 'ReviewSummary';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 진료결과 점수 Count List */
  diagnosisScoresCounts: Array<Scalars['Int']>;
  /** 시설/청결도 점수 Count List */
  facilityScoresCounts: Array<Scalars['Int']>;
  /** pk */
  id: Scalars['ID'];
  /** 친절도 점수 Count List */
  kindnessScoresCounts: Array<Scalars['Int']>;
  /** 평점 Count List */
  ratingCounts: Array<Scalars['Int']>;
  /** 평점 평균 */
  ratingMean: Scalars['Float'];
  /**
   * 병원, 약국, 클리닉시술, 이벤트, 비대면 리뷰로 구분
   * @deprecated 강제 업데이트 이후 삭제 예정
   */
  reviewType?: Maybe<ReviewTypeEnum>;
  /** 리뷰 카운트 */
  reviewsCount: Scalars['Int'];
  /** 재방문 카운트 */
  revisitCount: Scalars['Int'];
  /** 재방문율 */
  revisitRate: Scalars['Float'];
  /** 대상 도메인 (병원 / 약국) */
  summaryTarget: ReviewSummaryTargetEnum;
  targetId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum ReviewSummaryTargetEnum {
  Doctor = 'doctor',
  Event = 'event',
  Hospital = 'hospital',
  Pharmacy = 'pharmacy'
}

export enum ReviewTypeEnum {
  Event = 'event',
  Hospital = 'hospital',
  Pharmacy = 'pharmacy',
  Tablet = 'tablet',
  UntactDoctor = 'untactDoctor'
}

export enum RewardStatusTypeEnum {
  Complete = 'complete',
  Notyet = 'notyet',
  Unknown = 'unknown'
}

export type S3Output = {
  __typename?: 'S3Output';
  /** s3 download url */
  downloadUrl: Scalars['String'];
  /** 파일명 */
  fileName: Scalars['String'];
  /** s3 upload presigned url */
  presignedUrl: Scalars['String'];
  /** s3 download url */
  uploadExpiredAt: Scalars['DateTime'];
};

export type School = {
  __typename?: 'School';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctorEducations?: Maybe<Array<Maybe<DoctorEducation>>>;
  /** pk */
  id: Scalars['ID'];
  /** 한국 학교 */
  isDomestic: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

/** 통합검색 관련 정보 */
export type SearchAllOutput = {
  __typename?: 'SearchAllOutput';
  /** 키워드에 따른 정렬 */
  sort: Scalars['String'];
  /** 키워드에 따른 타겟 탭 */
  tab: Scalars['String'];
};

export enum SearchTypeEnum {
  All = 'all',
  Event = 'event',
  Hospital = 'hospital',
  Pharmacy = 'pharmacy'
}

export type SearchV2HomeHospitalCalculateOperation = {
  __typename?: 'SearchV2HomeHospitalCalculateOperation';
  /** 영업 종료시간 */
  close?: Maybe<Scalars['String']>;
  /** 영업 오픈시간 */
  open?: Maybe<Scalars['String']>;
};

export type SearchV2HomeHospitalObject = {
  __typename?: 'SearchV2HomeHospitalObject';
  address?: Maybe<Scalars['String']>;
  aliasName?: Maybe<Scalars['String']>;
  /** 예약 분류 */
  appointmentType?: Maybe<Scalars['String']>;
  aroundStationInformation?: Maybe<Scalars['String']>;
  avgWaitingTime?: Maybe<Scalars['Int']>;
  /** 진료시간 */
  calculatedOperation: SearchV2HomeHospitalCalculateOperation;
  /** 진료과목 */
  departments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 병원과의 거리(계산불가시 -1)m */
  distance?: Maybe<Scalars['Int']>;
  emergencyAvailability?: Maybe<Scalars['Boolean']>;
  /** 예약 가능 여부 */
  enableAppointment?: Maybe<Scalars['Boolean']>;
  /** 모바일 접수 가능 여부 */
  enableMobileReceipt?: Maybe<Scalars['Boolean']>;
  enableReceiptWaitingCounter?: Maybe<Scalars['Boolean']>;
  /** 비대면 진료 가능 여부 */
  enableUntactTreatment?: Maybe<Scalars['Boolean']>;
  establishmentDivision?: Maybe<EstablishmentDivisionEnum>;
  hiraCode?: Maybe<Scalars['String']>;
  hiraHospitalName?: Maybe<Scalars['String']>;
  homepageUrl?: Maybe<Scalars['String']>;
  /**  병원 주소 정보  */
  hospitalAddress?: Maybe<HospitalAddress>;
  /** 병원 구분 (심평원) */
  hospitalDivision?: Maybe<Scalars['String']>;
  hospitalDivisionCode?: Maybe<Scalars['String']>;
  hospitalPictures?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  introductionContent?: Maybe<Scalars['String']>;
  introductionSubTitle?: Maybe<Scalars['String']>;
  introductionTitle?: Maybe<Scalars['String']>;
  /** 인기 예약 여부 */
  isFamousAppointment?: Maybe<Scalars['Boolean']>;
  /** 굿닥병원 여부(접수/예약/비대면진료) */
  isGoodocHospital?: Maybe<Scalars['Boolean']>;
  /** 모바일 접수 실시간 가능 여부(브릿지연결 여부) */
  isMobileReceiptAvailable?: Maybe<Scalars['Boolean']>;
  isReviewNotification?: Maybe<Scalars['Boolean']>;
  /** 유저 즐겨찾기 여부 */
  isUserHospitalLike?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  /** 진료실 중 최대 접수 대기자 수(불가능병원: -1) */
  maxReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 진료실 중 최소 접수 대기자 수 */
  minReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 운영시간에 따른 모바일 접수 가능 여부 */
  mobileReceiptOpen?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nhisCode?: Maybe<Scalars['String']>;
  nmcCode?: Maybe<Scalars['String']>;
  noWaitingTime?: Maybe<Scalars['String']>;
  /** 병원 운영시간에 따른 상태 */
  openClosedStatus?: Maybe<HospitalOpenClosedStatusEnum>;
  openedAt?: Maybe<Scalars['DateTime']>;
  openedDate?: Maybe<Scalars['String']>;
  operatingState?: Maybe<HospitalOperatingStateEnum>;
  operationTimeDescription?: Maybe<Scalars['String']>;
  parkingAvailability?: Maybe<Scalars['Boolean']>;
  parkingDescription?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** 접수 가능 진료실 수 */
  receiptOpenedRoomCount?: Maybe<Scalars['Int']>;
  /** 접수 대기자 수(불가능병원: -1) */
  receiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 접수 대기 타입(시간/사람수) */
  receiptWaitingType?: Maybe<ReceiptWaitingTypeEnum>;
  /** 태블릿 접수 가능 여부 */
  receptionAvailable?: Maybe<Scalars['Boolean']>;
  /** 대표 진료 과목 */
  representativeDepartments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 리뷰 갯수 */
  reviewCount?: Maybe<Scalars['Int']>;
  /** 리뷰 평점 평균 */
  reviewRating?: Maybe<Scalars['Float']>;
  reviewVisibility?: Maybe<Scalars['Boolean']>;
  safetyPhone?: Maybe<Scalars['String']>;
  signClinicMarketContract?: Maybe<Scalars['Boolean']>;
  simplyIntroduction?: Maybe<Scalars['String']>;
  /** 당일 예약 가능 여부 */
  unableSameDayAppointment?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type SearchedKeyword = {
  __typename?: 'SearchedKeyword';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  keyword: Scalars['String'];
  /** 병원, 약국, 이벤트로 구분 */
  searchedKeywordType: SearchedKeywordTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  /** 매칭된 유저 아이디 */
  userId: Scalars['ID'];
};

export enum SearchedKeywordTypeEnum {
  All = 'all',
  Event = 'event',
  Hospital = 'hospital',
  Pharmacy = 'pharmacy'
}

export type SignInDeviceInput = {
  appVersion: Scalars['String'];
  deviceId: Scalars['String'];
  fcmToken?: InputMaybe<Scalars['String']>;
  os: DeviceOsEnum;
  uuid: Scalars['String'];
};

export type SignInDeviceResult = {
  __typename?: 'SignInDeviceResult';
  token: Scalars['String'];
};

export type SignInUserResult = {
  __typename?: 'SignInUserResult';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshTokenExpiredAt: Scalars['DateTime'];
  token: Scalars['String'];
  withSignUp: Scalars['Boolean'];
};

export enum SimpleAuthEnum {
  Kakaotalk = 'KAKAOTALK',
  KbMobile = 'KB_MOBILE',
  Naver = 'NAVER',
  Pass = 'PASS',
  Payco = 'PAYCO',
  SamsungPass = 'SAMSUNG_PASS',
  SinhanCerti = 'SINHAN_CERTI',
  Toss = 'TOSS'
}

export type SmartTvCodeObject = {
  __typename?: 'SmartTVCodeObject';
  /** code */
  code: Scalars['String'];
  /** token */
  token: Scalars['String'];
};

export type SocialAuth = {
  __typename?: 'SocialAuth';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  provider: SocialAuthProviderEnum;
  refreshToken?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  userId?: Maybe<Scalars['ID']>;
};

export enum SocialAuthProviderEnum {
  Apple = 'apple',
  Facebook = 'facebook',
  Kakao = 'kakao',
  Naver = 'naver'
}

export type SocialSignInInput = {
  authorizationCode?: InputMaybe<Scalars['String']>;
  marketingConsentAgreement?: InputMaybe<Scalars['Boolean']>;
  marketingPushConsentAgreement?: InputMaybe<Scalars['Boolean']>;
  marketingSmsLmsConsentAgreement?: InputMaybe<Scalars['Boolean']>;
  /** kakao, apple, naver, facebook */
  provider: SocialAuthProviderEnum;
  token: Scalars['String'];
  uid: Scalars['String'];
};

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpecialistDepartment = {
  __typename?: 'SpecialistDepartment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  doctorEducations?: Maybe<Array<Maybe<DoctorEducation>>>;
  /** pk */
  id: Scalars['ID'];
  /** 전문의 과목명 */
  name: Scalars['String'];
  /** 정렬 순서 */
  sort: Scalars['Int'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type SuccessObject = {
  __typename?: 'SuccessObject';
  success: Scalars['Boolean'];
};

export type SuccessReasonObject = {
  __typename?: 'SuccessReasonObject';
  /** 유저에게 보여줄 실패 사유 내용 */
  reason?: Maybe<Scalars['String']>;
  /** 실패시 유저를 이동시킬 appLink */
  redirectAppLink?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  /** 유저에게 보여줄 실패 사유 제목 */
  title?: Maybe<Scalars['String']>;
};

export enum TabletAddressInputEnum {
  Choice = 'choice',
  Search = 'search'
}

export type TermsOfService = {
  __typename?: 'TermsOfService';
  contents: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  sequence: Scalars['Int'];
  type: AgreementTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userTermsOfServices?: Maybe<Array<Maybe<UserTermsOfService>>>;
};

export type TermsOfServiceObject = {
  __typename?: 'TermsOfServiceObject';
  contents: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: AgreementTypeEnum;
};

export type Theme = {
  __typename?: 'Theme';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hospitalThemes: Array<HospitalTheme>;
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type Treatment = {
  __typename?: 'Treatment';
  callbackRequest: Scalars['Boolean'];
  cardName?: Maybe<Scalars['String']>;
  clientMethodId?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  department: UntactDepartment;
  departmentId: Scalars['ID'];
  doctor?: Maybe<Doctor>;
  doctorId?: Maybe<Scalars['ID']>;
  exceptionEligibleType?: Maybe<ExceptionEligibleTypeEnum>;
  /** 진료비 */
  fee?: Maybe<Scalars['Float']>;
  /** pk */
  id: Scalars['ID'];
  /** 진료가 보험인지 비보험인지 여부 */
  insuredType?: Maybe<InsuredTypeEnum>;
  /** 의사지정 진료 여부 */
  isDirect?: Maybe<Scalars['Boolean']>;
  /** 진료비 납부 여부 */
  isPaid?: Maybe<Scalars['Boolean']>;
  lastCardNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  matchingEndedAt?: Maybe<Scalars['DateTime']>;
  matchingStartedAt: Scalars['DateTime'];
  patient: Patient;
  patientId: Scalars['ID'];
  /** 결제 취소용 ID */
  paymentId?: Maybe<Scalars['ID']>;
  /** 결제 수단 종류 */
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>;
  /** 결제(수납) 상태 */
  paymentStatus?: Maybe<PaymentStatusEnum>;
  phone?: Maybe<Scalars['String']>;
  prescription?: Maybe<Prescription>;
  /** 처방전이 발급되는 진료인지 여부 */
  prescriptionState?: Maybe<PrescriptionStateEnum>;
  productType: ProductTypeEnum;
  reservedAt?: Maybe<Scalars['DateTime']>;
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  status: TreatmentStatusEnum;
  symptomDescription?: Maybe<Scalars['String']>;
  symptomImageS3Keys?: Maybe<Array<Scalars['String']>>;
  symptomImageUrls?: Maybe<Array<Scalars['String']>>;
  treatmentEndedAt?: Maybe<Scalars['DateTime']>;
  treatmentEvents?: Maybe<Array<TreatmentEvent>>;
  treatmentNote?: Maybe<Scalars['String']>;
  treatmentPayments?: Maybe<Array<TreatmentPayment>>;
  treatmentStartedAt?: Maybe<Scalars['DateTime']>;
  treatmentVideoCall?: Maybe<TreatmentVideoCall>;
  treatmentWaitingTime?: Maybe<Scalars['DateTime']>;
  untactQuestionnaireResponse?: Maybe<Array<Maybe<UntactQuestionnaireResponse>>>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  /** 유저의 본인인증 날짜  */
  userIdentityVerifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TreatmentEvent = {
  __typename?: 'TreatmentEvent';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  treatment: Treatment;
  treatmentId: Scalars['ID'];
  type: TreatmentEventTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum TreatmentEventTypeEnum {
  DoctorCancel = 'doctorCancel',
  PatientCancel = 'patientCancel'
}

export type TreatmentHistory = {
  __typename?: 'TreatmentHistory';
  /** 연계 예약 ID */
  appointmentId?: Maybe<Scalars['ID']>;
  /** 진료 시각 */
  doneDate: Scalars['DateTime'];
  /** 병원 정보 */
  hospital?: Maybe<Hospital>;
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** 연계 접수 ID */
  receiptId?: Maybe<Scalars['ID']>;
  /** 연관 리뷰 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  /** 진료 타입 */
  treatmentType: TreatmentHistoryEnum;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: Maybe<Scalars['ID']>;
  /** 유저 ID */
  userId: Scalars['ID'];
};

export enum TreatmentHistoryEnum {
  Appointment = 'appointment',
  Receipt = 'receipt',
  UntactTreatment = 'untactTreatment'
}

export type TreatmentHistoryQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
  /** 유저 ID */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 삭제된 진료 내역까지 포함 */
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type TreatmentInput = {
  /** untactDepartment */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** doctor */
  doctorId?: InputMaybe<Scalars['ID']>;
  exceptionEligibleType?: InputMaybe<ExceptionEligibleTypeEnum>;
  /** patient */
  patientId?: InputMaybe<Scalars['ID']>;
  /** user phone number */
  phone?: InputMaybe<Scalars['String']>;
  productType?: ProductTypeEnum;
  /** symptomDescription */
  symptomDescription?: InputMaybe<Scalars['String']>;
  /** symptomImageS3Keys */
  symptomImageS3Keys?: InputMaybe<Array<Scalars['String']>>;
};

export type TreatmentOnCreate = {
  __typename?: 'TreatmentOnCreate';
  callbackRequest: Scalars['Boolean'];
  cardName?: Maybe<Scalars['String']>;
  clientMethodId?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  department: UntactDepartment;
  departmentId: Scalars['ID'];
  doctor?: Maybe<Doctor>;
  doctorId?: Maybe<Scalars['ID']>;
  /** 진료비 */
  fee?: Maybe<Scalars['Float']>;
  /** pk */
  id: Scalars['ID'];
  /** 진료가 보험인지 비보험인지 여부 */
  insuredType?: Maybe<InsuredTypeEnum>;
  /** 의사지정 진료 여부 */
  isDirect?: Maybe<Scalars['Boolean']>;
  /** 진료비 납부 여부 */
  isPaid?: Maybe<Scalars['Boolean']>;
  lastCardNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  matchingEndedAt?: Maybe<Scalars['DateTime']>;
  matchingStartedAt: Scalars['DateTime'];
  patient: Patient;
  patientId: Scalars['ID'];
  /** 결제 취소용 ID */
  paymentId?: Maybe<Scalars['ID']>;
  /** 결제 수단 종류 */
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>;
  /** 결제(수납) 상태 */
  paymentStatus?: Maybe<PaymentStatusEnum>;
  phone?: Maybe<Scalars['String']>;
  prescription?: Maybe<Prescription>;
  /** 처방전이 발급되는 진료인지 여부 */
  prescriptionState?: Maybe<PrescriptionStateEnum>;
  productType: ProductTypeEnum;
  reservedAt?: Maybe<Scalars['DateTime']>;
  /** 연관 리뷰 리스트 */
  reviews?: Maybe<Array<Maybe<Review>>>;
  status: TreatmentStatusEnum;
  symptomDescription?: Maybe<Scalars['String']>;
  symptomImageS3Keys?: Maybe<Array<Scalars['String']>>;
  symptomImageUrls?: Maybe<Array<Scalars['String']>>;
  treatmentEndedAt?: Maybe<Scalars['DateTime']>;
  treatmentEvents?: Maybe<Array<TreatmentEvent>>;
  treatmentNote?: Maybe<Scalars['String']>;
  treatmentPayments?: Maybe<Array<TreatmentPayment>>;
  treatmentStartedAt?: Maybe<Scalars['DateTime']>;
  treatmentVideoCall?: Maybe<TreatmentVideoCall>;
  treatmentWaitingTime?: Maybe<Scalars['DateTime']>;
  untactQuestionnaireResponse?: Maybe<Array<Maybe<UntactQuestionnaireResponse>>>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type TreatmentPayment = {
  __typename?: 'TreatmentPayment';
  comment?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  paymentId?: Maybe<Scalars['ID']>;
  paymentMethodId: Scalars['ID'];
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>;
  paymentStatus?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  status?: Maybe<PaymentStatusEnum>;
  treatment: Treatment;
  treatmentId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum TreatmentStatusEnum {
  Cancel = 'cancel',
  Completion = 'completion',
  Endcall = 'endcall',
  Matchingfail = 'matchingfail',
  Ontreatment = 'ontreatment',
  Orderprescription = 'orderprescription',
  Request = 'request',
  Treatmenterror = 'treatmenterror',
  Waiting = 'waiting'
}

export type TreatmentVideoCall = {
  __typename?: 'TreatmentVideoCall';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctorJoinedAt?: Maybe<Scalars['DateTime']>;
  doctorLeftAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  matchedAt?: Maybe<Scalars['DateTime']>;
  patientJoinedAt?: Maybe<Scalars['DateTime']>;
  patientLeftAt?: Maybe<Scalars['DateTime']>;
  sessionId: Scalars['String'];
  status: VideoCallStatusEnum;
  treatment: Treatment;
  treatmentId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UberAdminUser = {
  __typename?: 'UberAdminUser';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  /** pk */
  id: Scalars['ID'];
  /** 마지막 비밀번호 변경 요청 시간 */
  lastPasswordChangeRequestedAt?: Maybe<Scalars['DateTime']>;
  /** 마지막 로그인 시간 */
  lastSignInAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  uberPermissionGroup?: Maybe<UberPermissionGroup>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UberPermission = {
  __typename?: 'UberPermission';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
  uberPermissionGroupUberPermissions: Array<UberPermissionGroupUberPermission>;
  uberPermissionType: UberPermissionTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UberPermissionGroup = {
  __typename?: 'UberPermissionGroup';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  uberAdminUsers: Array<UberAdminUser>;
  uberPermissionGroupUberPermissions: Array<UberPermissionGroupUberPermission>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UberPermissionGroupUberPermission = {
  __typename?: 'UberPermissionGroupUberPermission';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  uberPermission: UberPermission;
  uberPermissionGroup: UberPermissionGroup;
  uberPermissionGroupId: Scalars['ID'];
  uberPermissionId: Scalars['ID'];
};

export enum UberPermissionTypeEnum {
  AdminUserManaging = 'adminUserManaging',
  AdvertisementManaging = 'advertisementManaging',
  AppointmentCsManaging = 'appointmentCsManaging',
  AppointmentDetail = 'appointmentDetail',
  AppointmentDownload = 'appointmentDownload',
  AppointmentList = 'appointmentList',
  ClinicMarketBusinessHospitalDetail = 'clinicMarketBusinessHospitalDetail',
  ClinicMarketBusinessHospitalList = 'clinicMarketBusinessHospitalList',
  ClinicMarketServiceManaging = 'clinicMarketServiceManaging',
  GoodocNoticeBoardManaging = 'goodocNoticeBoardManaging',
  GoodocUserManaging = 'goodocUserManaging',
  HospitalAdminUserManagingList = 'hospitalAdminUserManagingList',
  HospitalAdminUserManagingUpdateDelete = 'hospitalAdminUserManagingUpdateDelete',
  HospitalManaging = 'hospitalManaging',
  HospitalsUsingService = 'hospitalsUsingService',
  NotificationBlockManaging = 'notificationBlockManaging',
  PatientManaging = 'patientManaging',
  PharmacyManaging = 'pharmacyManaging',
  ReceiptServiceManaging = 'receiptServiceManaging',
  ReviewManaging = 'reviewManaging',
  ReviewManagingDetail = 'reviewManagingDetail',
  ReviewManagingList = 'reviewManagingList',
  TabletServiceApplicationManagingDetail = 'tabletServiceApplicationManagingDetail',
  TabletServiceApplicationManagingList = 'tabletServiceApplicationManagingList',
  TabletServiceApplicationManagingUpdateDelete = 'tabletServiceApplicationManagingUpdateDelete',
  UntactHospitalManagingDetail = 'untactHospitalManagingDetail',
  UntactHospitalManagingList = 'untactHospitalManagingList',
  UntactHospitalManagingUpdateDelete = 'untactHospitalManagingUpdateDelete',
  UntactServiceApplicationManaging = 'untactServiceApplicationManaging',
  UntactServiceApplicationManagingDownload = 'untactServiceApplicationManagingDownload',
  UntactServiceApplicationManagingList = 'untactServiceApplicationManagingList',
  UntactServiceManaging = 'untactServiceManaging',
  UntactTreatmentManagingDetail = 'untactTreatmentManagingDetail',
  UntactTreatmentManagingList = 'untactTreatmentManagingList',
  UntactTreatmentManagingUpdateDelete = 'untactTreatmentManagingUpdateDelete'
}

/** 운영시간 요일 */
export type UniversalSearchDayObject = {
  __typename?: 'UniversalSearchDayObject';
  /** 금요일 */
  friday?: Maybe<Scalars['Int']>;
  /** 공휴일 */
  holiday?: Maybe<Scalars['Int']>;
  /** 월요일 */
  monday?: Maybe<Scalars['Int']>;
  /** 토요일 */
  saturday?: Maybe<Scalars['Int']>;
  /** 일요일 */
  sunday?: Maybe<Scalars['Int']>;
  /** 목요일 */
  thursday?: Maybe<Scalars['Int']>;
  /** 화요일 */
  tuesday?: Maybe<Scalars['Int']>;
  /** 수요일 */
  wednesday?: Maybe<Scalars['Int']>;
  /** 평일점심 */
  weekdayLunch?: Maybe<Scalars['Int']>;
  /** 주말점심 */
  weekendLunch?: Maybe<Scalars['Int']>;
};

/** 검색 의사 필터 */
export type UniversalSearchDoctorFilterInput = {
  /** 진료 과목 - 전문의 */
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 거리제한(단위 m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 응급진료 여부 - 병원 */
  emergencyCareAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 진료중 여부 - 병원 */
  enableTreatment?: InputMaybe<Scalars['Boolean']>;
  /** 여의사 여부 - 의료진 */
  femaleDoctorAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 야간진료 여부 - 병원 */
  nightCareAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 전문의 여부 - 의료진 */
  specialistAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 증상 - 비대면의사 */
  untactDepartments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 비대면 진료 여부 - 의료진 */
  untactVisibility?: InputMaybe<Scalars['Boolean']>;
  /** 주말진료 여부 - 병원 */
  weekendAvailability?: InputMaybe<Scalars['Boolean']>;
};

/** 통합 검색 의사 결과 */
export type UniversalSearchDoctorListObject = {
  __typename?: 'UniversalSearchDoctorListObject';
  /** 의사 총 검색결과 */
  doctorCount: Scalars['Int'];
  /** 의사 리스트 */
  doctors: Array<UniversalSearchDoctorObject>;
  /** 의사 검색엔진 쿼리 */
  esQuery?: Maybe<Scalars['String']>;
};

/** 통합 검색 의사 */
export type UniversalSearchDoctorObject = {
  __typename?: 'UniversalSearchDoctorObject';
  /** 거리(m) */
  distance?: Maybe<Scalars['Int']>;
  /** 병원 아이디 */
  doctorId?: Maybe<Scalars['ID']>;
  /** 성별 */
  gender?: Maybe<Scalars['String']>;
  /** 종료 영업시간(알수없음:0, 휴무:-1) */
  hospitalClose?: Maybe<UniversalSearchDayObject>;
  /** 병원 진료과목 */
  hospitalDepartments?: Maybe<Array<Scalars['String']>>;
  /** 병원 아이디 */
  hospitalId?: Maybe<Scalars['Int']>;
  /** 병원명 */
  hospitalName?: Maybe<Scalars['String']>;
  /** 시작 영업시간(알수없음:0, 휴무:-1) */
  hospitalOpen?: Maybe<UniversalSearchDayObject>;
  /** 병원 운영시간에 따른 상태 */
  hospitalOpenClosedStatus?: Maybe<HospitalOpenClosedStatusEnum>;
  /** 병원 태그 */
  hospitalTags?: Maybe<Array<Scalars['String']>>;
  /** 병원 주말진료 여부 */
  hospitalWeekendAvailability?: Maybe<Scalars['Boolean']>;
  /** 소개 상세 */
  introductionDetail?: Maybe<Scalars['String']>;
  /** 소개 요약 */
  introductionSummary?: Maybe<Scalars['String']>;
  /** 위치 */
  location?: Maybe<HospitalGeoPointObject>;
  /** 진료 분류(의원, 한의원, 치과의원) */
  medicalType?: Maybe<MedicalTypeEnum>;
  /** 의사명 */
  name?: Maybe<Scalars['String']>;
  /** 프로필 */
  profile?: Maybe<Scalars['String']>;
  /** 리뷰 개수 */
  reviewCount?: Maybe<Scalars['Int']>;
  /** 리뷰 평균 점수 */
  reviewRating?: Maybe<Scalars['Float']>;
  /** 전문의 */
  specialist?: Maybe<Array<Scalars['String']>>;
  /** 태그 */
  tags?: Maybe<Array<Scalars['String']>>;
  /** 이미지 URL */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** 증상 - 비대면의사 */
  untactDepartments?: Maybe<Array<Scalars['String']>>;
  /** 비대면 진료 현재 가능 여부 */
  untactOnTreatment?: Maybe<Scalars['Boolean']>;
  /** 비대면 진료 여부 */
  untactVisibility?: Maybe<Scalars['Boolean']>;
};

export enum UniversalSearchDoctorSortEnum {
  /** 거리순 */
  Location = 'location',
  /** 관련순 */
  Relevancy = 'relevancy',
  /** 리뷰많은순 */
  ReviewCount = 'reviewCount',
  /** 별점높은순 */
  ReviewRating = 'reviewRating'
}

/** 검색 시술 필터 */
export type UniversalSearchEventFilterInput = {
  /** 시술 카테고리 */
  categoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 거리제한(단위 m) */
  distance?: InputMaybe<Scalars['Int']>;
  /** 특가이벤트 여부 */
  enableDiscount?: InputMaybe<Scalars['Boolean']>;
};

/** 통합 검색 시술 결과 */
export type UniversalSearchEventListObject = {
  __typename?: 'UniversalSearchEventListObject';
  /** 시술 검색엔진 쿼리 */
  esQuery?: Maybe<Scalars['String']>;
  /** 시술 총 검색결과 */
  eventCount: Scalars['Int'];
  /** 시술 리스트 */
  events: Array<UniversalSearchEventObject>;
};

/** 통합 검색 시술 */
export type UniversalSearchEventObject = {
  __typename?: 'UniversalSearchEventObject';
  /** 진료 부서 및 과목(대,중,소분류) */
  categoryNames?: Maybe<Array<Scalars['String']>>;
  /** 클리닉이벤트 아이디 */
  clinicEventId?: Maybe<Scalars['ID']>;
  /** 클리닉 점수 */
  clinicScore?: Maybe<Scalars['Float']>;
  /** 시술 이벤트 제목 */
  displayName?: Maybe<Scalars['String']>;
  /** 거리(m) */
  distance?: Maybe<Scalars['Int']>;
  /** 병원 아이디 */
  hospitalId?: Maybe<Scalars['ID']>;
  /** 병원명 */
  hospitalName?: Maybe<Scalars['String']>;
  /** 위치 */
  location?: Maybe<HospitalGeoPointObject>;
  /** 시술 분류명 */
  medicalCategoryPropertyValueNames?: Maybe<Array<Scalars['String']>>;
  /** 시술 아이디 */
  medicalServiceId?: Maybe<Scalars['ID']>;
  /** 가격 */
  price?: Maybe<Scalars['Int']>;
  /** 프로모션 여부(인기도순에 영향) */
  promotion?: Maybe<Scalars['Boolean']>;
  /**
   * 리뷰 개수
   * @deprecated 바비톡 신디케이션 이후 사용하지 않음
   */
  reviewCount?: Maybe<Scalars['Int']>;
  /**
   * 리뷰 평균 점수
   * @deprecated 바비톡 신디케이션 이후 사용하지 않음
   */
  reviewRating?: Maybe<Scalars['Float']>;
  /** 이미지 URL */
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export enum UniversalSearchEventSortEnum {
  /** 거리순 */
  Location = 'location',
  /** 가격높은순 */
  PriceHigh = 'priceHigh',
  /** 가격낮은순 */
  PriceLow = 'priceLow',
  /** 홍보순 */
  Promotion = 'promotion',
  /** 관련순 */
  Relevancy = 'relevancy',
  /** 리뷰많은순 */
  ReviewCount = 'reviewCount',
  /** 별점높은순 */
  ReviewRating = 'reviewRating'
}

/** 통합 검색 필터 */
export type UniversalSearchFilterInput = {
  /** 의사 필터 */
  doctor?: InputMaybe<UniversalSearchDoctorFilterInput>;
  /** 시술 필터 */
  event?: InputMaybe<UniversalSearchEventFilterInput>;
  /** 병원 필터 */
  hospital?: InputMaybe<HospitalSearchFilterInput>;
};

/** 통합 검색 병원 결과 */
export type UniversalSearchHospitalListObject = {
  __typename?: 'UniversalSearchHospitalListObject';
  /** 병원 검색엔진 쿼리 */
  esQuery?: Maybe<Scalars['String']>;
  /** 병원 총 검색결과 */
  hospitalCount: Scalars['Int'];
  /** 병원 리스트 */
  hospitals: Array<UniversalSearchHospitalObject>;
};

/** 통합 검색 병원 */
export type UniversalSearchHospitalObject = {
  __typename?: 'UniversalSearchHospitalObject';
  /** 주소 */
  address?: Maybe<Scalars['String']>;
  /** 예약 분류 */
  appointmentType?: Maybe<Scalars['String']>;
  /** 역 근처 위치 */
  aroundStationInformation?: Maybe<Scalars['String']>;
  /** 뱃지 */
  badges?: Maybe<Array<Scalars['String']>>;
  /** 이벤트클리닉 여부 */
  clinicEventAvailable?: Maybe<Scalars['Boolean']>;
  /** 종료 영업시간(알수없음:0, 휴무:-1) */
  close?: Maybe<UniversalSearchDayObject>;
  /** 부서 */
  departments?: Maybe<Array<Scalars['String']>>;
  /** 관련 질병 */
  diseases?: Maybe<Array<Scalars['String']>>;
  /** 거리(m) */
  distance?: Maybe<Scalars['Int']>;
  /** 예약 가능 여부 */
  enableAppointment?: Maybe<Scalars['Boolean']>;
  /** 공휴일 연휴 중 하루라도 진료 가능 여부 */
  hasHolidayOpenDays?: Maybe<Scalars['Boolean']>;
  /** 진료과목 */
  hospitalDepartments?: Maybe<Array<Maybe<DepartmentHospital>>>;
  /** 병원 구분 (심평원) */
  hospitalDivision?: Maybe<Scalars['String']>;
  /** 병원 아이디 */
  hospitalId?: Maybe<Scalars['ID']>;
  /** 병원 이미지 URL */
  hospitalPictures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 소개 내용 */
  introductionContent?: Maybe<Scalars['String']>;
  /** 소개 부제목 */
  introductionSubTitle?: Maybe<Scalars['String']>;
  /** 소개 제목 */
  introductionTitle?: Maybe<Scalars['String']>;
  /** 인기 예약 여부 */
  isFamousAppointment?: Maybe<Scalars['Boolean']>;
  /** 모바일 접수 실시간 가능 여부(브릿지연결 여부) */
  isMobileReceiptAvailable?: Maybe<Scalars['Boolean']>;
  /** 유저 즐겨찾기 여부 */
  isUserHospitalLike?: Maybe<Scalars['Boolean']>;
  /** 위치 */
  location?: Maybe<HospitalGeoPointObject>;
  /** 진료실 중 최대 접수 대기자 수(불가능병원: -1) */
  maxReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 시술상담 가능 여부 */
  medicalServiceAvailable?: Maybe<Scalars['Boolean']>;
  /** 진료실 중 최소 접수 대기자 수 */
  minReceiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 모바일 접수 가능 병원 설정 여부 */
  mobileReceiptAvailable?: Maybe<Scalars['Boolean']>;
  /** 운영시간에 따른 모바일 접수 가능 여부 */
  mobileReceiptOpen?: Maybe<Scalars['Boolean']>;
  /** 병원명 */
  name?: Maybe<Scalars['String']>;
  /** 시작 영업시간(알수없음:0, 휴무:-1) */
  open?: Maybe<UniversalSearchDayObject>;
  /** 병원 운영시간에 따른 상태 */
  openClosedStatus?: Maybe<HospitalOpenClosedStatusEnum>;
  /** 인기 점수 */
  popularityScore?: Maybe<UniversalSearchPopularityScoreObject>;
  /** 접수 가능 대기실 수 */
  receiptOpenedRoomCount?: Maybe<Scalars['Int']>;
  /** 접수 대기 수(불가능병원: -1) */
  receiptWaitingNumber?: Maybe<Scalars['Int']>;
  /** 접수 대기 타입(시간/사람수) */
  receiptWaitingType?: Maybe<ReceiptWaitingTypeEnum>;
  /** 태블릿 접수 가능 여부 */
  receptionAvailable?: Maybe<Scalars['Boolean']>;
  /** 대표 진료 과목 */
  representativeDepartments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 리뷰 개수 */
  reviewCount?: Maybe<Scalars['Int']>;
  /** 리뷰 평균점수 */
  reviewRating?: Maybe<Scalars['Float']>;
  /** 간략 소개 */
  simplyIntroduction?: Maybe<Scalars['String']>;
  /** 태그 */
  tags?: Maybe<Array<Scalars['String']>>;
  /** 테마 */
  themes?: Maybe<Array<Scalars['String']>>;
  /** 당일 예약 가능 여부 */
  unableSameDayAppointment?: Maybe<Scalars['Boolean']>;
  /** 비대면 진료 상태 */
  untactServiceStatus?: Maybe<UntactServiceStatusEnum>;
};

export enum UniversalSearchHospitalSortEnum {
  /** 거리순 */
  Location = 'location',
  /** 신규개원순 */
  OpenedDate = 'openedDate',
  /** 인기순 */
  Popularity = 'popularity',
  /** 관련순 */
  Relevancy = 'relevancy',
  /** 리뷰많은순 */
  ReviewCount = 'reviewCount',
  /** 별점높은순 */
  ReviewRating = 'reviewRating'
}

/** 통합 검색 결과 */
export type UniversalSearchListObject = {
  __typename?: 'UniversalSearchListObject';
  /** 의사 총 검색결과 */
  doctorCount: Scalars['Int'];
  /** 의사 리스트 */
  doctors: Array<UniversalSearchDoctorObject>;
  /** 통합 검색엔진 쿼리 */
  esQuery?: Maybe<Scalars['String']>;
  /** 시술 총 검색결과 */
  eventCount: Scalars['Int'];
  /** 시술 리스트 */
  events: Array<UniversalSearchEventObject>;
  /** 병원 총 검색결과 */
  hospitalCount: Scalars['Int'];
  /** 병원 리스트 */
  hospitals: Array<UniversalSearchHospitalObject>;
};

/** 통합 검색 페이지 */
export type UniversalSearchPaginationInput = {
  /** 의사 페이지 */
  doctor?: InputMaybe<PagePaginationInput>;
  /** 시술 페이지 */
  event?: InputMaybe<PagePaginationInput>;
  /** 병원 페이지 */
  hospital?: InputMaybe<PagePaginationInput>;
};

/** 인기도 */
export type UniversalSearchPopularityScoreObject = {
  __typename?: 'UniversalSearchPopularityScoreObject';
  /** 랭크 점수 숫자 */
  integer?: Maybe<Scalars['Int']>;
  /** 랭크 점수 */
  rank_feature?: Maybe<Scalars['Int']>;
};

/** 통합 검색 정렬 */
export type UniversalSearchSortInput = {
  /** 의사 정렬 */
  doctor?: UniversalSearchDoctorSortEnum;
  /** 시술 정렬 */
  event?: UniversalSearchEventSortEnum;
  /** 병원 정렬 */
  hospital?: UniversalSearchHospitalSortEnum;
};

export enum UniversalSearchTypeEnum {
  All = 'all',
  Doctor = 'doctor',
  Event = 'event',
  Hospital = 'hospital'
}

/** 비대면 배너 */
export type UntactBannerObject = {
  __typename?: 'UntactBannerObject';
  /** 배너 이미지 */
  imageUrl: Scalars['String'];
  /** 배너 링크 */
  link: Scalars['String'];
};

export type UntactDepartment = {
  __typename?: 'UntactDepartment';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  displayOrder: Scalars['Int'];
  /** pk */
  id: Scalars['ID'];
  image: Scalars['String'];
  name: Scalars['String'];
  /** 약제비 대표값 */
  pharmacyPrice: Scalars['Int'];
  /** 진료비 대표값 */
  treatmentPrice: Scalars['Int'];
  treatments: Array<Treatment>;
  tvImageUrl?: Maybe<Scalars['String']>;
  /** TV앱에 노출 여부 */
  tvVisibility: Scalars['Boolean'];
  /** 기본, 치과, 한의학 구분 */
  type: MedicalTypeEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type UntactDepartmentQueryInput = {
  tvVisibility?: InputMaybe<Scalars['Boolean']>;
  types: Array<MedicalTypeEnum>;
};

export type UntactDepartmentsDoctorsObject = {
  __typename?: 'UntactDepartmentsDoctorsObject';
  doctors: Array<Doctor>;
  untactDepartmentId: Scalars['ID'];
};

export type UntactDepartmentsDoctorsQueryInput = {
  gender?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  limit: Scalars['Float'];
  longitude?: InputMaybe<Scalars['Float']>;
  sortType?: InputMaybe<UntactDoctorSortEnum>;
  untactDepartmentIds: Array<Scalars['ID']>;
  videoTreatmentEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UntactDoctorConfiguration = {
  __typename?: 'UntactDoctorConfiguration';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  doctorId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 모바일 진료 사용 여부 */
  mobileTreatmentEnabled: Scalars['Boolean'];
  /** 진료 운영 시간 */
  operationTime?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** 화상진료 가능 여부 */
  videoTreatmentEnabled: Scalars['Boolean'];
};

export enum UntactDoctorSortEnum {
  Distance = 'distance',
  Newer = 'newer',
  RatingMean = 'ratingMean',
  Video = 'video'
}

export type UntactDoctorTreatmentRanking = {
  __typename?: 'UntactDoctorTreatmentRanking';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  /** 의사 ID */
  doctorId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 순위 */
  ranking: Scalars['Int'];
  /** 비대면 진료 완료 수 */
  treatmentCompletionCount: Scalars['Int'];
  untactDepartment?: Maybe<UntactDepartment>;
  /** 비대면 진료과목 ID */
  untactDepartmentId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UntactDoctorTreatmentSummary = {
  __typename?: 'UntactDoctorTreatmentSummary';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  /** 의사 ID */
  doctorId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 비대면 진료 완료 수 */
  treatmentCompletionCount: Scalars['Int'];
  /** 비대면 진료과목 별 진료 완료 수 TOP 3 */
  untactDoctorTreatmentRankings?: Maybe<Array<Maybe<UntactDoctorTreatmentRanking>>>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

/** 비대면 진료 이용 가이드 */
export type UntactGuideObject = {
  __typename?: 'UntactGuideObject';
  /** 가이드 링크 */
  link: Scalars['String'];
  /** 가이드 제목 */
  title: Scalars['String'];
};

export type UntactHospitalBankAccount = {
  __typename?: 'UntactHospitalBankAccount';
  /** 예금주 */
  accountHolderName: Scalars['String'];
  /** 계좌번호 */
  accountNumber: Scalars['String'];
  /** 은행명 */
  bankName: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  /** 병원 ID */
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UntactHospitalConfiguration = {
  __typename?: 'UntactHospitalConfiguration';
  accountId?: Maybe<Scalars['String']>;
  agreedAt?: Maybe<Scalars['DateTime']>;
  agreement: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 마케팅 수신동의 */
  marketingTerms: Scalars['Boolean'];
  untactHospitalConfigurationAccounts: Array<UntactHospitalConfigurationAccount>;
  untactServiceStatus: UntactServiceStatusEnum;
  untactTreatmentAccept: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UntactHospitalConfigurationAccount = {
  __typename?: 'UntactHospitalConfigurationAccount';
  accountId: Scalars['ID'];
  accountType: InsuredTypeEnum;
  /** cat id */
  catId: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** Nicepay 활성화 여부 */
  nicepayCapability?: Maybe<Scalars['String']>;
  untactHospitalConfiguration: UntactHospitalConfiguration;
};

export type UntactOperation = {
  __typename?: 'UntactOperation';
  /** 언택트 Alert여부 */
  alert: Scalars['Boolean'];
  /** 알림 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 언택트 Open여부 */
  open: Scalars['Boolean'];
  /** 알림 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 경고 메시지 */
  warningMessage?: Maybe<Scalars['String']>;
};

export type UntactPharmacyParcelAgency = {
  __typename?: 'UntactPharmacyParcelAgency';
  code: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visible: Scalars['Boolean'];
};

export type UntactQuestionnaire = {
  __typename?: 'UntactQuestionnaire';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  order: Scalars['Int'];
  questionnaireContentType: QuestionnaireContentTypeEnum;
  questionnaireType: QuestionnaireTypeEnum;
  text: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  visibility: Scalars['Boolean'];
};

export type UntactQuestionnaireResponse = {
  __typename?: 'UntactQuestionnaireResponse';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  pharmacy: Pharmacy;
  pharmacyId?: Maybe<Scalars['ID']>;
  questionnaireText: Scalars['String'];
  questionnaireType: QuestionnaireTypeEnum;
  treatment: Treatment;
  treatmentId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UntactQuestionnaireResponseInput = {
  pharmacyId?: InputMaybe<Scalars['ID']>;
  questionnaireText: Array<Scalars['String']>;
  questionnaireType: QuestionnaireTypeEnum;
  treatmentId: Scalars['ID'];
};

export enum UntactServiceStatusEnum {
  Active = 'active',
  Deleted = 'deleted',
  None = 'none'
}

export type UntactTvBanner = {
  __typename?: 'UntactTvBanner';
  backgroundColor: Scalars['String'];
  cardImage: Scalars['String'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  detailImage: Scalars['String'];
  displayOrder: Scalars['Float'];
  /** pk */
  id: Scalars['ID'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  topImage: Scalars['String'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export type UntactVisitSuccessInput = {
  pharmacyId?: InputMaybe<Scalars['ID']>;
  treatmentId: Scalars['ID'];
};

export type UpdateGoogleSpreadsheetInput = {
  /** 열 Index */
  columnIndex?: Scalars['Float'];
  /** 행 Index */
  rowIndex?: Scalars['Float'];
  /** SheetID */
  sheetId?: Scalars['Float'];
  /** 스프레드 SheetID */
  spreadsheetId: Scalars['String'];
  /** 업데이트 타입 */
  updateType?: GoogleSpreadSheetUpdateType;
  /** 데이터 */
  values?: Array<Array<Scalars['String']>>;
};

export type UpdateHospitalInput = {
  address?: InputMaybe<Scalars['String']>;
  /** (현장접수) 병원이 요청한 병원 별칭 */
  aliasName?: InputMaybe<Scalars['String']>;
  /** 예약 유형 */
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  aroundStationInformation?: InputMaybe<Scalars['String']>;
  /** 병원 catId */
  catId?: InputMaybe<Scalars['String']>;
  /** 진료과 */
  departmentIds?: InputMaybe<Array<Scalars['ID']>>;
  emergencyAvailability?: InputMaybe<Scalars['Boolean']>;
  /** 예약가능 설정 */
  enableAppointment?: InputMaybe<Scalars['Boolean']>;
  establishmentDivision?: InputMaybe<EstablishmentDivisionEnum>;
  hiraCode?: InputMaybe<Scalars['String']>;
  homepageUrl?: InputMaybe<Scalars['String']>;
  hospitalDivision?: InputMaybe<Scalars['String']>;
  /** 맵핑할 구병원 아이디 */
  hospitalLegacyId?: InputMaybe<Scalars['String']>;
  hospitalOperationTimes?: InputMaybe<Array<CreateHospitalOperationTimeInput>>;
  hospitalPictures?: InputMaybe<Array<Scalars['String']>>;
  introductionContent?: InputMaybe<Scalars['String']>;
  introductionSubTitle?: InputMaybe<Scalars['String']>;
  introductionTitle?: InputMaybe<Scalars['String']>;
  /** 인기예약 여부 */
  isFamousAppointment?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  /** 클라이언트에 노출되는 병원명 */
  name?: InputMaybe<Scalars['String']>;
  nhisCode?: InputMaybe<Scalars['String']>;
  operatingState?: InputMaybe<HospitalOperatingStateEnum>;
  operationTimeDescription?: InputMaybe<Scalars['String']>;
  parkingAvailability?: InputMaybe<Scalars['Boolean']>;
  parkingDescription?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  reviewVisibility?: InputMaybe<Scalars['Boolean']>;
  safetyPhone?: InputMaybe<Scalars['String']>;
  /** 클리닉마켓 계약 여부 */
  signClinicMarketContract?: InputMaybe<Scalars['Boolean']>;
  simplyIntroduction?: InputMaybe<Scalars['String']>;
  /** 태그명 */
  tagNames?: InputMaybe<Array<Scalars['String']>>;
  /** 당일예약불가 설정 */
  unableSameDayAppointment?: InputMaybe<Scalars['Boolean']>;
  /** 비대면 진료 신청 여부 */
  untactServiceAvailable?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateHospitalLikeInput = {
  hospitalId: Scalars['ID'];
  receiveAlarm: Scalars['Boolean'];
  value: Scalars['Boolean'];
};

export type UpdateMedicineAlarmInput = {
  /** pk */
  id: Scalars['ID'];
  /** 알람 시간(HH:mm) */
  medicineAlarmTimes: Array<Scalars['String']>;
  /** 알람 이름 */
  name: Scalars['String'];
};

/** 리뷰 수정 인풋 */
export type UpdateReviewInput = {
  /** 연계 예약 ID */
  appointmentId?: InputMaybe<Scalars['ID']>;
  /** 리뷰 내용 */
  contents?: InputMaybe<Scalars['String']>;
  /** 진료 만족도 */
  diagnosisScore?: InputMaybe<Scalars['Int']>;
  /** 의사선생님 아이디 */
  doctorId?: InputMaybe<Scalars['ID']>;
  /** 연계 이벤트 ID */
  eventId?: InputMaybe<Scalars['ID']>;
  /** 시설 만족도 */
  facilityScore?: InputMaybe<Scalars['Int']>;
  /** 연계 병원 ID */
  hospitalId?: InputMaybe<Scalars['ID']>;
  /** 이미지 업로드 URL */
  images?: InputMaybe<Array<Scalars['String']>>;
  /** 인증/비인증 */
  isVisited?: InputMaybe<Scalars['Boolean']>;
  /** 친절 만족도 */
  kindnessScore?: InputMaybe<Scalars['Int']>;
  /** 연계 약국 ID */
  pharmacyId?: InputMaybe<Scalars['ID']>;
  /** 평점 */
  rating?: InputMaybe<Scalars['Int']>;
  /** 연계 접수 ID */
  receiptId?: InputMaybe<Scalars['ID']>;
  /** 재방문 */
  recommendation?: InputMaybe<Scalars['Boolean']>;
  /** 병원|약국|클리닉|이벤트|비대면 타입 */
  reviewType?: InputMaybe<ReviewTypeEnum>;
  /** 리뷰 작성 경로 */
  source?: InputMaybe<ReviewSourceEnum>;
  /** 병원|약국|클리닉|이벤트|비대면 아이디 */
  targetId?: InputMaybe<Scalars['ID']>;
  /** 진료항목 */
  treatmentName?: InputMaybe<Scalars['String']>;
  /** 진료비용 */
  treatmentPrice?: InputMaybe<Scalars['Float']>;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: InputMaybe<Scalars['ID']>;
  /** 작성자 이메일 */
  userEmail?: InputMaybe<Scalars['String']>;
  /** 작성자 이름 */
  userName?: InputMaybe<Scalars['String']>;
  /** 리뷰 노출 */
  visibility?: InputMaybe<Scalars['Boolean']>;
  /** 방문일 */
  visitedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']>;
  addressDetail?: InputMaybe<Scalars['String']>;
  birth?: InputMaybe<Scalars['String']>;
  /** 생성일 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  marketingTermOfService?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpsertHospitalOperationTimeInput = {
  /** 시작시간(kst) hh:mm:ss */
  from: Scalars['String'];
  operationOff: Scalars['Boolean'];
  operationType: HospitalOperationTimeTypeEnum;
  /** 종료시간(kst) hh:mm:ss */
  to: Scalars['String'];
};

export type UseServiceStatusObject = {
  __typename?: 'UseServiceStatusObject';
  appointment?: Maybe<Appointment>;
  lastUseServiceType: LastUseServiceType;
  receiptStatus?: Maybe<ReceiptStatusObject>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']>;
  addressDetail?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  birth?: Maybe<Scalars['String']>;
  ci?: Maybe<Scalars['String']>;
  ciExist?: Maybe<Scalars['Boolean']>;
  clinicBookmarks?: Maybe<Array<Maybe<ClinicBookmark>>>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  devices?: Maybe<Array<Maybe<Device>>>;
  di?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventLikes?: Maybe<Array<Maybe<EventLike>>>;
  gender?: Maybe<GenderEnum>;
  goodocStoreId?: Maybe<Scalars['Int']>;
  hospitalLikes?: Maybe<Array<Maybe<HospitalLike>>>;
  /** pk */
  id: Scalars['ID'];
  mobileCarrier?: Maybe<MobileCarrierEnum>;
  name?: Maybe<Scalars['String']>;
  nhisMedicalCheckupHistories?: Maybe<Array<Maybe<NhisMedicalCheckupHistory>>>;
  nhisMedicalHistories?: Maybe<Array<Maybe<NhisMedicalHistory>>>;
  patients?: Maybe<Array<Maybe<Patient>>>;
  pharmacyLikes?: Maybe<Array<Maybe<PharmacyLike>>>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  reviewLikes?: Maybe<Array<Maybe<ReviewLike>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  searchedKeywords?: Maybe<Array<Maybe<SearchedKeyword>>>;
  socialAuths?: Maybe<Array<Maybe<SocialAuth>>>;
  tradeid?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  userLegacyId?: Maybe<Scalars['Int']>;
  userReceipts?: Maybe<Array<Maybe<UserReceipt>>>;
  userReceiptsOfToday?: Maybe<Array<Maybe<UserReceiptOfToday>>>;
  userTermsOfServices?: Maybe<Array<Maybe<UserTermsOfService>>>;
  yymmddi?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserAddressCreateInput = {
  /** 시/군/구 포함 도로명 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  addressDetail?: InputMaybe<Scalars['String']>;
  /** 법정동/법정리 이름 */
  bname: Scalars['String'];
  /** 주소 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 주소 타입 */
  type: UserAddressTypeEnum;
  /** 우편번호 */
  zipCode: Scalars['String'];
};

export type UserAddressObject = {
  __typename?: 'UserAddressObject';
  /** 시/군/구 포함 도로명 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  addressDetail?: Maybe<Scalars['String']>;
  /** 법정동/법정리 이름 */
  bname: Scalars['String'];
  /** 주소 pk */
  id: Scalars['ID'];
  /** 주소 이름 */
  name?: Maybe<Scalars['String']>;
  /** 주소 타입 */
  type: UserAddressTypeEnum;
  /** 유저 아이디 */
  userId: Scalars['ID'];
  /** 우편번호 */
  zipCode: Scalars['String'];
};

export enum UserAddressTypeEnum {
  /** 기타 */
  Etc = 'etc',
  /** 집 */
  Home = 'home'
}

export type UserAddressUpdateInput = {
  /** 시/군/구 포함 도로명 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  addressDetail?: InputMaybe<Scalars['String']>;
  /** 법정동/법정리 이름 */
  bname: Scalars['String'];
  /** 주소 pk */
  id: Scalars['ID'];
  /** 주소 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 우편번호 */
  zipCode: Scalars['String'];
};

export type UserHistoriesOnCloseOptionOfGoodocPopup = {
  closedAt: Scalars['DateTime'];
  id?: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  address?: Maybe<Scalars['String']>;
  addressDetail?: Maybe<Scalars['String']>;
  birth?: Maybe<Scalars['String']>;
  ci?: Maybe<Scalars['String']>;
  ciExist?: Maybe<Scalars['Boolean']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  devices?: Maybe<Array<Maybe<Device>>>;
  di?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  goodocStoreId?: Maybe<Scalars['Int']>;
  hospitalLikes?: Maybe<Array<Maybe<Hospital>>>;
  /** pk */
  id: Scalars['ID'];
  identityVerifications?: Maybe<Array<Maybe<IdentityVerificationObject>>>;
  marketingTermOfService: Scalars['Boolean'];
  mobileCarrier?: Maybe<MobileCarrierEnum>;
  name?: Maybe<Scalars['String']>;
  /** 유저의 환자(가족) 수 */
  patientCount?: Maybe<Scalars['Float']>;
  pharmacyLikes?: Maybe<Array<Maybe<Pharmacy>>>;
  phone?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  socialAuths?: Maybe<Array<Maybe<SocialAuth>>>;
  termsOfServices?: Maybe<Array<Maybe<UserTermsOfServiceObject>>>;
  tradeid?: Maybe<Scalars['String']>;
  userLegacyId?: Maybe<Scalars['Int']>;
  userTermsOfServices?: Maybe<Array<Maybe<UserTermsOfService>>>;
  yymmddi?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserReceipt = {
  __typename?: 'UserReceipt';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  receipt: Receipt;
  receiptId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type UserReceiptOfToday = {
  __typename?: 'UserReceiptOfToday';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  receipt?: Maybe<Receipt>;
  receiptId?: Maybe<Scalars['ID']>;
  receiptOfToday: ReceiptOfToday;
  receiptOfTodayId: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type UserReceiptQueryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hospitalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  receiptIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  receiptOfTodayIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  receiptPatientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** pagination skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** 정렬의 기준이 되는 field */
  sortField?: InputMaybe<Scalars['String']>;
  /** 정렬 순서: DESC, ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
  /** 접수 신청 경로 유형(tablet, mobile, qr) */
  sourceTypes?: InputMaybe<Array<ReceiptSourceTypeEnum>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 접수 타입) C01, C03, C04, C07, F03, F05 */
  statuses?: InputMaybe<Array<ReceiptStatusEnum>>;
  /** pagination take */
  take?: InputMaybe<Scalars['Int']>;
};

export type UserTermsOfService = {
  __typename?: 'UserTermsOfService';
  agreement: Scalars['Boolean'];
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  signedAt: Scalars['DateTime'];
  termsOfService: TermsOfService;
  termsOfServiceId?: Maybe<Scalars['ID']>;
  user: User;
  userId?: Maybe<Scalars['ID']>;
};

/** 유저 이용약관 동의 */
export type UserTermsOfServiceObject = {
  __typename?: 'UserTermsOfServiceObject';
  agreement: Scalars['Boolean'];
  contents: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  required: Scalars['Boolean'];
  sequence: Scalars['Int'];
  signedAt: Scalars['DateTime'];
  type: AgreementTypeEnum;
  user: User;
};

export type VideoAdvertisement = {
  __typename?: 'VideoAdvertisement';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  videoAdvertisementPlaylist?: Maybe<Array<Maybe<VideoAdvertisementPlaylist>>>;
  videoAdvertisementPlaylistHistories?: Maybe<
    Array<Maybe<VideoAdvertisementPlaylistHistory>>
  >;
};

export type VideoAdvertisementPlaylist = {
  __typename?: 'VideoAdvertisementPlaylist';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  hospital: Hospital;
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  videoAdvertisement: VideoAdvertisement;
  videoAdvertisementId?: Maybe<Scalars['ID']>;
};

export type VideoAdvertisementPlaylistHistory = {
  __typename?: 'VideoAdvertisementPlaylistHistory';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  hospital: Hospital;
  hospitalId: Scalars['ID'];
  /** pk */
  id: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  videoAdvertisement: VideoAdvertisement;
  videoAdvertisementId: Scalars['ID'];
};

export enum VideoCallStatusEnum {
  CreatedSession = 'createdSession',
  DoctorJoined = 'doctorJoined',
  DoctorLeft = 'doctorLeft',
  Matched = 'matched',
  PatientJoined = 'patientJoined',
  PatientLeft = 'patientLeft'
}

export type VisitFaxInput = {
  /** faxNumber */
  fax: Scalars['String'];
  /** pharmacyId */
  pharmacyId: Scalars['ID'];
  /** prescriptionId */
  prescriptionId: Scalars['ID'];
};

export type VisitPharmaciesOutput = {
  __typename?: 'VisitPharmaciesOutput';
  code: Scalars['Float'];
  message: Scalars['String'];
  pharmacies: Array<VisitPharmacy>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type VisitPharmacy = {
  __typename?: 'VisitPharmacy';
  address?: Maybe<Scalars['String']>;
  /** 환자와 약국간의 거리 */
  distance: Scalars['Float'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** 연중무휴 여부(Y, N) */
  openAllDayYn: Scalars['String'];
  /** 야간운영 여부(Y, N) */
  openNightYn: Scalars['String'];
  /** 운영시간 설명 2000자내외 */
  operationTimeDescription?: Maybe<Scalars['String']>;
  /** 약국 고유코드(약정원) */
  pharmacyCode: Scalars['String'];
  pharmacyOperationTimes: Array<OperationTime>;
  phone?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type VotingEvent = {
  __typename?: 'VotingEvent';
  /** 투표/이벤트 종류 구분(3) : 공지사항, 투표, 이벤트 */
  contentType: VotingEventTypeEnum;
  /** 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 투표 종료일(UTC) */
  endAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  /** image links */
  imageLinks?: Maybe<Array<Scalars['String']>>;
  /** image urls */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 링크 버튼 text */
  linkButtonLabels?: Maybe<Array<Scalars['String']>>;
  /** 링크 버튼 urls */
  linkButtonUrls?: Maybe<Array<Scalars['String']>>;
  /** 투표 시작일(UTC) */
  startAt?: Maybe<Scalars['DateTime']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 작성자 */
  uberAdminUser: UberAdminUser;
  uberAdminUserId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** video links */
  videoLinks?: Maybe<Array<Scalars['String']>>;
  /** video urls */
  videoUrls?: Maybe<Array<Scalars['String']>>;
  /** 노출 여부, (당연히) 노출 기간 설정 보다 우선 함. */
  visibility: Scalars['Boolean'];
  /** 투표 응답 답변 목록(선택 가능 답변 목록) */
  votingChoices?: Maybe<Array<Scalars['String']>>;
  /** VotingEventSummary */
  votingEventSummary?: Maybe<VotingEventSummary>;
  votingEventSummaryId?: Maybe<Scalars['ID']>;
};

export type VotingEventHistory = {
  __typename?: 'VotingEventHistory';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 좋아요 */
  isLike: Scalars['Boolean'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
  votingEvent: VotingEvent;
  votingEventId: Scalars['ID'];
  /** 투표 응답(chosen indexes), support multi-choices */
  votingResponses: Array<Scalars['Int']>;
};

export type VotingEventOutput = {
  __typename?: 'VotingEventOutput';
  /** 투표/이벤트 종류 구분(3) : 공지사항, 투표, 이벤트 */
  contentType: VotingEventTypeEnum;
  /** 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** 투표 종료일(UTC) */
  endAt?: Maybe<Scalars['DateTime']>;
  /** pk */
  id: Scalars['ID'];
  /** image links */
  imageLinks?: Maybe<Array<Scalars['String']>>;
  /** image urls */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** 링크 버튼 text */
  linkButtonLabels?: Maybe<Array<Scalars['String']>>;
  /** 링크 버튼 urls */
  linkButtonUrls?: Maybe<Array<Scalars['String']>>;
  /** 투표 시작일(UTC) */
  startAt?: Maybe<Scalars['DateTime']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 작성자 */
  uberAdminUser: UberAdminUser;
  uberAdminUserId: Scalars['ID'];
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  /** video links */
  videoLinks?: Maybe<Array<Scalars['String']>>;
  /** video urls */
  videoUrls?: Maybe<Array<Scalars['String']>>;
  /** 노출 여부, (당연히) 노출 기간 설정 보다 우선 함. */
  visibility: Scalars['Boolean'];
  /** 투표 응답 목록(선택 가능 답변 목록) */
  votingChoices?: Maybe<Array<Scalars['String']>>;
  /** 투표 응답 내역(좋아요 포함) */
  votingEventHistories?: Maybe<Array<VotingEventHistory>>;
  /** VotingEventSummary */
  votingEventSummary?: Maybe<VotingEventSummary>;
  votingEventSummaryId?: Maybe<Scalars['ID']>;
};

export type VotingEventSummary = {
  __typename?: 'VotingEventSummary';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  /** pk */
  id: Scalars['ID'];
  /** 좋아요 수 */
  numberOfLikes: Scalars['Int'];
  /** 누적 투표 이벤트 참여자 수 */
  numberOfVoters: Scalars['Int'];
  /** 투표 응답별 누적 수 */
  numberOfVotingResponses: Array<Scalars['Int']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
  votingEvent: VotingEvent;
  votingEventId: Scalars['ID'];
};

export enum VotingEventTypeEnum {
  All = 'all',
  Notice = 'notice',
  Voting = 'voting'
}

export type WaitingOrderInQueueQueryInput = {
  receiptOfTodayId: Scalars['ID'];
};

export type Webcam = {
  __typename?: 'Webcam';
  /** 생성일 */
  createdAt: Scalars['DateTime'];
  disposalAt?: Maybe<Scalars['DateTime']>;
  hospital?: Maybe<Hospital>;
  hospitalId?: Maybe<Scalars['ID']>;
  /** pk */
  id: Scalars['ID'];
  orderedAt?: Maybe<Scalars['DateTime']>;
  serialNo: Scalars['String'];
  status: WebcamStatusEnum;
  /** 수정일 */
  updatedAt: Scalars['DateTime'];
};

export enum WebcamStatusEnum {
  Disposal = 'disposal',
  In = 'in',
  Out = 'out'
}

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type CheckConsentAndReturnVisitorPreferenceInput = {
  encryptedRrn?: InputMaybe<Scalars['String']>;
  /** name */
  name: Scalars['String'];
  /** 전화번호 */
  phone: Scalars['String'];
  /** 환자주민등록번호 */
  residentRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 접수 인입 유형 */
  sourceType?: InputMaybe<Scalars['String']>;
};

export enum MedicalQuestionnaireContentsTypes {
  Chapter = 'chapter',
  Main = 'main',
  Question = 'question'
}

export enum MedicalQuestionnaireInputTypes {
  Input = 'input',
  Multiple = 'multiple',
  MultipleWithInput = 'multipleWithInput',
  Single = 'single'
}

export enum MedicalQuestionnaireTypes {
  Child = 'child',
  Normal = 'normal'
}

export type MedicalServiceSummaryReviewObject = {
  __typename?: 'medicalServiceSummaryReviewObject';
  /** 후기 */
  contents?: Maybe<Scalars['String']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['String']>;
  /** 리뷰생성날짜 */
  createdAt: Scalars['String'];
  /** 진료결과 */
  diagnosisScore?: Maybe<Scalars['Float']>;
  /** 의사아이디 */
  doctorId?: Maybe<Scalars['String']>;
  /** 시설/청결도 */
  facilityScore?: Maybe<Scalars['Float']>;
  /** 리뷰ID */
  id: Scalars['String'];
  /** image 리스트 */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 방문여부 */
  isVisited?: Maybe<Scalars['Boolean']>;
  /** 친절도 */
  kindnessScore?: Maybe<Scalars['Float']>;
  /** 리뷰점수 */
  rating?: Maybe<Scalars['Float']>;
  /** 추천 */
  recommendation?: Maybe<Scalars['Boolean']>;
  /** 작성경로 */
  source?: Maybe<Scalars['String']>;
  /** 진료항목 */
  treatmentName?: Maybe<Scalars['String']>;
  /** 진료비용 */
  treatmentPrice?: Maybe<Scalars['Float']>;
  /** 리뷰수정날짜 */
  updatedAt: Scalars['String'];
  /** 작성 유저 아이디 */
  userId?: Maybe<Scalars['String']>;
  /** 작성자 */
  userName?: Maybe<Scalars['String']>;
};

export enum TabletAddressInputAccept {
  Essential = 'essential',
  Nouse = 'nouse',
  Skippable = 'skippable'
}

export enum TargetAgeTypes {
  Age19To25 = 'age19To25',
  Age26To45 = 'age26To45',
  Age46To64 = 'age46To64',
  AgeOver65 = 'ageOver65',
  AgeUnder19 = 'ageUnder19'
}

export enum TargetGenderTypes {
  Female = 'female',
  Male = 'male'
}

export enum TargetReceiptPatientTypes {
  FirstReceipt = 'firstReceipt',
  ReturnReceipt = 'returnReceipt'
}

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserObject';
    id: string;
    name?: string | null;
    phone?: string | null;
    yymmddi?: string | null;
  };
};

export type SignInDeviceMutationVariables = Exact<{
  input: SignInDeviceInput;
}>;

export type SignInDeviceMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'SignInDeviceResult'; accessToken: string };
};

export type SignInSocialMutationVariables = Exact<{
  input: SocialSignInInput;
}>;

export type SignInSocialMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'SignInUserResult'; accessToken: string };
};

export type SpecialistDepartmentsQueryVariables = Exact<{
  existsDoctor?: InputMaybe<Scalars['Boolean']>;
}>;

export type SpecialistDepartmentsQuery = {
  __typename?: 'Query';
  specialistDepartments: Array<{
    __typename?: 'SpecialistDepartment';
    id: string;
    name: string;
    displayOrder: number;
  }>;
};

export type UntactDepartmentsQueryVariables = Exact<{
  input?: InputMaybe<UntactDepartmentQueryInput>;
}>;

export type UntactDepartmentsQuery = {
  __typename?: 'Query';
  untactDepartments: Array<{
    __typename?: 'UntactDepartment';
    id: string;
    name: string;
    image: string;
    type: MedicalTypeEnum;
    displayOrder: number;
  }>;
};

export type UntactDepartmentsDoctorsQueryVariables = Exact<{
  input: UntactDepartmentsDoctorsQueryInput;
}>;

export type UntactDepartmentsDoctorsQuery = {
  __typename?: 'Query';
  untactDepartmentsDoctors: Array<{
    __typename?: 'UntactDepartmentsDoctorsObject';
    untactDepartmentId: string;
    doctors: Array<{
      __typename?: 'Doctor';
      id: string;
      name: string;
      defaultImage: string;
      position?: string | null;
      image?: string | null;
      untactOnTreatment: boolean;
      showAwardBadge?: boolean | null;
      hospital: {
        __typename?: 'Hospital';
        name: string;
        id: string;
        address?: string | null;
      };
      reviewSummary: {
        __typename?: 'ReviewSummary';
        reviewsCount: number;
        ratingMean: number;
      };
      doctorSpecialistDepartments?: Array<{
        __typename?: 'DoctorSpecialistDepartment';
        representative: boolean;
        specialistDepartment: { __typename?: 'SpecialistDepartment'; name: string };
      } | null> | null;
    }>;
  }>;
};

export type UntactDoctorQueryVariables = Exact<{
  doctorId: Scalars['ID'];
}>;

export type UntactDoctorQuery = {
  __typename?: 'Query';
  doctor: {
    __typename?: 'Doctor';
    id: string;
    name: string;
    image?: string | null;
    untactOnTreatment: boolean;
    type: MedicalTypeEnum;
    untactNotice?: string | null;
    profile?: string | null;
    doctorSpecialistDepartments?: Array<{
      __typename?: 'DoctorSpecialistDepartment';
      representative: boolean;
      specialistDepartment: { __typename?: 'SpecialistDepartment'; name: string };
    } | null> | null;
    reviewSummary: { __typename?: 'ReviewSummary'; reviewsCount: number };
    untactDoctorTreatmentSummary?: {
      __typename?: 'UntactDoctorTreatmentSummary';
      treatmentCompletionCount: number;
    } | null;
    untactConfiguration?: {
      __typename?: 'UntactDoctorConfiguration';
      operationTime?: string | null;
    } | null;
    hospital: {
      __typename?: 'Hospital';
      id: string;
      name: string;
      address?: string | null;
    };
    doctorUntactDepartments?: Array<{
      __typename?: 'DoctorUntactDepartment';
      id: string;
      untactDepartment?: {
        __typename?: 'UntactDepartment';
        name: string;
        id: string;
      } | null;
    } | null> | null;
  };
};

export type UntactSpecialistDepartmentDoctorsQueryVariables = Exact<{
  specialistDepartmentId: Scalars['ID'];
}>;

export type UntactSpecialistDepartmentDoctorsQuery = {
  __typename?: 'Query';
  untactSpecialistDepartmentDoctors: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    defaultImage: string;
    position?: string | null;
    image?: string | null;
    untactOnTreatment: boolean;
    showAwardBadge?: boolean | null;
    hospital: {
      __typename?: 'Hospital';
      name: string;
      id: string;
      address?: string | null;
    };
    reviewSummary: {
      __typename?: 'ReviewSummary';
      reviewsCount: number;
      ratingMean: number;
    };
    doctorSpecialistDepartments?: Array<{
      __typename?: 'DoctorSpecialistDepartment';
      representative: boolean;
      specialistDepartment: { __typename?: 'SpecialistDepartment'; name: string };
    } | null> | null;
  }>;
};

export type HospitalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type HospitalQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'HospitalObject';
    id: string;
    name: string;
    address?: string | null;
    defaultImage: string;
    operatingState: HospitalOperatingStateEnum;
    operationTimeDescription?: string | null;
    onUntactTreatment?: boolean | null;
    hospitalPictures?: Array<string> | null;
    phone?: string | null;
    safetyPhone?: string | null;
    operationTimes?: Array<{
      __typename?: 'HospitalOperationTime';
      from: string;
      to: string;
      targetDate?: any | null;
      holidayName?: string | null;
      operationOff: boolean;
      operationType: HospitalOperationTimeTypeEnum;
      id: string;
    } | null> | null;
    medicalServices?: Array<{
      __typename?: 'MedicalService';
      id: string;
      serviceType: string;
      displayName: string;
      description: string;
      price?: number | null;
      medicalCategory?: { __typename?: 'MedicalCategory'; name: string } | null;
    } | null> | null;
  };
};

export type UntactHospitalDoctorsQueryVariables = Exact<{
  hospitalId: Scalars['ID'];
}>;

export type UntactHospitalDoctorsQuery = {
  __typename?: 'Query';
  untactHospitalDoctors: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    image?: string | null;
    type: MedicalTypeEnum;
    untactOnTreatment: boolean;
    doctorSpecialistDepartments?: Array<{
      __typename?: 'DoctorSpecialistDepartment';
      representative: boolean;
      specialistDepartment: { __typename?: 'SpecialistDepartment'; name: string };
    } | null> | null;
  }>;
};

export type MetaDoctorQueryVariables = Exact<{
  doctorId: Scalars['ID'];
}>;

export type MetaDoctorQuery = {
  __typename?: 'Query';
  doctor: {
    __typename?: 'Doctor';
    id: string;
    name: string;
    image?: string | null;
    type: MedicalTypeEnum;
    untactVisibility: boolean;
    doctorUntactDepartments?: Array<{
      __typename?: 'DoctorUntactDepartment';
      untactDepartment?: { __typename?: 'UntactDepartment'; name: string } | null;
    } | null> | null;
    hospital: {
      __typename?: 'Hospital';
      name: string;
      address?: string | null;
      hospitalDepartments?: Array<{
        __typename?: 'DepartmentHospital';
        department: { __typename?: 'Department'; name: string };
      } | null> | null;
      hospitalTags?: Array<{ __typename?: 'HospitalTag'; name: string } | null> | null;
    };
  };
};

export type MetaHospitalQueryVariables = Exact<{
  hospitalId: Scalars['ID'];
}>;

export type MetaHospitalQuery = {
  __typename?: 'Query';
  hospital: {
    __typename?: 'HospitalObject';
    id: string;
    name: string;
    address?: string | null;
    doctors?: Array<{
      __typename?: 'Doctor';
      name: string;
      type: MedicalTypeEnum;
      untactVisibility: boolean;
      doctorUntactDepartments?: Array<{
        __typename?: 'DoctorUntactDepartment';
        untactDepartment?: { __typename?: 'UntactDepartment'; name: string } | null;
      } | null> | null;
    } | null> | null;
    hospitalDepartments?: Array<{
      __typename?: 'DepartmentHospital';
      department: { __typename?: 'Department'; name: string };
    } | null> | null;
    hospitalTags?: Array<{ __typename?: 'HospitalTag'; name: string } | null> | null;
  };
};

export type CreatePatientMutationVariables = Exact<{
  name: Scalars['String'];
  residentRegistrationNumber: Scalars['String'];
}>;

export type CreatePatientMutation = {
  __typename?: 'Mutation';
  createPatient: { __typename?: 'Patient'; id: string };
};

export type PatientsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['String']>;
}>;

export type PatientsQuery = {
  __typename?: 'Query';
  patients: Array<{
    __typename?: 'Patient';
    id: string;
    name: string;
    residentRegistrationNumber?: string | null;
  }>;
};

export type ApproveKakaopayRequestMutationVariables = Exact<{
  pg_token: Scalars['String'];
  tid: Scalars['String'];
}>;

export type ApproveKakaopayRequestMutation = {
  __typename?: 'Mutation';
  approveKakaopayRequest: boolean;
};

export type CreateKakaopayRequestMutationVariables = Exact<{
  requestType?: InputMaybe<KakaopayRequestTypeEnum>;
}>;

export type CreateKakaopayRequestMutation = {
  __typename?: 'Mutation';
  createKakaopayRequest: {
    __typename?: 'KakaopayReadyResponseObject';
    next_redirect_pc_url: string;
    next_redirect_mobile_url: string;
    tid: string;
  };
};

export type CreateTreatmentPaymentMutationVariables = Exact<{
  paymentMethodId: Scalars['ID'];
  treatmentId: Scalars['ID'];
  treatmentFee: Scalars['Float'];
  signData?: InputMaybe<Scalars['String']>;
  installmentMonths?: InputMaybe<Scalars['Float']>;
}>;

export type CreateTreatmentPaymentMutation = {
  __typename?: 'Mutation';
  createTreatmentPayment: {
    __typename?: 'Treatment';
    status: TreatmentStatusEnum;
    treatmentPayments?: Array<{
      __typename?: 'TreatmentPayment';
      price: number;
      paymentId?: string | null;
      paymentStatus?: string | null;
    }> | null;
  };
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = {
  __typename?: 'Query';
  paymentMethods: Array<{
    __typename?: 'PaymentMethod';
    id: string;
    clientMethodId?: string | null;
    lastCardNumber?: string | null;
    cardName?: string | null;
    default: boolean;
    status: string;
    provider?: string | null;
    userId: string;
  }>;
};

export type RequestBankTransferConfirmationMutationVariables = Exact<{
  treatmentId: Scalars['ID'];
}>;

export type RequestBankTransferConfirmationMutation = {
  __typename?: 'Mutation';
  requestBankTransferConfirmation: boolean;
};

export type SelectPaymentMethodToBankTransferMutationVariables = Exact<{
  treatmentId: Scalars['ID'];
  treatmentFee: Scalars['Float'];
}>;

export type SelectPaymentMethodToBankTransferMutation = {
  __typename?: 'Mutation';
  selectPaymentMethodToBankTransfer: {
    __typename?: 'Treatment';
    id: string;
    isPaid?: boolean | null;
    fee?: number | null;
    paymentId?: string | null;
    paymentStatus?: PaymentStatusEnum | null;
    prescriptionState?: PrescriptionStateEnum | null;
    paymentMethodType?: PaymentMethodTypeEnum | null;
  };
};

export type CancelVisitPharmacyMutationVariables = Exact<{
  treatmentId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}>;

export type CancelVisitPharmacyMutation = {
  __typename?: 'Mutation';
  cancelVisitPharmacy: {
    __typename?: 'CancelVisitPharmacyOutput';
    code: number;
    message: string;
    prescriptionCode: string;
  };
};

export type PrescriptionQueryVariables = Exact<{
  prescriptionId: Scalars['String'];
}>;

export type PrescriptionQuery = {
  __typename?: 'Query';
  prescription: {
    __typename?: 'Prescription';
    treatmentId: string;
    prescriptionDispatchHistories?: Array<{
      __typename?: 'PrescriptionDispatchHistory';
      id: string;
      createdAt: any;
      updatedAt: any;
      prescriptionId: string;
      status: PrescriptionDispatchStatusEnum;
      patientName: string;
      patientPhone: string;
      patientRequests?: string | null;
      pharmacyCode: string;
      pharmacyName: string;
      pharmacyPhone: string;
      pharmacyAddress: string;
      pharmacyBankName?: string | null;
      pharmacyBankAccount?: string | null;
      pharmacyAccountHolder?: string | null;
      receiptType: PrescriptionReceiptTypeEnum;
      estimateVisitTime?: any | null;
      substituteName?: string | null;
      substitutePhone?: string | null;
      substituteRelation?: string | null;
      externalId?: string | null;
      fee?: number | null;
      alternativeDispensing?: string | null;
      estimatedDispensingTime?: any | null;
      reason?: string | null;
    }> | null;
  };
};

export type PrescriptionFileUrlQueryVariables = Exact<{
  prescriptionId: Scalars['String'];
}>;

export type PrescriptionFileUrlQuery = {
  __typename?: 'Query';
  prescription: { __typename?: 'Prescription'; fileUrls?: Array<string> | null };
};

export type RequestPrescriptionFeeMutationVariables = Exact<{
  input: RequestPrescriptionFeeInput;
}>;

export type RequestPrescriptionFeeMutation = {
  __typename?: 'Mutation';
  requestPrescriptionFee: {
    __typename?: 'RequestPrescriptionFeeOutput';
    code: number;
    message: string;
    prescriptionCode: string;
  };
};

export type RequestVisitPharmacyMutationVariables = Exact<{
  input: RequestVisitPharmacyInput;
}>;

export type RequestVisitPharmacyMutation = {
  __typename?: 'Mutation';
  requestVisitPharmacy: {
    __typename?: 'RequestVisitPharmacyOutput';
    code: number;
    message: string;
    prescriptionCode: string;
  };
};

export type SearchVisitPharmaciesQueryVariables = Exact<{
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  distance?: InputMaybe<Scalars['Int']>;
}>;

export type SearchVisitPharmaciesQuery = {
  __typename?: 'Query';
  searchVisitPharmacies: {
    __typename?: 'VisitPharmaciesOutput';
    code: number;
    message: string;
    totalCount?: number | null;
    pharmacies: Array<{
      __typename?: 'VisitPharmacy';
      name: string;
      phone?: string | null;
      address?: string | null;
      longitude?: number | null;
      latitude?: number | null;
      distance: number;
      operationTimeDescription?: string | null;
      pharmacyCode: string;
      zipcode?: string | null;
      openNightYn: string;
      openAllDayYn: string;
      pharmacyOperationTimes: Array<{
        __typename?: 'OperationTime';
        operationType: string;
        from?: string | null;
        to?: string | null;
      }>;
    }>;
  };
};

export type UpdateTreatmentCancelMutationVariables = Exact<{
  treatmentId: Scalars['ID'];
  enablePublish?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateTreatmentCancelMutation = {
  __typename?: 'Mutation';
  updateTreatmentCancel: { __typename?: 'Treatment'; id: string };
};

export type CreateDirectTreatmentMutationVariables = Exact<{
  createTreatmentInput: TreatmentInput;
}>;

export type CreateDirectTreatmentMutation = {
  __typename?: 'Mutation';
  createDirectTreatment: { __typename?: 'TreatmentOnCreate'; id: string };
};

export type CreateSymptomImageMutationVariables = Exact<{
  fileNames: Array<Scalars['String']> | Scalars['String'];
}>;

export type CreateSymptomImageMutation = {
  __typename?: 'Mutation';
  createSymptomImage: Array<{
    __typename?: 'CreateSymptomImage';
    s3Key: string;
    url: string;
  }>;
};

export type RecentTreatmentQueryVariables = Exact<{ [key: string]: never }>;

export type RecentTreatmentQuery = {
  __typename?: 'Query';
  recentTreatment: {
    __typename?: 'RecentTreatment';
    treatment?: {
      __typename?: 'Treatment';
      id: string;
      status: TreatmentStatusEnum;
      isDirect?: boolean | null;
      callbackRequest: boolean;
      treatmentWaitingTime?: any | null;
      doctor?: {
        __typename?: 'Doctor';
        id: string;
        name: string;
        image?: string | null;
        untactOnTreatment: boolean;
        showAwardBadge?: boolean | null;
        hospital: { __typename?: 'Hospital'; id: string; name: string };
      } | null;
      department: { __typename?: 'UntactDepartment'; name: string };
    } | null;
  };
};

export type RevokeSymptomImageMutationVariables = Exact<{
  resourceKeys: Array<Scalars['String']> | Scalars['String'];
}>;

export type RevokeSymptomImageMutation = {
  __typename?: 'Mutation';
  revokeSymptomImage: boolean;
};

export type TreatmentHistoryDetailV3QueryVariables = Exact<{
  treatmentId: Scalars['ID'];
}>;

export type TreatmentHistoryDetailV3Query = {
  __typename?: 'Query';
  treatmentV3: {
    __typename?: 'Treatment';
    id: string;
    status: TreatmentStatusEnum;
    symptomDescription?: string | null;
    createdAt: any;
    paymentStatus?: PaymentStatusEnum | null;
    paymentMethodType?: PaymentMethodTypeEnum | null;
    cardName?: string | null;
    fee?: number | null;
    prescription?: {
      __typename?: 'Prescription';
      id: string;
      createdAt: any;
      updatedAt: any;
      prescriptionDispatchHistoryId?: string | null;
      status: PrescriptionStatusEnum;
      prescriptionDispatchHistories?: Array<{
        __typename?: 'PrescriptionDispatchHistory';
        pharmacyName: string;
        createdAt: any;
      }> | null;
    } | null;
    untactQuestionnaireResponse?: Array<{
      __typename?: 'UntactQuestionnaireResponse';
      id: string;
      questionnaireText: string;
      treatmentId: string;
      createdAt: any;
    } | null> | null;
    doctor?: {
      __typename?: 'Doctor';
      id: string;
      name: string;
      type: MedicalTypeEnum;
      hospital: { __typename?: 'Hospital'; id: string; name: string };
    } | null;
    patient: { __typename?: 'Patient'; id: string; name: string; phone?: string | null };
    department: { __typename?: 'UntactDepartment'; id: string; name: string };
  };
};

export type TreatmentPaymentsQueryVariables = Exact<{
  treatmentId: Scalars['ID'];
}>;

export type TreatmentPaymentsQuery = {
  __typename?: 'Query';
  treatmentV3: {
    __typename?: 'Treatment';
    id: string;
    fee?: number | null;
    paymentStatus?: PaymentStatusEnum | null;
    paymentMethodType?: PaymentMethodTypeEnum | null;
    patient: { __typename?: 'Patient'; name: string };
    doctor?: {
      __typename?: 'Doctor';
      hospital: {
        __typename?: 'Hospital';
        name: string;
        phone?: string | null;
        bankAccount?: {
          __typename?: 'UntactHospitalBankAccount';
          accountNumber: string;
          bankName: string;
          accountHolderName: string;
        } | null;
      };
    } | null;
  };
};

export const MeDocument = gql`
  query me {
    me {
      id
      name
      phone
      yymmddi
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SignInDeviceDocument = gql`
  mutation signInDevice($input: SignInDeviceInput!) {
    signIn: signInDevice(data: $input) {
      accessToken: token
    }
  }
`;
export type SignInDeviceMutationFn = Apollo.MutationFunction<
  SignInDeviceMutation,
  SignInDeviceMutationVariables
>;

/**
 * __useSignInDeviceMutation__
 *
 * To run a mutation, you first call `useSignInDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInDeviceMutation, { data, loading, error }] = useSignInDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInDeviceMutation,
    SignInDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInDeviceMutation, SignInDeviceMutationVariables>(
    SignInDeviceDocument,
    options
  );
}
export type SignInDeviceMutationHookResult = ReturnType<typeof useSignInDeviceMutation>;
export type SignInDeviceMutationResult = Apollo.MutationResult<SignInDeviceMutation>;
export type SignInDeviceMutationOptions = Apollo.BaseMutationOptions<
  SignInDeviceMutation,
  SignInDeviceMutationVariables
>;
export const SignInSocialDocument = gql`
  mutation signInSocial($input: SocialSignInInput!) {
    signIn: socialSignIn(data: $input) {
      accessToken: token
    }
  }
`;
export type SignInSocialMutationFn = Apollo.MutationFunction<
  SignInSocialMutation,
  SignInSocialMutationVariables
>;

/**
 * __useSignInSocialMutation__
 *
 * To run a mutation, you first call `useSignInSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInSocialMutation, { data, loading, error }] = useSignInSocialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInSocialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInSocialMutation,
    SignInSocialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInSocialMutation, SignInSocialMutationVariables>(
    SignInSocialDocument,
    options
  );
}
export type SignInSocialMutationHookResult = ReturnType<typeof useSignInSocialMutation>;
export type SignInSocialMutationResult = Apollo.MutationResult<SignInSocialMutation>;
export type SignInSocialMutationOptions = Apollo.BaseMutationOptions<
  SignInSocialMutation,
  SignInSocialMutationVariables
>;
export const SpecialistDepartmentsDocument = gql`
  query specialistDepartments($existsDoctor: Boolean) {
    specialistDepartments(existsDoctor: $existsDoctor) {
      id
      name
      displayOrder: sort
    }
  }
`;

/**
 * __useSpecialistDepartmentsQuery__
 *
 * To run a query within a React component, call `useSpecialistDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialistDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialistDepartmentsQuery({
 *   variables: {
 *      existsDoctor: // value for 'existsDoctor'
 *   },
 * });
 */
export function useSpecialistDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpecialistDepartmentsQuery,
    SpecialistDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpecialistDepartmentsQuery, SpecialistDepartmentsQueryVariables>(
    SpecialistDepartmentsDocument,
    options
  );
}
export function useSpecialistDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpecialistDepartmentsQuery,
    SpecialistDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpecialistDepartmentsQuery,
    SpecialistDepartmentsQueryVariables
  >(SpecialistDepartmentsDocument, options);
}
export type SpecialistDepartmentsQueryHookResult = ReturnType<
  typeof useSpecialistDepartmentsQuery
>;
export type SpecialistDepartmentsLazyQueryHookResult = ReturnType<
  typeof useSpecialistDepartmentsLazyQuery
>;
export type SpecialistDepartmentsQueryResult = Apollo.QueryResult<
  SpecialistDepartmentsQuery,
  SpecialistDepartmentsQueryVariables
>;
export const UntactDepartmentsDocument = gql`
  query untactDepartments($input: UntactDepartmentQueryInput) {
    untactDepartments(input: $input) {
      id
      name
      image
      type
      displayOrder
    }
  }
`;

/**
 * __useUntactDepartmentsQuery__
 *
 * To run a query within a React component, call `useUntactDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUntactDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUntactDepartmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUntactDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UntactDepartmentsQuery,
    UntactDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UntactDepartmentsQuery, UntactDepartmentsQueryVariables>(
    UntactDepartmentsDocument,
    options
  );
}
export function useUntactDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UntactDepartmentsQuery,
    UntactDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UntactDepartmentsQuery, UntactDepartmentsQueryVariables>(
    UntactDepartmentsDocument,
    options
  );
}
export type UntactDepartmentsQueryHookResult = ReturnType<
  typeof useUntactDepartmentsQuery
>;
export type UntactDepartmentsLazyQueryHookResult = ReturnType<
  typeof useUntactDepartmentsLazyQuery
>;
export type UntactDepartmentsQueryResult = Apollo.QueryResult<
  UntactDepartmentsQuery,
  UntactDepartmentsQueryVariables
>;
export const UntactDepartmentsDoctorsDocument = gql`
  query untactDepartmentsDoctors($input: UntactDepartmentsDoctorsQueryInput!) {
    untactDepartmentsDoctors(input: $input) {
      doctors {
        id
        name
        defaultImage
        hospital {
          name
          id
          address
        }
        position
        image
        reviewSummary {
          reviewsCount
          ratingMean
        }
        untactOnTreatment
        showAwardBadge
        doctorSpecialistDepartments {
          representative
          specialistDepartment {
            name
          }
        }
      }
      untactDepartmentId
    }
  }
`;

/**
 * __useUntactDepartmentsDoctorsQuery__
 *
 * To run a query within a React component, call `useUntactDepartmentsDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUntactDepartmentsDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUntactDepartmentsDoctorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUntactDepartmentsDoctorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UntactDepartmentsDoctorsQuery,
    UntactDepartmentsDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UntactDepartmentsDoctorsQuery,
    UntactDepartmentsDoctorsQueryVariables
  >(UntactDepartmentsDoctorsDocument, options);
}
export function useUntactDepartmentsDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UntactDepartmentsDoctorsQuery,
    UntactDepartmentsDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UntactDepartmentsDoctorsQuery,
    UntactDepartmentsDoctorsQueryVariables
  >(UntactDepartmentsDoctorsDocument, options);
}
export type UntactDepartmentsDoctorsQueryHookResult = ReturnType<
  typeof useUntactDepartmentsDoctorsQuery
>;
export type UntactDepartmentsDoctorsLazyQueryHookResult = ReturnType<
  typeof useUntactDepartmentsDoctorsLazyQuery
>;
export type UntactDepartmentsDoctorsQueryResult = Apollo.QueryResult<
  UntactDepartmentsDoctorsQuery,
  UntactDepartmentsDoctorsQueryVariables
>;
export const UntactDoctorDocument = gql`
  query untactDoctor($doctorId: ID!) {
    doctor: untactDoctor(id: $doctorId) {
      id
      name
      image
      untactOnTreatment
      type
      untactNotice
      profile
      doctorSpecialistDepartments {
        representative
        specialistDepartment {
          name
        }
      }
      reviewSummary {
        reviewsCount
      }
      untactDoctorTreatmentSummary {
        treatmentCompletionCount
      }
      untactConfiguration {
        operationTime
      }
      hospital {
        id
        name
        address
      }
      doctorUntactDepartments {
        id
        untactDepartment {
          name
          id
        }
      }
    }
  }
`;

/**
 * __useUntactDoctorQuery__
 *
 * To run a query within a React component, call `useUntactDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUntactDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUntactDoctorQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useUntactDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<UntactDoctorQuery, UntactDoctorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UntactDoctorQuery, UntactDoctorQueryVariables>(
    UntactDoctorDocument,
    options
  );
}
export function useUntactDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UntactDoctorQuery, UntactDoctorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UntactDoctorQuery, UntactDoctorQueryVariables>(
    UntactDoctorDocument,
    options
  );
}
export type UntactDoctorQueryHookResult = ReturnType<typeof useUntactDoctorQuery>;
export type UntactDoctorLazyQueryHookResult = ReturnType<typeof useUntactDoctorLazyQuery>;
export type UntactDoctorQueryResult = Apollo.QueryResult<
  UntactDoctorQuery,
  UntactDoctorQueryVariables
>;
export const UntactSpecialistDepartmentDoctorsDocument = gql`
  query untactSpecialistDepartmentDoctors($specialistDepartmentId: ID!) {
    untactSpecialistDepartmentDoctors(specialistDepartmentId: $specialistDepartmentId) {
      id
      name
      defaultImage
      hospital {
        name
        id
        address
      }
      position
      image
      reviewSummary {
        reviewsCount
        ratingMean
      }
      untactOnTreatment
      showAwardBadge
      doctorSpecialistDepartments {
        representative
        specialistDepartment {
          name
        }
      }
    }
  }
`;

/**
 * __useUntactSpecialistDepartmentDoctorsQuery__
 *
 * To run a query within a React component, call `useUntactSpecialistDepartmentDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUntactSpecialistDepartmentDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUntactSpecialistDepartmentDoctorsQuery({
 *   variables: {
 *      specialistDepartmentId: // value for 'specialistDepartmentId'
 *   },
 * });
 */
export function useUntactSpecialistDepartmentDoctorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UntactSpecialistDepartmentDoctorsQuery,
    UntactSpecialistDepartmentDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UntactSpecialistDepartmentDoctorsQuery,
    UntactSpecialistDepartmentDoctorsQueryVariables
  >(UntactSpecialistDepartmentDoctorsDocument, options);
}
export function useUntactSpecialistDepartmentDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UntactSpecialistDepartmentDoctorsQuery,
    UntactSpecialistDepartmentDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UntactSpecialistDepartmentDoctorsQuery,
    UntactSpecialistDepartmentDoctorsQueryVariables
  >(UntactSpecialistDepartmentDoctorsDocument, options);
}
export type UntactSpecialistDepartmentDoctorsQueryHookResult = ReturnType<
  typeof useUntactSpecialistDepartmentDoctorsQuery
>;
export type UntactSpecialistDepartmentDoctorsLazyQueryHookResult = ReturnType<
  typeof useUntactSpecialistDepartmentDoctorsLazyQuery
>;
export type UntactSpecialistDepartmentDoctorsQueryResult = Apollo.QueryResult<
  UntactSpecialistDepartmentDoctorsQuery,
  UntactSpecialistDepartmentDoctorsQueryVariables
>;
export const HospitalDocument = gql`
  query hospital($id: ID!) {
    data: hospital(id: $id) {
      id
      name
      address
      defaultImage
      operationTimes {
        from
        to
        targetDate
        holidayName
        operationOff
        operationType
        id
      }
      operatingState
      operationTimeDescription
      onUntactTreatment
      hospitalPictures
      phone
      safetyPhone
      medicalServices {
        id
        serviceType
        displayName
        description
        price
        medicalCategory {
          name
        }
      }
    }
  }
`;

/**
 * __useHospitalQuery__
 *
 * To run a query within a React component, call `useHospitalQuery` and pass it any options that fit your needs.
 * When your component renders, `useHospitalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHospitalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHospitalQuery(
  baseOptions: Apollo.QueryHookOptions<HospitalQuery, HospitalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HospitalQuery, HospitalQueryVariables>(
    HospitalDocument,
    options
  );
}
export function useHospitalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HospitalQuery, HospitalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HospitalQuery, HospitalQueryVariables>(
    HospitalDocument,
    options
  );
}
export type HospitalQueryHookResult = ReturnType<typeof useHospitalQuery>;
export type HospitalLazyQueryHookResult = ReturnType<typeof useHospitalLazyQuery>;
export type HospitalQueryResult = Apollo.QueryResult<
  HospitalQuery,
  HospitalQueryVariables
>;
export const UntactHospitalDoctorsDocument = gql`
  query untactHospitalDoctors($hospitalId: ID!) {
    untactHospitalDoctors(hospitalId: $hospitalId) {
      id
      name
      image
      type
      untactOnTreatment
      doctorSpecialistDepartments {
        representative
        specialistDepartment {
          name
        }
      }
    }
  }
`;

/**
 * __useUntactHospitalDoctorsQuery__
 *
 * To run a query within a React component, call `useUntactHospitalDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUntactHospitalDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUntactHospitalDoctorsQuery({
 *   variables: {
 *      hospitalId: // value for 'hospitalId'
 *   },
 * });
 */
export function useUntactHospitalDoctorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UntactHospitalDoctorsQuery,
    UntactHospitalDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UntactHospitalDoctorsQuery, UntactHospitalDoctorsQueryVariables>(
    UntactHospitalDoctorsDocument,
    options
  );
}
export function useUntactHospitalDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UntactHospitalDoctorsQuery,
    UntactHospitalDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UntactHospitalDoctorsQuery,
    UntactHospitalDoctorsQueryVariables
  >(UntactHospitalDoctorsDocument, options);
}
export type UntactHospitalDoctorsQueryHookResult = ReturnType<
  typeof useUntactHospitalDoctorsQuery
>;
export type UntactHospitalDoctorsLazyQueryHookResult = ReturnType<
  typeof useUntactHospitalDoctorsLazyQuery
>;
export type UntactHospitalDoctorsQueryResult = Apollo.QueryResult<
  UntactHospitalDoctorsQuery,
  UntactHospitalDoctorsQueryVariables
>;
export const MetaDoctorDocument = gql`
  query metaDoctor($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      name
      image
      type
      untactVisibility
      doctorUntactDepartments {
        untactDepartment {
          name
        }
      }
      hospital {
        name
        address
        hospitalDepartments {
          department {
            name
          }
        }
        hospitalTags {
          name
        }
      }
    }
  }
`;

/**
 * __useMetaDoctorQuery__
 *
 * To run a query within a React component, call `useMetaDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaDoctorQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useMetaDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<MetaDoctorQuery, MetaDoctorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetaDoctorQuery, MetaDoctorQueryVariables>(
    MetaDoctorDocument,
    options
  );
}
export function useMetaDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetaDoctorQuery, MetaDoctorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetaDoctorQuery, MetaDoctorQueryVariables>(
    MetaDoctorDocument,
    options
  );
}
export type MetaDoctorQueryHookResult = ReturnType<typeof useMetaDoctorQuery>;
export type MetaDoctorLazyQueryHookResult = ReturnType<typeof useMetaDoctorLazyQuery>;
export type MetaDoctorQueryResult = Apollo.QueryResult<
  MetaDoctorQuery,
  MetaDoctorQueryVariables
>;
export const MetaHospitalDocument = gql`
  query metaHospital($hospitalId: ID!) {
    hospital(id: $hospitalId) {
      id
      name
      address
      doctors {
        name
        type
        untactVisibility
        doctorUntactDepartments {
          untactDepartment {
            name
          }
        }
      }
      hospitalDepartments {
        department {
          name
        }
      }
      hospitalTags {
        name
      }
    }
  }
`;

/**
 * __useMetaHospitalQuery__
 *
 * To run a query within a React component, call `useMetaHospitalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaHospitalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaHospitalQuery({
 *   variables: {
 *      hospitalId: // value for 'hospitalId'
 *   },
 * });
 */
export function useMetaHospitalQuery(
  baseOptions: Apollo.QueryHookOptions<MetaHospitalQuery, MetaHospitalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetaHospitalQuery, MetaHospitalQueryVariables>(
    MetaHospitalDocument,
    options
  );
}
export function useMetaHospitalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetaHospitalQuery, MetaHospitalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetaHospitalQuery, MetaHospitalQueryVariables>(
    MetaHospitalDocument,
    options
  );
}
export type MetaHospitalQueryHookResult = ReturnType<typeof useMetaHospitalQuery>;
export type MetaHospitalLazyQueryHookResult = ReturnType<typeof useMetaHospitalLazyQuery>;
export type MetaHospitalQueryResult = Apollo.QueryResult<
  MetaHospitalQuery,
  MetaHospitalQueryVariables
>;
export const CreatePatientDocument = gql`
  mutation createPatient($name: String!, $residentRegistrationNumber: String!) {
    createPatient(
      data: { name: $name, residentRegistrationNumber: $residentRegistrationNumber }
    ) {
      id
    }
  }
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      name: // value for 'name'
 *      residentRegistrationNumber: // value for 'residentRegistrationNumber'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(
    CreatePatientDocument,
    options
  );
}
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const PatientsDocument = gql`
  query patients($patientId: String) {
    patients(id: $patientId) {
      id
      name
      residentRegistrationNumber
    }
  }
`;

/**
 * __usePatientsQuery__
 *
 * To run a query within a React component, call `usePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientsQuery, PatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export function usePatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientsQuery, PatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export type PatientsQueryHookResult = ReturnType<typeof usePatientsQuery>;
export type PatientsLazyQueryHookResult = ReturnType<typeof usePatientsLazyQuery>;
export type PatientsQueryResult = Apollo.QueryResult<
  PatientsQuery,
  PatientsQueryVariables
>;
export const ApproveKakaopayRequestDocument = gql`
  mutation approveKakaopayRequest($pg_token: String!, $tid: String!) {
    approveKakaopayRequest(data: { pg_token: $pg_token, tid: $tid })
  }
`;
export type ApproveKakaopayRequestMutationFn = Apollo.MutationFunction<
  ApproveKakaopayRequestMutation,
  ApproveKakaopayRequestMutationVariables
>;

/**
 * __useApproveKakaopayRequestMutation__
 *
 * To run a mutation, you first call `useApproveKakaopayRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveKakaopayRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveKakaopayRequestMutation, { data, loading, error }] = useApproveKakaopayRequestMutation({
 *   variables: {
 *      pg_token: // value for 'pg_token'
 *      tid: // value for 'tid'
 *   },
 * });
 */
export function useApproveKakaopayRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveKakaopayRequestMutation,
    ApproveKakaopayRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveKakaopayRequestMutation,
    ApproveKakaopayRequestMutationVariables
  >(ApproveKakaopayRequestDocument, options);
}
export type ApproveKakaopayRequestMutationHookResult = ReturnType<
  typeof useApproveKakaopayRequestMutation
>;
export type ApproveKakaopayRequestMutationResult =
  Apollo.MutationResult<ApproveKakaopayRequestMutation>;
export type ApproveKakaopayRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveKakaopayRequestMutation,
  ApproveKakaopayRequestMutationVariables
>;
export const CreateKakaopayRequestDocument = gql`
  mutation createKakaopayRequest($requestType: KakaopayRequestTypeEnum) {
    createKakaopayRequest(requestType: $requestType) {
      next_redirect_pc_url
      next_redirect_mobile_url
      tid
    }
  }
`;
export type CreateKakaopayRequestMutationFn = Apollo.MutationFunction<
  CreateKakaopayRequestMutation,
  CreateKakaopayRequestMutationVariables
>;

/**
 * __useCreateKakaopayRequestMutation__
 *
 * To run a mutation, you first call `useCreateKakaopayRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKakaopayRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKakaopayRequestMutation, { data, loading, error }] = useCreateKakaopayRequestMutation({
 *   variables: {
 *      requestType: // value for 'requestType'
 *   },
 * });
 */
export function useCreateKakaopayRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKakaopayRequestMutation,
    CreateKakaopayRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKakaopayRequestMutation,
    CreateKakaopayRequestMutationVariables
  >(CreateKakaopayRequestDocument, options);
}
export type CreateKakaopayRequestMutationHookResult = ReturnType<
  typeof useCreateKakaopayRequestMutation
>;
export type CreateKakaopayRequestMutationResult =
  Apollo.MutationResult<CreateKakaopayRequestMutation>;
export type CreateKakaopayRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateKakaopayRequestMutation,
  CreateKakaopayRequestMutationVariables
>;
export const CreateTreatmentPaymentDocument = gql`
  mutation createTreatmentPayment(
    $paymentMethodId: ID!
    $treatmentId: ID!
    $treatmentFee: Float!
    $signData: String
    $installmentMonths: Float
  ) {
    createTreatmentPayment(
      paymentMethodId: $paymentMethodId
      treatmentId: $treatmentId
      treatmentFee: $treatmentFee
      signData: $signData
      installmentMonths: $installmentMonths
    ) {
      status
      treatmentPayments {
        price
        paymentId
        paymentStatus
      }
    }
  }
`;
export type CreateTreatmentPaymentMutationFn = Apollo.MutationFunction<
  CreateTreatmentPaymentMutation,
  CreateTreatmentPaymentMutationVariables
>;

/**
 * __useCreateTreatmentPaymentMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentPaymentMutation, { data, loading, error }] = useCreateTreatmentPaymentMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      treatmentId: // value for 'treatmentId'
 *      treatmentFee: // value for 'treatmentFee'
 *      signData: // value for 'signData'
 *      installmentMonths: // value for 'installmentMonths'
 *   },
 * });
 */
export function useCreateTreatmentPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTreatmentPaymentMutation,
    CreateTreatmentPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTreatmentPaymentMutation,
    CreateTreatmentPaymentMutationVariables
  >(CreateTreatmentPaymentDocument, options);
}
export type CreateTreatmentPaymentMutationHookResult = ReturnType<
  typeof useCreateTreatmentPaymentMutation
>;
export type CreateTreatmentPaymentMutationResult =
  Apollo.MutationResult<CreateTreatmentPaymentMutation>;
export type CreateTreatmentPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateTreatmentPaymentMutation,
  CreateTreatmentPaymentMutationVariables
>;
export const PaymentMethodsDocument = gql`
  query paymentMethods {
    paymentMethods {
      id
      clientMethodId
      lastCardNumber
      cardName
      default
      status
      provider
      userId
    }
  }
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const RequestBankTransferConfirmationDocument = gql`
  mutation requestBankTransferConfirmation($treatmentId: ID!) {
    requestBankTransferConfirmation(treatmentId: $treatmentId)
  }
`;
export type RequestBankTransferConfirmationMutationFn = Apollo.MutationFunction<
  RequestBankTransferConfirmationMutation,
  RequestBankTransferConfirmationMutationVariables
>;

/**
 * __useRequestBankTransferConfirmationMutation__
 *
 * To run a mutation, you first call `useRequestBankTransferConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBankTransferConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBankTransferConfirmationMutation, { data, loading, error }] = useRequestBankTransferConfirmationMutation({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *   },
 * });
 */
export function useRequestBankTransferConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestBankTransferConfirmationMutation,
    RequestBankTransferConfirmationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestBankTransferConfirmationMutation,
    RequestBankTransferConfirmationMutationVariables
  >(RequestBankTransferConfirmationDocument, options);
}
export type RequestBankTransferConfirmationMutationHookResult = ReturnType<
  typeof useRequestBankTransferConfirmationMutation
>;
export type RequestBankTransferConfirmationMutationResult =
  Apollo.MutationResult<RequestBankTransferConfirmationMutation>;
export type RequestBankTransferConfirmationMutationOptions = Apollo.BaseMutationOptions<
  RequestBankTransferConfirmationMutation,
  RequestBankTransferConfirmationMutationVariables
>;
export const SelectPaymentMethodToBankTransferDocument = gql`
  mutation selectPaymentMethodToBankTransfer($treatmentId: ID!, $treatmentFee: Float!) {
    selectPaymentMethodToBankTransfer(
      treatmentId: $treatmentId
      treatmentFee: $treatmentFee
    ) {
      id
      isPaid
      fee
      paymentId
      paymentStatus
      prescriptionState
      paymentMethodType
    }
  }
`;
export type SelectPaymentMethodToBankTransferMutationFn = Apollo.MutationFunction<
  SelectPaymentMethodToBankTransferMutation,
  SelectPaymentMethodToBankTransferMutationVariables
>;

/**
 * __useSelectPaymentMethodToBankTransferMutation__
 *
 * To run a mutation, you first call `useSelectPaymentMethodToBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectPaymentMethodToBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectPaymentMethodToBankTransferMutation, { data, loading, error }] = useSelectPaymentMethodToBankTransferMutation({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *      treatmentFee: // value for 'treatmentFee'
 *   },
 * });
 */
export function useSelectPaymentMethodToBankTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectPaymentMethodToBankTransferMutation,
    SelectPaymentMethodToBankTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SelectPaymentMethodToBankTransferMutation,
    SelectPaymentMethodToBankTransferMutationVariables
  >(SelectPaymentMethodToBankTransferDocument, options);
}
export type SelectPaymentMethodToBankTransferMutationHookResult = ReturnType<
  typeof useSelectPaymentMethodToBankTransferMutation
>;
export type SelectPaymentMethodToBankTransferMutationResult =
  Apollo.MutationResult<SelectPaymentMethodToBankTransferMutation>;
export type SelectPaymentMethodToBankTransferMutationOptions = Apollo.BaseMutationOptions<
  SelectPaymentMethodToBankTransferMutation,
  SelectPaymentMethodToBankTransferMutationVariables
>;
export const CancelVisitPharmacyDocument = gql`
  mutation CancelVisitPharmacy($treatmentId: ID!, $reason: String) {
    cancelVisitPharmacy(treatmentId: $treatmentId, reason: $reason) {
      code
      message
      prescriptionCode
    }
  }
`;
export type CancelVisitPharmacyMutationFn = Apollo.MutationFunction<
  CancelVisitPharmacyMutation,
  CancelVisitPharmacyMutationVariables
>;

/**
 * __useCancelVisitPharmacyMutation__
 *
 * To run a mutation, you first call `useCancelVisitPharmacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVisitPharmacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVisitPharmacyMutation, { data, loading, error }] = useCancelVisitPharmacyMutation({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelVisitPharmacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelVisitPharmacyMutation,
    CancelVisitPharmacyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelVisitPharmacyMutation,
    CancelVisitPharmacyMutationVariables
  >(CancelVisitPharmacyDocument, options);
}
export type CancelVisitPharmacyMutationHookResult = ReturnType<
  typeof useCancelVisitPharmacyMutation
>;
export type CancelVisitPharmacyMutationResult =
  Apollo.MutationResult<CancelVisitPharmacyMutation>;
export type CancelVisitPharmacyMutationOptions = Apollo.BaseMutationOptions<
  CancelVisitPharmacyMutation,
  CancelVisitPharmacyMutationVariables
>;
export const PrescriptionDocument = gql`
  query prescription($prescriptionId: String!) {
    prescription(id: $prescriptionId) {
      treatmentId
      prescriptionDispatchHistories {
        id
        createdAt
        updatedAt
        prescriptionId
        status
        patientName
        patientPhone
        patientRequests
        pharmacyCode
        pharmacyName
        pharmacyPhone
        pharmacyAddress
        pharmacyBankName
        pharmacyBankAccount
        pharmacyAccountHolder
        receiptType
        estimateVisitTime
        substituteName
        substitutePhone
        substituteRelation
        externalId
        fee
        alternativeDispensing
        estimatedDispensingTime
        reason
      }
    }
  }
`;

/**
 * __usePrescriptionQuery__
 *
 * To run a query within a React component, call `usePrescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function usePrescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrescriptionQuery, PrescriptionQueryVariables>(
    PrescriptionDocument,
    options
  );
}
export function usePrescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrescriptionQuery, PrescriptionQueryVariables>(
    PrescriptionDocument,
    options
  );
}
export type PrescriptionQueryHookResult = ReturnType<typeof usePrescriptionQuery>;
export type PrescriptionLazyQueryHookResult = ReturnType<typeof usePrescriptionLazyQuery>;
export type PrescriptionQueryResult = Apollo.QueryResult<
  PrescriptionQuery,
  PrescriptionQueryVariables
>;
export const PrescriptionFileUrlDocument = gql`
  query prescriptionFileUrl($prescriptionId: String!) {
    prescription(id: $prescriptionId) {
      fileUrls
    }
  }
`;

/**
 * __usePrescriptionFileUrlQuery__
 *
 * To run a query within a React component, call `usePrescriptionFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionFileUrlQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function usePrescriptionFileUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    PrescriptionFileUrlQuery,
    PrescriptionFileUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrescriptionFileUrlQuery, PrescriptionFileUrlQueryVariables>(
    PrescriptionFileUrlDocument,
    options
  );
}
export function usePrescriptionFileUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrescriptionFileUrlQuery,
    PrescriptionFileUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrescriptionFileUrlQuery, PrescriptionFileUrlQueryVariables>(
    PrescriptionFileUrlDocument,
    options
  );
}
export type PrescriptionFileUrlQueryHookResult = ReturnType<
  typeof usePrescriptionFileUrlQuery
>;
export type PrescriptionFileUrlLazyQueryHookResult = ReturnType<
  typeof usePrescriptionFileUrlLazyQuery
>;
export type PrescriptionFileUrlQueryResult = Apollo.QueryResult<
  PrescriptionFileUrlQuery,
  PrescriptionFileUrlQueryVariables
>;
export const RequestPrescriptionFeeDocument = gql`
  mutation requestPrescriptionFee($input: RequestPrescriptionFeeInput!) {
    requestPrescriptionFee(input: $input) {
      code
      message
      prescriptionCode
    }
  }
`;
export type RequestPrescriptionFeeMutationFn = Apollo.MutationFunction<
  RequestPrescriptionFeeMutation,
  RequestPrescriptionFeeMutationVariables
>;

/**
 * __useRequestPrescriptionFeeMutation__
 *
 * To run a mutation, you first call `useRequestPrescriptionFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPrescriptionFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPrescriptionFeeMutation, { data, loading, error }] = useRequestPrescriptionFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPrescriptionFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPrescriptionFeeMutation,
    RequestPrescriptionFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPrescriptionFeeMutation,
    RequestPrescriptionFeeMutationVariables
  >(RequestPrescriptionFeeDocument, options);
}
export type RequestPrescriptionFeeMutationHookResult = ReturnType<
  typeof useRequestPrescriptionFeeMutation
>;
export type RequestPrescriptionFeeMutationResult =
  Apollo.MutationResult<RequestPrescriptionFeeMutation>;
export type RequestPrescriptionFeeMutationOptions = Apollo.BaseMutationOptions<
  RequestPrescriptionFeeMutation,
  RequestPrescriptionFeeMutationVariables
>;
export const RequestVisitPharmacyDocument = gql`
  mutation RequestVisitPharmacy($input: RequestVisitPharmacyInput!) {
    requestVisitPharmacy(input: $input) {
      code
      message
      prescriptionCode
    }
  }
`;
export type RequestVisitPharmacyMutationFn = Apollo.MutationFunction<
  RequestVisitPharmacyMutation,
  RequestVisitPharmacyMutationVariables
>;

/**
 * __useRequestVisitPharmacyMutation__
 *
 * To run a mutation, you first call `useRequestVisitPharmacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVisitPharmacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVisitPharmacyMutation, { data, loading, error }] = useRequestVisitPharmacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestVisitPharmacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestVisitPharmacyMutation,
    RequestVisitPharmacyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestVisitPharmacyMutation,
    RequestVisitPharmacyMutationVariables
  >(RequestVisitPharmacyDocument, options);
}
export type RequestVisitPharmacyMutationHookResult = ReturnType<
  typeof useRequestVisitPharmacyMutation
>;
export type RequestVisitPharmacyMutationResult =
  Apollo.MutationResult<RequestVisitPharmacyMutation>;
export type RequestVisitPharmacyMutationOptions = Apollo.BaseMutationOptions<
  RequestVisitPharmacyMutation,
  RequestVisitPharmacyMutationVariables
>;
export const SearchVisitPharmaciesDocument = gql`
  query searchVisitPharmacies($latitude: Float!, $longitude: Float!, $distance: Int) {
    searchVisitPharmacies(
      latitude: $latitude
      longitude: $longitude
      distance: $distance
    ) {
      code
      message
      pharmacies {
        name
        phone
        address
        longitude
        latitude
        distance
        operationTimeDescription
        pharmacyOperationTimes {
          operationType
          from
          to
        }
        pharmacyCode
        zipcode
        openNightYn
        openAllDayYn
      }
      totalCount
    }
  }
`;

/**
 * __useSearchVisitPharmaciesQuery__
 *
 * To run a query within a React component, call `useSearchVisitPharmaciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVisitPharmaciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVisitPharmaciesQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useSearchVisitPharmaciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchVisitPharmaciesQuery,
    SearchVisitPharmaciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchVisitPharmaciesQuery, SearchVisitPharmaciesQueryVariables>(
    SearchVisitPharmaciesDocument,
    options
  );
}
export function useSearchVisitPharmaciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchVisitPharmaciesQuery,
    SearchVisitPharmaciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchVisitPharmaciesQuery,
    SearchVisitPharmaciesQueryVariables
  >(SearchVisitPharmaciesDocument, options);
}
export type SearchVisitPharmaciesQueryHookResult = ReturnType<
  typeof useSearchVisitPharmaciesQuery
>;
export type SearchVisitPharmaciesLazyQueryHookResult = ReturnType<
  typeof useSearchVisitPharmaciesLazyQuery
>;
export type SearchVisitPharmaciesQueryResult = Apollo.QueryResult<
  SearchVisitPharmaciesQuery,
  SearchVisitPharmaciesQueryVariables
>;
export const UpdateTreatmentCancelDocument = gql`
  mutation updateTreatmentCancel($treatmentId: ID!, $enablePublish: Boolean) {
    updateTreatmentCancel(treatmentId: $treatmentId, enablePublish: $enablePublish) {
      id
    }
  }
`;
export type UpdateTreatmentCancelMutationFn = Apollo.MutationFunction<
  UpdateTreatmentCancelMutation,
  UpdateTreatmentCancelMutationVariables
>;

/**
 * __useUpdateTreatmentCancelMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentCancelMutation, { data, loading, error }] = useUpdateTreatmentCancelMutation({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *      enablePublish: // value for 'enablePublish'
 *   },
 * });
 */
export function useUpdateTreatmentCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTreatmentCancelMutation,
    UpdateTreatmentCancelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTreatmentCancelMutation,
    UpdateTreatmentCancelMutationVariables
  >(UpdateTreatmentCancelDocument, options);
}
export type UpdateTreatmentCancelMutationHookResult = ReturnType<
  typeof useUpdateTreatmentCancelMutation
>;
export type UpdateTreatmentCancelMutationResult =
  Apollo.MutationResult<UpdateTreatmentCancelMutation>;
export type UpdateTreatmentCancelMutationOptions = Apollo.BaseMutationOptions<
  UpdateTreatmentCancelMutation,
  UpdateTreatmentCancelMutationVariables
>;
export const CreateDirectTreatmentDocument = gql`
  mutation createDirectTreatment($createTreatmentInput: TreatmentInput!) {
    createDirectTreatment(createTreatmentInput: $createTreatmentInput) {
      id
    }
  }
`;
export type CreateDirectTreatmentMutationFn = Apollo.MutationFunction<
  CreateDirectTreatmentMutation,
  CreateDirectTreatmentMutationVariables
>;

/**
 * __useCreateDirectTreatmentMutation__
 *
 * To run a mutation, you first call `useCreateDirectTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectTreatmentMutation, { data, loading, error }] = useCreateDirectTreatmentMutation({
 *   variables: {
 *      createTreatmentInput: // value for 'createTreatmentInput'
 *   },
 * });
 */
export function useCreateDirectTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectTreatmentMutation,
    CreateDirectTreatmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDirectTreatmentMutation,
    CreateDirectTreatmentMutationVariables
  >(CreateDirectTreatmentDocument, options);
}
export type CreateDirectTreatmentMutationHookResult = ReturnType<
  typeof useCreateDirectTreatmentMutation
>;
export type CreateDirectTreatmentMutationResult =
  Apollo.MutationResult<CreateDirectTreatmentMutation>;
export type CreateDirectTreatmentMutationOptions = Apollo.BaseMutationOptions<
  CreateDirectTreatmentMutation,
  CreateDirectTreatmentMutationVariables
>;
export const CreateSymptomImageDocument = gql`
  mutation createSymptomImage($fileNames: [String!]!) {
    createSymptomImage(fileNames: $fileNames) {
      s3Key
      url
    }
  }
`;
export type CreateSymptomImageMutationFn = Apollo.MutationFunction<
  CreateSymptomImageMutation,
  CreateSymptomImageMutationVariables
>;

/**
 * __useCreateSymptomImageMutation__
 *
 * To run a mutation, you first call `useCreateSymptomImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSymptomImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSymptomImageMutation, { data, loading, error }] = useCreateSymptomImageMutation({
 *   variables: {
 *      fileNames: // value for 'fileNames'
 *   },
 * });
 */
export function useCreateSymptomImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSymptomImageMutation,
    CreateSymptomImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSymptomImageMutation,
    CreateSymptomImageMutationVariables
  >(CreateSymptomImageDocument, options);
}
export type CreateSymptomImageMutationHookResult = ReturnType<
  typeof useCreateSymptomImageMutation
>;
export type CreateSymptomImageMutationResult =
  Apollo.MutationResult<CreateSymptomImageMutation>;
export type CreateSymptomImageMutationOptions = Apollo.BaseMutationOptions<
  CreateSymptomImageMutation,
  CreateSymptomImageMutationVariables
>;
export const RecentTreatmentDocument = gql`
  query recentTreatment {
    recentTreatment {
      treatment {
        id
        status
        isDirect
        callbackRequest
        treatmentWaitingTime
        doctor {
          id
          name
          image
          untactOnTreatment
          hospital {
            id
            name
          }
          showAwardBadge
        }
        department {
          name
        }
      }
    }
  }
`;

/**
 * __useRecentTreatmentQuery__
 *
 * To run a query within a React component, call `useRecentTreatmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTreatmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTreatmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentTreatmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentTreatmentQuery,
    RecentTreatmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTreatmentQuery, RecentTreatmentQueryVariables>(
    RecentTreatmentDocument,
    options
  );
}
export function useRecentTreatmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentTreatmentQuery,
    RecentTreatmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTreatmentQuery, RecentTreatmentQueryVariables>(
    RecentTreatmentDocument,
    options
  );
}
export type RecentTreatmentQueryHookResult = ReturnType<typeof useRecentTreatmentQuery>;
export type RecentTreatmentLazyQueryHookResult = ReturnType<
  typeof useRecentTreatmentLazyQuery
>;
export type RecentTreatmentQueryResult = Apollo.QueryResult<
  RecentTreatmentQuery,
  RecentTreatmentQueryVariables
>;
export const RevokeSymptomImageDocument = gql`
  mutation revokeSymptomImage($resourceKeys: [String!]!) {
    revokeSymptomImage(resourceKeys: $resourceKeys)
  }
`;
export type RevokeSymptomImageMutationFn = Apollo.MutationFunction<
  RevokeSymptomImageMutation,
  RevokeSymptomImageMutationVariables
>;

/**
 * __useRevokeSymptomImageMutation__
 *
 * To run a mutation, you first call `useRevokeSymptomImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSymptomImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSymptomImageMutation, { data, loading, error }] = useRevokeSymptomImageMutation({
 *   variables: {
 *      resourceKeys: // value for 'resourceKeys'
 *   },
 * });
 */
export function useRevokeSymptomImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeSymptomImageMutation,
    RevokeSymptomImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeSymptomImageMutation,
    RevokeSymptomImageMutationVariables
  >(RevokeSymptomImageDocument, options);
}
export type RevokeSymptomImageMutationHookResult = ReturnType<
  typeof useRevokeSymptomImageMutation
>;
export type RevokeSymptomImageMutationResult =
  Apollo.MutationResult<RevokeSymptomImageMutation>;
export type RevokeSymptomImageMutationOptions = Apollo.BaseMutationOptions<
  RevokeSymptomImageMutation,
  RevokeSymptomImageMutationVariables
>;
export const TreatmentHistoryDetailV3Document = gql`
  query treatmentHistoryDetailV3($treatmentId: ID!) {
    treatmentV3(treatmentId: $treatmentId) {
      id
      status
      symptomDescription
      createdAt
      paymentStatus
      paymentMethodType
      cardName
      prescription {
        id
        createdAt
        updatedAt
        prescriptionDispatchHistoryId
        prescriptionDispatchHistories {
          pharmacyName
          createdAt
        }
        status
      }
      fee
      untactQuestionnaireResponse {
        id
        questionnaireText
        treatmentId
        createdAt
      }
      doctor {
        id
        name
        type
        hospital {
          id
          name
        }
      }
      patient {
        id
        name
        phone
      }
      department {
        id
        name
      }
    }
  }
`;

/**
 * __useTreatmentHistoryDetailV3Query__
 *
 * To run a query within a React component, call `useTreatmentHistoryDetailV3Query` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentHistoryDetailV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentHistoryDetailV3Query({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *   },
 * });
 */
export function useTreatmentHistoryDetailV3Query(
  baseOptions: Apollo.QueryHookOptions<
    TreatmentHistoryDetailV3Query,
    TreatmentHistoryDetailV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TreatmentHistoryDetailV3Query,
    TreatmentHistoryDetailV3QueryVariables
  >(TreatmentHistoryDetailV3Document, options);
}
export function useTreatmentHistoryDetailV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentHistoryDetailV3Query,
    TreatmentHistoryDetailV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TreatmentHistoryDetailV3Query,
    TreatmentHistoryDetailV3QueryVariables
  >(TreatmentHistoryDetailV3Document, options);
}
export type TreatmentHistoryDetailV3QueryHookResult = ReturnType<
  typeof useTreatmentHistoryDetailV3Query
>;
export type TreatmentHistoryDetailV3LazyQueryHookResult = ReturnType<
  typeof useTreatmentHistoryDetailV3LazyQuery
>;
export type TreatmentHistoryDetailV3QueryResult = Apollo.QueryResult<
  TreatmentHistoryDetailV3Query,
  TreatmentHistoryDetailV3QueryVariables
>;
export const TreatmentPaymentsDocument = gql`
  query treatmentPayments($treatmentId: ID!) {
    treatmentV3(treatmentId: $treatmentId) {
      id
      patient {
        name
      }
      fee
      doctor {
        hospital {
          name
          phone
          bankAccount {
            accountNumber
            bankName
            accountHolderName
          }
        }
      }
      paymentStatus
      paymentMethodType
    }
  }
`;

/**
 * __useTreatmentPaymentsQuery__
 *
 * To run a query within a React component, call `useTreatmentPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPaymentsQuery({
 *   variables: {
 *      treatmentId: // value for 'treatmentId'
 *   },
 * });
 */
export function useTreatmentPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TreatmentPaymentsQuery,
    TreatmentPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentPaymentsQuery, TreatmentPaymentsQueryVariables>(
    TreatmentPaymentsDocument,
    options
  );
}
export function useTreatmentPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentPaymentsQuery,
    TreatmentPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentPaymentsQuery, TreatmentPaymentsQueryVariables>(
    TreatmentPaymentsDocument,
    options
  );
}
export type TreatmentPaymentsQueryHookResult = ReturnType<
  typeof useTreatmentPaymentsQuery
>;
export type TreatmentPaymentsLazyQueryHookResult = ReturnType<
  typeof useTreatmentPaymentsLazyQuery
>;
export type TreatmentPaymentsQueryResult = Apollo.QueryResult<
  TreatmentPaymentsQuery,
  TreatmentPaymentsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CodeFMedicalCheckupHistoryUnionOutputType: [
      'CodeFErrorOutput',
      'CodeFMedicalCheckupHistoryOutput',
      'CodeFTwoWayOutput'
    ],
    CodeFTreatmentAndDrugContentsUnionOutput: [
      'CodeFErrorOutput',
      'CodeFTreatmentAndDrugContentsOutput',
      'CodeFTwoWayOutput'
    ]
  }
};
export default result;
