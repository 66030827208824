import { FC } from 'react';

import Text from '..';
import { TextProps } from '../Text';
import * as styles from './styles';

type WithCircleProps = TextProps;

const WithCircle: FC<WithCircleProps> = (props) => {
  const { color } = props;

  return (
    <li className={styles.container(color)}>
      <Text {...props} />
    </li>
  );
};

export default WithCircle;
