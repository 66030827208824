export const convertShortAddress = (fullAddress: string) => {
  if (!fullAddress.match(/([가-힣]+(d|)+(시|도|군|구|동|읍|면))/gi)) return;

  const [state, city, town] = fullAddress.split(' ').slice(0, 3);

  const shortState = state.replace(/(특별시|광역시|특별자치시|특별자치도)/, '');
  if (shortState && city && town) {
    return `${shortState} ${city} ${town}`;
  }
};
