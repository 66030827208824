import React, { FC } from 'react';
import { cx } from '@emotion/css';

import { Text } from '@/components/typographies';
import { UntactDepartment } from '@/graphql/generated/graphql';
import { COLORS } from '@/styles/colors';
import { DeepPartial } from '@/utils/createDeepPartial';

import * as styles from './styles';

type DepartmentCardProps = {
  disabled?: boolean;
  className?: string;
  department: DeepPartial<UntactDepartment>;
  children?: React.ReactNode;
  onClick: () => void;
};

const DepartmentCard: FC<DepartmentCardProps> = (props) => {
  const { disabled = false, className, department, children, onClick } = props;

  return (
    <button className={cx(styles.container, className)} onClick={onClick}>
      <div className={styles.cardContainer}>
        {department.image && (
          <img
            className={styles.cardImage(disabled)}
            src={department.image}
            alt={department.name || ''}
          />
        )}
        <Text
          type={'body2_500'}
          color={!disabled ? COLORS.GRAY_80 : COLORS.GRAY_50}
          className={styles.departmentName}
        >
          {department.name}
        </Text>
      </div>
      {children}
    </button>
  );
};

export default DepartmentCard;
