import { useMemo } from 'react';
import Head from 'next/head';

import { convertShortAddress } from '@/utils/convertShortAddress';
import { Doctor, HospitalObject } from '@/graphql/generated/graphql';

const HeadComponent = (props: { hospital: HospitalObject; doctor: Doctor }) => {
  const { hospital, doctor } = props;

  const metaData = useMemo(() => {
    const departments =
      hospital?.hospitalDepartments || doctor?.hospital?.hospitalDepartments;
    const hospitalName = hospital?.name || doctor?.hospital.name;
    const doctorName =
      doctor?.name ||
      hospital?.doctors?.filter((item) => item?.untactVisibility)?.[0]?.name;
    const tags =
      hospital?.doctors?.filter((item) => item?.untactVisibility)?.[0]
        ?.doctorUntactDepartments || doctor?.doctorUntactDepartments;
    const address = hospital?.address || doctor?.hospital.address;

    return {
      title: `${hospitalName ? `${hospitalName} ` : ''}${
        departments?.length
          ? Array.from(new Set(departments?.map((item) => item?.department.name)))
          : ''
      } 비대면 진료 간편신청 | 굿닥`,
      description: `${address ? `${convertShortAddress(address)} ` : ''}${
        hospitalName ? `${hospitalName}의 ` : ''
      }${doctorName ? `${doctorName}에게 ` : ''}${
        tags?.length
          ? `${tags?.map((item) => item?.untactDepartment?.name).join(', ')} 등 `
          : '이비인후과, 장염, 정신건강의학과, 위염, 감기, 소아청소년과, 두통, 탈모 등 '
      }비대면으로 바로 진료 받아보세요.`,
      keyword: `비대면진료, 처방전, 약국${
        departments?.length
          ? `, ${Array.from(
              new Set(departments?.map((item) => item?.department.name))
            ).join(', ')}`
          : ''
      }${hospitalName ? `, ${hospitalName}` : ''}`
    };
  }, [hospital, doctor]);

  return (
    <Head>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="cache-control" content="no-cache" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ko_KR" />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:description" content={metaData.description} />
      <meta name="keywords" content={metaData.keyword} />
      <meta property="og:image" content="/img_addon_og.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta property="og:site_name" content="굿닥 비대면진료 간편신청" />
      <meta property="og:locale" content="ko_KR" />
      <meta property="og:url" content="https://untact.goodoc.co.kr" />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
      <meta name="twitter:url" content="https://untact.goodoc.co.kr" />
      <meta name="twitter:card" content="/img_addon_og.png" />
      <meta name="twitter:image" content="/img_addon_og.png" />
      <meta name="twitter:creator" content="@goodoc" />
      <meta
        name="google-site-verification"
        content="7YO_uwq7T-fUvEKWLDwRZK2fTaCpM8CSHhTwaSyi1l4"
      />
      <meta
        name="naver-site-verification"
        content="a15d5d322cf8932355921ed65319c1fb1561755d"
      />
      <link rel="cannonical" href="https://untact.goodoc.co.kr" />
      <link rel="icon" href="/favicon.png" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css"
      />
    </Head>
  );
};
export default HeadComponent;
