import constate from 'constate';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ApolloError } from '@apollo/client/errors';

import {
  MedicalTypeEnum,
  ProductTypeEnum,
  TreatmentStatusEnum,
  UntactDepartment,
  useCreateDirectTreatmentMutation,
  useCreatePatientMutation,
  usePatientsLazyQuery,
  useRecentTreatmentLazyQuery,
  useUntactDepartmentsQuery
} from '@/graphql/generated/graphql';
import { WithButtonProps as ModalProps } from '@/components/overlays/Modal/WithButtons';
import { DeepPartial } from '@/utils/createDeepPartial';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useSymptomImageUpload from '@/context/medicalApplicationForm/hooks/useSymptomImageUpload';
import amplitude from '@/utils/amplitude';
import { useDoctorContext } from '@/context/doctor';
import symptomData from '@/assets/data/symptom.json';
import { useToastContext } from '@/context/toast';
import { ProfileCardProps } from '@/pages/doctor/[doctorId]/form/treatment/components/DoctorProfile/ProfileCard';
import { DoctorProfileType } from '@/pages/doctor/[doctorId]/form/treatment/components/DoctorProfile/ProfileCard/types';

const useMedicalApplicationForm = () => {
  const router = useRouter();
  const { doctorId } = router.query;
  const showToast = useToastContext();
  const { doctorResponse } = useDoctorContext();
  const symptomDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const [userName, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [frontRRN, setFrontRRN] = useState<string>('');
  const [backRRN, setBackRRN] = useState<string>('');
  const [symptomDescription, setSymptomDescription] = useState<string>('');
  const [selectedSymptomPresets, setSelectedSymptomPresets] = useState<string[]>([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState<DeepPartial<UntactDepartment>>();

  const {
    uploadRef,
    isLoadingUploadToS3,
    s3Keys,
    uploadedImages,
    uploadImage,
    deleteImage
  } = useSymptomImageUpload();
  const [treatmentCancelModal, setTreatmentCancelModal] = useState(false);
  const [isVisibleDoctorReviewBottomSheet, setIsVisibleDoctorReviewBottomSheet] =
    useState(false);
  const [isVisibleAppointmentBottomSheet, setIsVisibleAppointmentBottomSheet] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [treatmentAlreadyExistsModal, setTreatmentAlreadyExistsModal] =
    useState<ModalProps>({
      isVisible: false,
      title: '',
      contentComponent: <></>,
      rightButton: <></>
    });
  const [userInformationModal, setUserInformationModal] = useState<ModalProps>({
    isVisible: false,
    title: '',
    contentComponent: <></>,
    rightButton: <></>
  });

  const { data: departmentResponse, loading: isDepartmentLoading } =
    useUntactDepartmentsQuery({
      variables: {
        input: { types: [doctorResponse?.doctor?.type || MedicalTypeEnum.Medicine] }
      }
    });

  const [getFamilyData, { data: familyResponse }] = usePatientsLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [getRecentTreatment, { data: recentTreatmentResponse }] =
    useRecentTreatmentLazyQuery({ fetchPolicy: 'no-cache' });

  const [createPatientMutation, { loading: isLoadingPatientCreate }] =
    useCreatePatientMutation({
      fetchPolicy: 'no-cache'
    });

  const [createTreatmentMutation, { loading: isLoadingTreatmentCreate }] =
    useCreateDirectTreatmentMutation({
      fetchPolicy: 'no-cache'
    });

  const doctorProfiles: ProfileCardProps[] = useMemo(() => {
    const profiles = [];
    if (doctorResponse?.doctor.untactConfiguration?.operationTime) {
      profiles.push({
        type: DoctorProfileType.OperationTime,
        description: doctorResponse.doctor.untactConfiguration.operationTime
      });
    }
    if (doctorResponse?.doctor.untactNotice) {
      profiles.push({
        type: DoctorProfileType.Notice,
        description: doctorResponse.doctor.untactNotice
      });
    }
    if (doctorResponse?.doctor.profile) {
      profiles.push({
        type: DoctorProfileType.Profile,
        description: doctorResponse.doctor.profile
      });
    }
    return profiles;
  }, [doctorResponse]);

  const sortedDepartments = useMemo(() => {
    if (!departmentResponse?.untactDepartments) return [];
    return [...departmentResponse.untactDepartments].sort((previous, current) => {
      return previous.displayOrder - current.displayOrder;
    });
  }, [departmentResponse]);

  const existedPatient = useMemo(() => {
    if (!familyResponse?.patients) return;

    return familyResponse.patients.find(
      ({ residentRegistrationNumber }) =>
        residentRegistrationNumber === `${frontRRN}-${backRRN}`
    );
  }, [frontRRN, backRRN, familyResponse]);

  const selectDepartment = (department: DeepPartial<UntactDepartment>) => {
    setSelectedDepartment(department);
    setSelectedSymptomPresets([]);
    amplitude.track('untact_addOnTreatmentRequest_type_click');
  };

  const selectSymptomPreset = (preset: string) => {
    amplitude.track('untact_addOnTreatmentRequest_preset_click');
    if (!selectedSymptomPresets.includes(preset)) {
      return setSelectedSymptomPresets([...selectedSymptomPresets, preset]);
    }
    const removedPresets = selectedSymptomPresets.filter((value) => value !== preset);
    setSelectedSymptomPresets(removedPresets);
  };

  const changeSymptomDescription: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setSymptomDescription(String(event.target.value));
  };

  const changeUserName = useCallback((value: string) => {
    setUserName(value);
  }, []);

  const changeFrontRRN = useCallback((value: string) => {
    const MAX_LENGTH = 6;
    setFrontRRN(value.slice(0, MAX_LENGTH));
  }, []);

  const changeBackRRN = (value: string) => {
    const MAX_LENGTH = 7;
    setBackRRN(value.slice(0, MAX_LENGTH));
  };

  const changePhone = useCallback((value: string) => {
    setPhone(value);
  }, []);

  const createPatient = async () => {
    const result = await createPatientMutation({
      variables: {
        name: userName,
        residentRegistrationNumber: `${frontRRN}-${backRRN}`
      }
    });

    return result.data?.createPatient.id;
  };

  const symptoms = useMemo(() => {
    return symptomData?.filter((item) => item.id === selectedDepartment?.id)?.[0];
  }, [symptomData, selectedDepartment]);

  const checkRecentTreatment = useMemo(() => {
    const recentTreatment = recentTreatmentResponse?.recentTreatment;
    if (!recentTreatment?.treatment) return false;
    if (
      recentTreatment.treatment?.status === TreatmentStatusEnum.Request ||
      recentTreatment.treatment?.status === TreatmentStatusEnum.Waiting ||
      recentTreatment.treatment?.status === TreatmentStatusEnum.Ontreatment
    ) {
      return recentTreatment.treatment;
    }
  }, [recentTreatmentResponse?.recentTreatment]);

  const startTreatmentCreateSequence = async () => {
    if (isLoadingPatientCreate || isLoadingTreatmentCreate) return;
    if (checkRecentTreatment) {
      closeAppointmentBottomSheet();
      return setTreatmentAlreadyExistsModal({
        ...treatmentAlreadyExistsModal,
        isVisible: true,
        title: '접수중인 비대면 진료가 있어요'
      });
    }

    try {
      const patientId = existedPatient?.id ?? (await createPatient());

      if (patientId) {
        createTreatment(patientId);
      } else {
        closeAppointmentBottomSheet();
        setErrorMessage('가족 등록 중 오류가 발생했습니다.');
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error as ApolloError);

      closeAppointmentBottomSheet();
      setErrorMessage(errorMessage);
    }
  };

  /**
   * TODO: 기획 싱크 필요
   * 선택한 진료항목이 없는 경우, 바로 /form/user 진입한 케이스
   */
  const createTreatment = async (patientId: string) => {
    await createTreatmentMutation({
      variables: {
        createTreatmentInput: {
          doctorId: String(doctorId),
          patientId,
          phone,
          symptomDescription:
            selectedSymptomPresets.length > 0
              ? `증상: ${selectedSymptomPresets}\n${symptomDescription}`
              : symptomDescription,
          symptomImageS3Keys: s3Keys,
          departmentId: selectedDepartment?.id,
          productType: ProductTypeEnum.AddOn
        }
      },
      onCompleted: (response) => {
        const treatmentId = response.createDirectTreatment.id;

        closeAppointmentBottomSheet();
        router.push(`/treatment/${treatmentId}`);
      },
      onError: (error) => {
        const errorMessage = getErrorMessage(error);

        if (errorMessage) {
          setErrorMessage(errorMessage);
          closeAppointmentBottomSheet();
        }
      }
    });
  };

  const moveToUser = () => {
    if (!selectedDepartment) {
      return showToast({ label: '증상을 선택해 주세요', type: 'error' });
    }
    router.push(`/doctor/${doctorId}/form/user`);
  };

  const moveToIntroOfMedicalApplicationForm = () => {
    router.replace(`/doctor/${doctorId}/form/treatment`);
  };

  const moveToHospitalDetail = () => {
    router.push(`/hospital/${doctorResponse?.doctor.hospital.id}`);
  };

  const openDoctorReviewBottomSheet = () => {
    setIsVisibleDoctorReviewBottomSheet(true);
    amplitude.track('untact_addOnTreatmentRequest_review_click');
  };

  const openUserInformationModal = () => {
    setUserInformationModal({
      ...userInformationModal,
      isVisible: true,
      title: '진료자 정보를 확인해 주세요'
    });
    amplitude.track('untact_addOnPersonalInformationCheckModal_view');
  };

  const openAppointmentBottomSheet = () => {
    closeUserInformationModal();
    setIsVisibleAppointmentBottomSheet(true);
    amplitude.track('untact_addOnAgreementSheet_view');
  };

  const openTreatmentCancelModal = () => {
    setTreatmentCancelModal(true);
  };

  const openDoctorReviewOnApp = () => {
    router.push(
      `https://goodoc.onelink.me/7srm?af_dp=goodoc://app/doctor/profile/${doctorId}/DoctorProfile`
    );
  };

  const closeDoctorReviewBottomSheet = () => {
    setIsVisibleDoctorReviewBottomSheet(false);
  };

  const closeUserInformationModal = () => {
    setUserInformationModal({
      ...userInformationModal,
      isVisible: false
    });
  };

  const closeAppointmentBottomSheet = () => {
    setIsVisibleAppointmentBottomSheet(false);
  };

  const closeTreatmentCancelModal = () => {
    setTreatmentCancelModal(false);
  };

  const closeTreatmentAlreadyExistsModalAction = () => {
    setTreatmentAlreadyExistsModal({
      ...treatmentAlreadyExistsModal,
      isVisible: false
    });
  };

  const moveToCompleteHistoryScreen = () => {
    setTreatmentAlreadyExistsModal({
      ...treatmentAlreadyExistsModal,
      isVisible: false
    });
    const treatmentId = recentTreatmentResponse?.recentTreatment.treatment?.id;
    router.push(`/treatment/${treatmentId}`);
  };

  return {
    doctorProfiles,
    userName,
    frontRRN,
    backRRN,
    phone,
    isDepartmentLoading,
    sortedDepartments,
    symptoms,
    symptomDescriptionRef,
    selectedDepartment,
    selectedSymptomPresets,
    symptomDescription,
    existedPatient,
    userInformationModal,
    isVisibleDoctorReviewBottomSheet,
    isVisibleAppointmentBottomSheet,
    errorMessage,
    treatmentCancelModal,
    treatmentAlreadyExistsModal,
    getFamilyData,
    startTreatmentCreateSequence,
    selectDepartment,
    selectSymptomPreset,
    changeSymptomDescription,
    changeUserName,
    changePhone,
    changeFrontRRN,
    changeBackRRN,
    createTreatment,
    moveToUser,
    moveToIntroOfMedicalApplicationForm,
    openTreatmentCancelModal,
    openUserInformationModal,
    openDoctorReviewBottomSheet,
    openAppointmentBottomSheet,
    openDoctorReviewOnApp,
    closeUserInformationModal,
    closeDoctorReviewBottomSheet,
    closeAppointmentBottomSheet,
    closeTreatmentCancelModal,
    closeTreatmentAlreadyExistsModalAction,
    moveToCompleteHistoryScreen,
    moveToHospitalDetail,
    getRecentTreatment,
    setErrorMessage,
    uploadRef,
    isLoadingUploadToS3,
    uploadedImages,
    uploadImage,
    deleteImage
  };
};

export const [MedicalApplicationFormProvider, useMedicalApplicationFormContext] =
  constate(useMedicalApplicationForm);
