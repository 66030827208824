import { FC } from 'react';

import * as styles from './styles';

const StickyBottomView: FC = (props) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};

export default StickyBottomView;
