import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.GOODOC_API_URL
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (typeof window === 'undefined') return headers;
  const localToken = localStorage.getItem('token');
  const temporaryToken = sessionStorage.getItem('temporaryToken');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: localToken
        ? `Bearer ${localToken}`
        : temporaryToken
        ? `Bearer ${temporaryToken}`
        : ''
    }
  };
});

const client = new ApolloClient({
  ssrMode: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false })
});

export default client;
