import { cx } from '@emotion/css';
import { FC } from 'react';

import Avatar from '../../../assets/images/img_doctor_avatar.svg';
import * as styles from './styles';
import { ImageSize } from './types';

type DoctorAvatarProps = {
  size: ImageSize;
  src?: string | null;
  className?: string;
};

const DoctorAvatar: FC<DoctorAvatarProps> = (props) => {
  const { size, src, className } = props;

  return src ? (
    <img
      src={src}
      className={cx(styles.image(size), styles.imageRadius(size), className)}
      alt="굿닥 비대면진료 프로필"
    />
  ) : (
    <Avatar
      width={styles.imageSize[size]}
      height={styles.imageSize[size]}
      className={cx(styles.imageRadius(size), className)}
      alt="굿닥 비대면진료 프로필"
    />
  );
};

export default DoctorAvatar;
