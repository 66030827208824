import { css } from '@emotion/css';

import { FontWeight, Scale, TextType } from './type';

export const text = (type: TextType, color?: string) => css`
  color: ${color};
  ${getTextStyle(type)};
`;

const getFontWeight = (fontWeight: FontWeight) => {
  switch (fontWeight) {
    case '700':
      return css`
        font-weight: 700;
      `;
    case '500':
      return css`
        font-weight: 500;
      `;
    case '400':
      return css`
        font-weight: 400;
      `;
  }
};

const getScale = (scale: Scale) => {
  switch (scale) {
    case 'heading1':
      return css`
        font-size: 30px;
        line-height: 1.5;
      `;
    case 'heading2':
      return css`
        font-size: 28px;
        line-height: 1.5;
      `;
    case 'heading3':
      return css`
        font-size: 24px;
        line-height: 1.5;
      `;
    case 'heading4':
      return css`
        font-size: 20px;
        line-height: 1.5;
      `;
    case 'heading5':
      return css`
        font-size: 18px;
        line-height: 1.5;
      `;
    case 'body1':
      return css`
        font-size: 16px;
        line-height: 1.5;
      `;
    case 'body2':
      return css`
        font-size: 14px;
        line-height: 1.5;
      `;
    case 'caption':
      return css`
        font-size: 12px;
        line-height: 1.5;
      `;
    case 'overline':
      return css`
        font-size: 10px;
        line-height: 1.4;
      `;
  }
};

const getTextStyle = (type: TextType) => {
  switch (type) {
    case 'heading1_700':
      return css`
        ${getScale('heading1')};
        ${getFontWeight('700')};
      `;
    case 'heading2_700':
      return css`
        ${getScale('heading2')};
        ${getFontWeight('700')};
      `;
    case 'heading3_700':
      return css`
        ${getScale('heading3')};
        ${getFontWeight('700')};
      `;
    case 'heading4_700':
      return css`
        ${getScale('heading4')};
        ${getFontWeight('700')};
      `;
    case 'heading5_700':
      return css`
        ${getScale('heading5')};
        ${getFontWeight('700')};
      `;
    case 'heading5_500':
      return css`
        ${getScale('heading5')};
        ${getFontWeight('500')};
      `;
    case 'body1_700':
      return css`
        ${getScale('body1')};
        ${getFontWeight('700')};
      `;
    case 'body1_500':
      return css`
        ${getScale('body1')};
        ${getFontWeight('500')};
      `;
    case 'body1_400':
      return css`
        ${getScale('body1')};
        ${getFontWeight('400')};
      `;
    case 'body2_700':
      return css`
        ${getScale('body2')};
        ${getFontWeight('700')};
      `;
    case 'body2_500':
      return css`
        ${getScale('body2')};
        ${getFontWeight('500')};
      `;
    case 'body2_400':
      return css`
        ${getScale('body2')};
        ${getFontWeight('400')};
      `;
    case 'caption_700':
      return css`
        ${getScale('caption')};
        ${getFontWeight('700')};
      `;
    case 'caption_500':
      return css`
        ${getScale('caption')};
        ${getFontWeight('500')};
      `;
    case 'caption_400':
      return css`
        ${getScale('caption')};
        ${getFontWeight('400')};
      `;
    case 'overline_500':
      return css`
        ${getScale('overline')};
        ${getFontWeight('500')};
      `;
    case 'overline_400':
      return css`
        ${getScale('overline')};
        ${getFontWeight('400')};
      `;
  }
};
