import constate from 'constate';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';

import amplitude from '@/utils/amplitude';
import {
  DeviceOsEnum,
  SocialAuthProviderEnum,
  useMeQuery,
  useSignInDeviceMutation,
  useSignInSocialMutation
} from '@/graphql/generated/graphql';
import useKakaoAuth, { UserProfile } from '@/context/auth/hooks/useKakaoAuth';
import { getUniqueId } from '@/utils/uuid';

import { version } from '../../package.json';

const useAuth = () => {
  const router = useRouter();
  const [accessToken, setAccessToken] = useState<string>();
  const [deviceToken, setDeviceToken] = useState<string>('testDeviceToken');

  const { signInKakao } = useKakaoAuth();

  const [signInDevice] = useSignInDeviceMutation({});
  const [signInSocialMutation] = useSignInSocialMutation({});
  const { data: queryMe, loading: isMeLoading } = useMeQuery({
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onCompleted: (data) => {
      amplitude.setUserId(data.me.id);
    }
  });

  const signIn = async () => {
    const { token, profile } = await signInKakao();
    const uuid = getUniqueId();
    const destination = makeDestination(router.query);

    signInDevice({
      variables: {
        input: {
          os: DeviceOsEnum.Aos,
          uuid: uuid ?? 'null',
          deviceId: 'none',
          appVersion: version
        }
      },
      onCompleted: (data) => {
        if (!data.signIn?.accessToken) {
          console.warn('accessToken of deviceSignIn is undefined');
          return;
        }

        setDeviceToken(data.signIn.accessToken);
        localStorage.setItem('token', data.signIn.accessToken);

        signinGoodoc(token, profile, destination);
      },
      onError: (error) => {
        console.warn(error.message);
      }
    });
  };

  const signinGoodoc = (
    kakaoToken: string,
    profile: UserProfile,
    destination?: UrlObject
  ) => {
    const { uid } = profile;

    if (!uid || !kakaoToken) return;

    signInSocialMutation({
      variables: {
        input: {
          provider: SocialAuthProviderEnum.Kakao,
          uid,
          token: kakaoToken
        }
      },
      onCompleted: (data) => {
        if (!data.signIn?.accessToken) {
          console.warn('accessToken of socialSignIn is undefined');
          return;
        }

        localStorage.setItem('token', data.signIn.accessToken);
        setAccessToken(data.signIn.accessToken);
        onSuccessSignIn(destination);
      },
      onError: (error) => {
        console.warn(error.message);
      }
    });
  };

  const onSuccessSignIn = (destination?: UrlObject) => {
    if (destination) {
      router.replace(destination);
    }
  };

  const makeDestination = (query: ParsedUrlQuery) => {
    const { destination, ...restQuery } = query;
    if (!destination) return { pathname: '/' };
    return {
      pathname: String(destination),
      query: { ...restQuery }
    };
  };

  const signInWithToken = useCallback((accessToken: string) => {
    localStorage.setItem('token', accessToken);
    setAccessToken(accessToken);
  }, []);

  return {
    isMeLoading,
    me: queryMe?.me,
    signIn,
    isSignedIn: !!accessToken,
    signInWithToken
  };
};

export const [AuthProvider, useAuthContext] = constate(useAuth);
