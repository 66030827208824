import constate from 'constate';
import { useRouter } from 'next/router';

import { useUntactDoctorQuery } from '@/graphql/generated/graphql';
import { getErrorMessage } from '@/utils/getErrorMessage';

const useDoctor = () => {
  const router = useRouter();
  const { doctorId } = router.query;

  const { loading: isLoadingDoctor, data: doctorResponse } = useUntactDoctorQuery({
    skip: !doctorId,
    variables: { doctorId: String(doctorId) },
    onError: (error) => {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) router.replace('/_error');
    }
  });

  return {
    doctorId,
    isLoadingDoctor,
    doctorResponse,
    reviewCount: doctorResponse?.doctor.reviewSummary.reviewsCount,
    treatmentCompletionCount:
      doctorResponse?.doctor.untactDoctorTreatmentSummary?.treatmentCompletionCount
  };
};
export const [DoctorProvider, useDoctorContext] = constate(useDoctor);
