import { css } from '@emotion/css';

import { ImageSize } from './types';

export const image = (size: ImageSize) => css`
  width: ${imageSize[size]}px;
  height: ${imageSize[size]}px;
  object-fit: cover;
`;

export const imageRadius = (size: ImageSize) => css`
  border-radius: ${radius[size]}px;
`;

export const imageSize: { [key in ImageSize]: number } = {
  '24': 24,
  '48': 48,
  '52': 52,
  '56': 56
};

export const radius: { [key in ImageSize]: number } = {
  '24': 6,
  '48': 6,
  '52': 6,
  '56': 12
};
