import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = css`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${COLORS.TRANSPARENT};
  border: none;
  overflow: hidden;
  cursor: pointer;
`;

export const cardContainer = css`
  display: flex;
  align-items: center;
  overflow: hidden;
  column-gap: 6px;
  flex: 1;
`;

export const cardImage = (disabled: boolean) => css`
  width: 24px;
  height: 24px;
  opacity: ${!disabled ? 1 : 0.4};
`;

export const departmentName = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
