import { ServerError } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';

export const getErrorMessage = (error: ApolloError) => {
  const { networkError, graphQLErrors } = error;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const networkErrors = (networkError as ServerError)?.result?.errors;

  if (networkErrors?.length) {
    return networkErrors[0].message;
  }

  if (graphQLErrors?.length) {
    return graphQLErrors[0].message;
  }

  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    return '네트워크 연결을 확인해주세요.';
  }
};
