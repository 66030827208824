import * as amplitude from '@amplitude/analytics-browser';

amplitude.init(String(process.env.AMPLITUDE_API_KEY), undefined, {
  defaultTracking: true
});

const track = (eventName: string, properties?: Record<string, any> | undefined) => {
  amplitude.track(eventName, {
    ...properties,
    agent: navigator.userAgent,
    isApp: !!window.ReactNativeWebView
  });
};

const setUserId = (id: string | undefined) => {
  amplitude.setUserId(id);
};
export default { track, setUserId };
